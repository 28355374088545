import React from 'react';
import { 
  Menu, 
  Box, 
  Typography, 
  Button, 
  MenuItem, 
  FormControlLabel, 
  Checkbox 
} from '@mui/material';

const ColumnFilterMenu = ({ 
  anchorEl, 
  open, 
  onClose, 
  column, 
  values, 
  activeFilters, 
  toggleFilter, 
  clearFilters 
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <Box sx={{ px: 2, py: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle2">
          Filter {column === 'server1' ? 'Server 1' : 
                  column === 'port1' ? 'Port 1' :
                  column === 'server2' ? 'Server 2' :
                  column === 'port2' ? 'Port 2' : 
                  column === 'cableType' ? 'Cable Type' :
                  column === 'destination' ? 'Destination' : ''}
        </Typography>
        {activeFilters.length > 0 && (
          <Button 
            size="small" 
            onClick={clearFilters}
            color="primary"
          >
            Clear
          </Button>
        )}
      </Box>
      
      {values.map(value => (
        <MenuItem key={value}>
          <FormControlLabel
            control={
              <Checkbox
                checked={activeFilters.includes(value)}
                onChange={() => toggleFilter(column, value)}
              />
            }
            label={value}
          />
        </MenuItem>
      ))}
      
      {values.length === 0 && (
        <MenuItem disabled>
          <Typography variant="body2" color="text.secondary">
            No filter options available
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );
};

const ConnectionStatusFilterMenu = ({
  anchorEl,
  open,
  onClose,
  activeFilters,
  toggleFilter,
  clearFilters
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <Box sx={{ px: 2, py: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle2">
          Filter Connection Status
        </Typography>
        {activeFilters.length > 0 && (
          <Button 
            size="small" 
            onClick={clearFilters}
            color="primary"
          >
            Clear
          </Button>
        )}
      </Box>
      
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={activeFilters.includes('Yes')}
              onChange={() => toggleFilter('isConnected', 'Yes')}
            />
          }
          label={<Box sx={{ color: theme => theme.palette.success.main }}>Connected</Box>}
        />
      </MenuItem>
      
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={activeFilters.includes('No')}
              onChange={() => toggleFilter('isConnected', 'No')}
            />
          }
          label={<Box sx={{ color: theme => theme.palette.error.main }}>Not Connected</Box>}
        />
      </MenuItem>
      
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={activeFilters.includes('Unknown')}
              onChange={() => toggleFilter('isConnected', 'Unknown')}
            />
          }
          label="Unknown"
        />
      </MenuItem>
    </Menu>
  );
};

export { ColumnFilterMenu, ConnectionStatusFilterMenu };