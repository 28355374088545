// Constants used throughout the Project components

export const serverTabLabels = [
  { label: 'Type', key: 'type' },
  { label: 'Model', key: 'model' },
  { label: 'Destination', key: 'destination' }
];

export const modelAlternativeOptions = [
  { label: 'Model', key: 'model' },
  { label: 'Hostname', key: 'hostname' },
  { label: 'Serial Number', key: 'serialNumber' },
  { label: 'Make', key: 'make' },
  { label: 'Device Name', key: 'deviceName' }
];

// The currently selected model alternative
export let selectedModelAlternative = { label: 'Model', key: 'model' };

// Destination display alternatives
export const destinationAlternativeOptions = [
  { label: 'Destination', key: 'destination' }, // Default (Row-Rack-RU)
  { label: 'Cart', key: 'cart' }
];

// The currently selected destination alternative
export let selectedDestinationAlternative = { label: 'Destination', key: 'destination' };

export const workstationTabLabels = [
  { label: 'Username', key: 'username' },
  { label: 'Destination', key: 'destination' },
  { label: 'Issues', key: 'issues' },
  { label: 'Status', key: 'status' }
];

// Define server types with key/label pairs for more consistent handling 
export const serverTypeOptions = [
  { key: 'chassis', label: 'Chassis' },
  { key: 'kvm', label: 'KVM' },
  { key: 'patch-panel', label: 'Patch Panel' },
  { key: 'pc', label: 'PC' },
  { key: 'power', label: 'Power' },
  { key: 'server', label: 'Server' },
  { key: 'shelf', label: 'Shelf' },
  { key: 'switch', label: 'Switch' }
];

// For backward compatibility with existing code that expects string array
export const serverTypeLabels = serverTypeOptions.map(option => option.label);