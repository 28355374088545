import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse';

// Field type mapping for connections
const fieldTypes = {
  'server1': 'string',
  'port1': 'string',
  'server2': 'string',
  'port2': 'string',
  'cableType': 'string',
  'notes': 'string'
};

// Column name normalization mapping
const columnMapping = {
  'fromdevice': 'server1',
  'from device': 'server1',
  'from': 'server1',
  'source device': 'server1',
  'source': 'server1',
  'server1': 'server1',
  'server 1': 'server1',
  'fromport': 'port1',
  'from port': 'port1',
  'port1': 'port1',
  'source port': 'port1',
  'todevice': 'server2',
  'to device': 'server2',
  'to': 'server2',
  'target device': 'server2',
  'target': 'server2',
  'destination': 'server2',
  'server2': 'server2',
  'server 2': 'server2',
  'toport': 'port2',
  'to port': 'port2',
  'port2': 'port2',
  'target port': 'port2',
  'destination port': 'port2',
  'notes': 'notes',
  'note': 'notes',
  'comment': 'notes',
  'comments': 'notes',
  'cabletype': 'cableType',
  'cable type': 'cableType',
  'cable_type': 'cableType',
  'type': 'cableType',
  'cable': 'cableType'
};

const handleFileUploadConnections = async (rows, firestore, selectedProject, onComplete) => {
  console.log('ConnectionsUploadHandler started with', rows.length, 'rows');
  try {
    // Make sure we have a valid project path
    if (!selectedProject?.refLocation?.path) {
      throw new Error('Invalid project reference. Project path is undefined.');
    }
    
    console.log('Fetching servers from path:', `${selectedProject.refLocation.path}/servers`);
    
    // Fetch all servers from Firestore
    const serversCollection = await firestore
      .collection(`${selectedProject.refLocation.path}/servers`)
      .get();

    if (serversCollection.empty) {
      console.error('No servers found to create connections');
      
      if (onComplete) {
        console.log('Calling onComplete callback due to no servers found');
        onComplete();
      }
      
      // Return a result object with error information instead of showing an alert
      return {
        count: 0,
        headers: rows.length > 0 ? Object.keys(rows[0]) : [],
        failedRows: rows.map(row => ({...row, error: 'No servers found. Please add servers before creating connections.'}))
      };
    }

    // Define the priority order for device matching
    const priorityOrder = [
      'hostname',
      'serialNumber',
      'uid',
      'deviceName',
      'model',
      'location' // Composite of building + row + rack + RU
    ];

    // Create attribute maps for each identifier in the priority order
    const attributeMaps = {
      hostname: {},
      serialNumber: {},
      uid: {},
      deviceName: {},
      model: {},
      location: {}
    };
    
    // Keep track of all servers by ID to handle duplicates
    const allServersById = {};

    // Populate attribute maps with server data
    serversCollection.forEach((doc) => {
      const serverData = doc.data();
      const serverInfo = { ref: doc.ref, id: doc.id, data: serverData };
      
      // Store in by-ID map for deduplication
      allServersById[doc.id] = serverInfo;

      // Define attribute values, ensuring we handle undefined/null values properly
      const attributes = {
        hostname: serverData.hostname || null,
        serialNumber: serverData.serialNumber || null,
        uid: serverData.uid || null,
        deviceName: serverData.deviceName || null,
        model: serverData.model || null,
        location: serverData.building && serverData.row && serverData.rack && serverData.RU
          ? `${String(serverData.building)}:${String(serverData.row)}:${String(serverData.rack)}:${String(serverData.RU)}`
          : null
      };

      // Populate the maps
      for (const [attr, value] of Object.entries(attributes)) {
        if (value) {
          // Store both original case and lowercase for better matching
          const originalKey = String(value).trim();
          const lowercaseKey = originalKey.toLowerCase();
          
          // Store with original case preserved for debugging
          if (!attributeMaps[attr][originalKey]) attributeMaps[attr][originalKey] = [];
          attributeMaps[attr][originalKey].push(serverInfo);
          
          // Also store with lowercase for standard matching
          if (originalKey !== lowercaseKey) {
            if (!attributeMaps[attr][lowercaseKey]) attributeMaps[attr][lowercaseKey] = [];
            attributeMaps[attr][lowercaseKey].push(serverInfo);
          }
        }
      }
    });
    
    // Log how many unique servers we have
    console.log(`Found ${Object.keys(allServersById).length} unique servers in the database`);
    
    // Log any hostnames that appear to have duplicates
    for (const [key, servers] of Object.entries(attributeMaps.hostname)) {
      if (servers.length > 1) {
        // Extract unique server IDs for this hostname
        const uniqueIds = new Set(servers.map(server => server.id));
        if (uniqueIds.size > 1) {
          console.log(`Warning: Hostname "${key}" maps to ${uniqueIds.size} different servers: ${Array.from(uniqueIds).join(', ')}`);
        }
      }
    }

    // Function to find a unique server based on the identifier
    const findUniqueServer = (identifier) => {
      const id = String(identifier).trim().toLowerCase();
      console.log(`Looking for unique server with identifier: '${id}'`);
      console.log('Current attributeMaps:', JSON.stringify(attributeMaps));
      
      // First try exact match with priority order
      for (const attribute of priorityOrder) {
        const map = attributeMaps[attribute];
        if (map[id]) {
          const matches = map[id];
          console.log(`Checked ${attribute}: found ${matches.length} match(es)`);
          if (matches.length === 1) {
            console.log(`Unique match found using ${attribute}`);
            return matches[0];
          }
          // Multiple matches found; skip to next attribute
        } else {
          console.log(`No matches for ${attribute}`);
        }
      }
      
      // If we didn't find an exact match, attempt a case-insensitive search through hostname
      if (attributeMaps.hostname) {
        const allHostnameKeys = Object.keys(attributeMaps.hostname);
        console.log(`Attempting case-insensitive hostname match for: ${id}`);
        
        // First, collect all matching hostname entries
        const hostnameMatches = [];
        for (const key of allHostnameKeys) {
          if (key.toLowerCase() === id.toLowerCase()) {
            console.log(`Found case-insensitive hostname match: ${key}`);
            const matches = attributeMaps.hostname[key];
            if (matches && matches.length > 0) {
              hostnameMatches.push(...matches);
            }
          }
        }
        
        // Deduplicate matches by server ID
        if (hostnameMatches.length > 0) {
          const uniqueServers = {};
          for (const match of hostnameMatches) {
            uniqueServers[match.id] = match;
          }
          
          const uniqueMatches = Object.values(uniqueServers);
          console.log(`Found ${uniqueMatches.length} unique servers after deduplication`);
          
          // If we have exactly one unique server, return it
          if (uniqueMatches.length === 1) {
            console.log(`Unique match found after deduplication`);
            return uniqueMatches[0];
          }
        }
        
        // If no exact matches, try a more fuzzy match approach
        // This can help with slight variations in naming
        if (hostnameMatches.length === 0) {
          console.log(`Attempting fuzzy hostname match for: ${id}`);
          
          // Try partial matching (e.g., if identifier is contained within hostname)
          const fuzzyMatches = [];
          for (const key of allHostnameKeys) {
            // Check if one string is contained within the other (both ways)
            if (key.toLowerCase().includes(id.toLowerCase()) || 
                id.toLowerCase().includes(key.toLowerCase())) {
              console.log(`Found fuzzy hostname match: ${key}`);
              const matches = attributeMaps.hostname[key];
              if (matches && matches.length > 0) {
                fuzzyMatches.push(...matches);
              }
            }
          }
          
          // Deduplicate fuzzy matches
          if (fuzzyMatches.length > 0) {
            const uniqueFuzzyServers = {};
            for (const match of fuzzyMatches) {
              uniqueFuzzyServers[match.id] = match;
            }
            
            const uniqueFuzzyMatches = Object.values(uniqueFuzzyServers);
            console.log(`Found ${uniqueFuzzyMatches.length} unique servers after fuzzy matching`);
            
            // If we have exactly one unique server from fuzzy matching, return it
            if (uniqueFuzzyMatches.length === 1) {
              console.log(`Unique match found after fuzzy matching`);
              return uniqueFuzzyMatches[0];
            }
          }
        }
      }
      console.log(`No unique match found for identifier: '${id}'`);
      return null;
    };

    // Process in smaller batches for better performance and feedback
    const BATCH_SIZE = 50; // Process 50 connections at a time
    let processedCount = 0;
    let connectionsCount = 0;
    let failedRows = [];
    const originalHeaders = rows.length > 0 ? Object.keys(rows[0]) : [];
    
    // Pre-fetch existing connections to reduce individual queries
    console.log("Pre-fetching existing connections...");
    const connectionsPath = `${selectedProject?.refLocation?.path}/connections`;
    const existingConnectionsSnapshot = await firestore
      .collection(connectionsPath)
      .get();
      
    // Create lookup maps for faster checking
    const connectionLookupMap = {};
    
    existingConnectionsSnapshot.forEach(doc => {
      const data = doc.data();
      // Create keys in format "server1Id_server2Id_port1_port2" and the reverse
      if (data.server1Id && data.server2Id && data.connectionDetails) {
        const forwardKey = `${data.server1Id}_${data.server2Id}_${data.connectionDetails.port1}_${data.connectionDetails.port2}`;
        const reverseKey = `${data.server2Id}_${data.server1Id}_${data.connectionDetails.port2}_${data.connectionDetails.port1}`;
        connectionLookupMap[forwardKey] = { ref: doc.ref, id: doc.id };
        connectionLookupMap[reverseKey] = { ref: doc.ref, id: doc.id };
      }
    });
    
    console.log(`Found ${existingConnectionsSnapshot.size} existing connections`);
    
    // Log the populated attribute maps for all servers
    console.log('Server hostnames in database:', Object.keys(attributeMaps.hostname).sort());
    
    // Process data in batches
    for (let i = 0; i < rows.length; i += BATCH_SIZE) {
      const batch = firestore.batch();
      const currentBatch = rows.slice(i, i + BATCH_SIZE);
      let batchProcessed = 0;
      
      console.log(`Processing batch ${Math.floor(i/BATCH_SIZE) + 1} of ${Math.ceil(rows.length/BATCH_SIZE)}`);

      // Process each row in the current batch
      for (const row of currentBatch) {
        const processedData = {};
        let failureReason = null;

        // Process each field in the row
        Object.entries(row).forEach(([header, value]) => {
          if (value === undefined || value === null || value === '') return;

          const normalizedHeader = header.toLowerCase().trim();
          const fieldName = columnMapping[normalizedHeader];

          if (!fieldName) {
            console.log(`Ignoring unknown column: ${header}`);
            return;
          }

          const fieldType = fieldTypes[fieldName];
          switch (fieldType) {
            case 'string':
              processedData[fieldName] = `${value}`.trim();
              break;
            case 'number':
              processedData[fieldName] = Number(value);
              break;
            case 'boolean':
              if (value === 'TRUE' || value === 'true' || value === true) {
                processedData[fieldName] = true;
              } else if (value === 'FALSE' || value === 'false' || value === false) {
                processedData[fieldName] = false;
              }
              break;
            default:
              processedData[fieldName] = value;
          }
        });
        
        // Debug logging to check processed data
        console.log('Processed row data:', processedData);

        // Check for required fields
        const requiredFields = ['server1', 'port1', 'server2', 'port2'];
        const missingFields = requiredFields.filter(field => !processedData[field]);

        if (missingFields.length > 0) {
          failureReason = `Missing required fields: ${missingFields.join(', ')}`;
          failedRows.push({ ...row, error: failureReason });
          continue;
        }

        // Look up the server documents using priority-based matching
        const server1 = findUniqueServer(processedData.server1);
        const server2 = findUniqueServer(processedData.server2);

        if (!server1) {
          failureReason = `No unique match found for server1: ${processedData.server1}`;
          console.log(failureReason);
          failedRows.push({ ...row, error: failureReason });
          continue;
        }

        if (!server2) {
          failureReason = `No unique match found for server2: ${processedData.server2}`;
          console.log(failureReason);
          failedRows.push({ ...row, error: failureReason });
          continue;
        }

        // Create the connection document
        const connectionData = {
          server1Ref: server1.ref,
          server2Ref: server2.ref,
          server1Id: server1.id,
          server2Id: server2.id,
          server1Name: processedData.server1,
          server2Name: processedData.server2,
          connectionDetails: {
            port1: processedData.port1,
            port2: processedData.port2
          },
          cableType: processedData.cableType || '',
          notes: processedData.notes || ''
        };

        // Use our pre-created lookup map to check for existing connections
        // First check the forward direction
        const forwardKey = `${server1.id}_${server2.id}_${processedData.port1}_${processedData.port2}`;
        // Then check the reverse direction
        const reverseKey = `${server2.id}_${server1.id}_${processedData.port2}_${processedData.port1}`;
        
        const existingConnection = connectionLookupMap[forwardKey] || connectionLookupMap[reverseKey];
        
        // Track duplicates instead of silently updating
        if (existingConnection) {
          // Mark as duplicate for tracking
          failedRows.push({
            ...row, 
            duplicateId: existingConnection.id,
            duplicateFound: `Duplicate connection: ${processedData.server1}:${processedData.port1} to ${processedData.server2}:${processedData.port2}`
          });
        } else {
          // Create new connection
          const connectionRef = firestore.collection(connectionsPath).doc(uuidv4());
          batch.set(connectionRef, connectionData);
          batchProcessed++;
        }
      }
      
      // Commit the current batch and update counters
      if (batchProcessed > 0) {
        console.log(`Committing batch of ${batchProcessed} connections...`);
        await batch.commit();
        connectionsCount += batchProcessed;
        processedCount += currentBatch.length;
        console.log(`Progress: ${processedCount}/${rows.length} rows processed (${Math.round(processedCount/rows.length*100)}%)`);
      }
    }

    // Final report after all batches are done
    console.log(`Successfully processed ${connectionsCount} connections`);

    // Separate true failures from duplicates
    const duplicateRows = failedRows.filter(row => row.duplicateId);
    const genuineFailedRows = failedRows.filter(row => !row.duplicateId);
    
    // Check if we have duplicates to handle
    if (duplicateRows.length > 0) {
      console.log(`Found ${duplicateRows.length} duplicate connections`);
      
      // Generate CSV data for duplicates
      const fields = Object.keys(duplicateRows[0]).filter(key => key !== 'duplicateId');
      const csv = Papa.unparse({
        fields,
        data: duplicateRows.map(duplicate => {
          const row = {};
          fields.forEach(field => {
            row[field] = duplicate[field];
          });
          return row;
        })
      });
      
      // Create a blob for download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      
      // Return information about duplicates, but don't auto-process them
      return {
        duplicatesFound: true,
        duplicatesCount: duplicateRows.length,
        totalCount: rows.length,
        duplicateCsvUrl: url,
        count: connectionsCount, // Count of non-duplicates that were processed
        failedRows: genuineFailedRows.length > 0 ? genuineFailedRows : null,
        
        // Function to proceed with upload (overwriting duplicates)
        proceedWithUpload: async () => {
          // Process duplicates by updating existing connections
          let updatedCount = 0;
          const batch = firestore.batch();
          
          for (const duplicate of duplicateRows) {
            // Extract the server information again
            const server1 = findUniqueServer(duplicate.server1);
            const server2 = findUniqueServer(duplicate.server2);
            
            if (server1 && server2) {
              // Create connection data again
              const connectionData = {
                server1Ref: server1.ref,
                server2Ref: server2.ref,
                server1Id: server1.id,
                server2Id: server2.id,
                server1Name: duplicate.server1,
                server2Name: duplicate.server2,
                connectionDetails: {
                  port1: duplicate.port1,
                  port2: duplicate.port2
                },
                cableType: duplicate.cableType || '',
                notes: duplicate.notes || ''
              };
              
              // Update the existing connection
              const existingConnectionRef = firestore.collection(connectionsPath).doc(duplicate.duplicateId);
              batch.update(existingConnectionRef, connectionData);
              updatedCount++;
            }
          }
          
          // Commit the batch update
          if (updatedCount > 0) {
            await batch.commit();
          }
          
          // Return final result
          return {
            count: connectionsCount + updatedCount,
            failedRows: genuineFailedRows.length > 0 ? genuineFailedRows : null
          };
        }
      };
    }
    
    // Regular result with no duplicates
    const result = {
      count: connectionsCount,
      duplicatesFound: false,
      headers: originalHeaders,
      failedRows: genuineFailedRows.length > 0 ? genuineFailedRows : null
    };
    
    console.log('ConnectionsUploadHandler completed successfully. Returning result:', result);
    
    // Call onComplete callback if provided
    if (onComplete) {
      console.log('Calling onComplete callback');
      onComplete();
    }
    
    // Return the result for proper handling in the UI
    return result;
  } catch (error) {
    console.error('Error creating connections:', error);
    
    if (onComplete) {
      console.log('Calling onComplete callback after error');
      onComplete();
    }
    
    // Rethrow the error to be caught by the caller
    throw error;
  }
};

export default handleFileUploadConnections;