import React, { useState, useRef, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase';
import {
  Button, AppBar, Tabs, Tab, Box, useTheme, InputBase, Snackbar, Menu, MenuItem,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import ProjectAnalytics from './ProjectAnalytics';
import ServerProjectAnalytics from './ServerProjectAnalytics';
import Workstations from './workstations/Workstations';
import Servers from './servers/Servers';
import mmaLogo from '../../assets/mma-logo.png';
import Title from './Title';
import UploadModal from './Modals/UploadModal';
import ShareModal from './Modals/ShareModal';
import useShareModal from '../../hooks/useShareModal';
import ProjectEditManager from './ProjectEditManager';

// Import utilities
import { serverTabLabels, workstationTabLabels, selectedModelAlternative, selectedDestinationAlternative, modelAlternativeOptions } from '../../utils/status/constants';
import { sortWorkstations, sortServers } from '../../utils/status/sortUtils';
import { filterWorkstations, filterServers } from '../../utils/status/filterUtils';
import { generateReport } from '../../utils/status/reportExport';

// Import filter components
import StatusFilterMenu from './filters/StatusFilterMenu';
import UsernameFilterMenu from './filters/UsernameFilterMenu';
import ServerTypeFilterMenu from './filters/ServerTypeFilterMenu';
import TabLabel from './tabs/TabLabel';

// Import upload handlers
import handleWorkstationFileUpload from './upload/WorkstationUploadHandler';
import handleServerFileUpload from './upload/ServerUploadHandler';
import handleFileUploadConnections from './upload/ConnectionsUploadHandler';

function Project({ auth, role, title, workstations, servers, url, selectedProject, refLocation, projectType: initialProjectType, accessType: initialAccessType }) {
  // Debug role and auth values
  console.log('Project component rendering with role:', role, 'auth:', auth?.uid);
  
  // Add state to track cached role for consistent UI across refreshes
  const [cachedRole, setCachedRole] = useState(null);
  
  // Add effect to store and retrieve role information for consistent button visibility
  useEffect(() => {
    // When role is available from props, store it
    if (role && auth?.uid) {
      try {
        sessionStorage.setItem('mma_user_role', role);
        setCachedRole(role);
        console.log('Stored role in session storage:', role);
      } catch (e) {
        console.error('Error storing role:', e);
      }
    } 
    // When auth is available but role isn't, try to retrieve from storage
    else if (auth?.uid && !role) {
      try {
        const storedRole = sessionStorage.getItem('mma_user_role');
        if (storedRole) {
          console.log('Retrieved cached role from storage:', storedRole);
          setCachedRole(storedRole);
        }
      } catch (e) {
        console.error('Error retrieving cached role:', e);
      }
    }
  }, [role, auth?.uid]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  // Duplicate checking dialog states
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [duplicateInfo, setDuplicateInfo] = useState(null);
  const [uploadType, setUploadType] = useState(''); // 'devices' or 'connections'
  
  const {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    handleCopyLink,
  } = useShareModal(url, setSnackbarMessage);

  const theme = useTheme();
  const [usersData, setUsersData] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: 'username', direction: 'asc' });
  const [showSearch, setShowSearch] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);

  // Workstation-specific filters
  const [filterByComputers, setFilterByComputers] = useState(false);
  const [filterByDockingStations, setFilterByDockingStations] = useState(false);
  const [statusFilter, setStatusFilter] = useState({
    disconnectOnly: false,
    qa: false,
    issue: false,
    reconnected: false
  });

  // Server-specific filter: type
  const [serverTypeFilter, setServerTypeFilter] = useState([]);

  // Tab position and menu anchors
  const [tabPosition, setTabPosition] = useState({ left: 0, width: 0 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElUsernameFilter, setAnchorElUsernameFilter] = useState(null);
  const [anchorElServerTypeFilter, setAnchorElServerTypeFilter] = useState(null);

  const tabsRef = useRef(null);
  const [accessType, setAccessType] = useState(initialAccessType || null);
  const firestore = useFirestore();
  const [projectType, setProjectType] = useState(initialProjectType || 'workstations'); // default

  // Upload modal state
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadConnectionsModalOpen, setUploadConnectionsModalOpen] = useState(false);
  
  // Upload menu state
  const [uploadMenuAnchorEl, setUploadMenuAnchorEl] = useState(null);

  // Infer project type from Firestore
  useEffect(() => {
    if (selectedProject) {
      const refLocation = selectedProject?.refLocation?.path;
      const parts = refLocation.split('/');
      const inferredType = parts.includes('serverProjects') ? 'servers' : 'workstations';
      setProjectType(inferredType);

      firestore
        .doc(refLocation)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const projectData = doc.data();
            setAccessType(projectData.public ? 'public' : 'restricted');
          }
        })
        .catch((error) => console.error('Error fetching project data:', error));
    }
  }, [selectedProject, firestore]);
  
  // Handle direct URL access case (when refLocation is provided directly)
  useEffect(() => {
    // Only run this effect when there's no selectedProject but there is a refLocation
    if (!selectedProject && refLocation) {
      console.log('Using direct refLocation path:', refLocation);
      
      // Fetch project data to get public/restricted status
      firestore
        .doc(refLocation)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log('Found project document via direct URL');
            const projectData = doc.data();
            setAccessType(projectData.public ? 'public' : 'restricted');
          } else {
            console.log('Project document not found via direct URL');
          }
        })
        .catch((error) => console.error('Error fetching project data via direct URL:', error));
    }
  }, [refLocation, selectedProject, firestore]);

  // Load user data (for workstation UI)
  useEffect(() => {
    if (auth.uid) {
      firestore
        .collection('users')
        .get()
        .then((snapshot) => {
          const users = snapshot.docs.reduce(
            (acc, doc) => ({ ...acc, [doc.id]: doc.data() }),
            {}
          );
          setUsersData(users);
        })
        .catch((error) => console.error('Error fetching users:', error));
    }
  }, [firestore, auth.uid]);

  // Reposition the search box
  const updateTabPosition = (tabKey) => {
    const tabElements = tabsRef.current?.querySelectorAll('.MuiTab-root');
    // We find the correct index in the array of {label, key} objects
    const array = projectType === 'servers' ? serverTabLabels : workstationTabLabels;
    const index = array.findIndex((tabObj) => tabObj.key === tabKey);

    if (tabElements && index !== -1) {
      const tabElement = tabElements[index];
      setTabPosition({ left: tabElement.offsetLeft, width: tabElement.offsetWidth });
    }
  };

  // Update tab highlight position when sort key changes
  useEffect(() => {
    updateTabPosition(sortConfig.key);
  }, [sortConfig.key, projectType]);
  
  // Listen for model alternative changes to update sorting
  useEffect(() => {
    const handleModelAlternativeChange = (event) => {
      // Array of all model-related fields
      const modelRelatedKeys = ['model', 'hostname', 'serialNumber', 'make', 'deviceName'];
      
      // Are we currently sorting by any model-related field?
      const isCurrentlySortingByModelField = modelRelatedKeys.includes(sortConfig.key);
      
      if (isCurrentlySortingByModelField) {
        // Only update if we're changing to a different field
        if (sortConfig.key !== event.detail.key) {
          console.log(`Updating sort from ${sortConfig.key} to ${event.detail.key}, direction: ${event.detail.direction || sortConfig.direction}`);
          
          // Use the direction provided in the event if available, otherwise keep current direction
          const direction = event.detail.direction || sortConfig.direction;
          
          // Update sortConfig to use the newly selected field
          setSortConfig({
            key: event.detail.key,
            direction: direction
          });
          
          // Broadcast sort direction change to help TabLabel update
          window.dispatchEvent(new CustomEvent('sortDirectionChanged', {
            detail: { key: event.detail.key, direction: direction }
          }));
        }
        
        // Make sure to update tab position to show arrows correctly
        setTimeout(() => {
          updateTabPosition('model');
        }, 0);
      }
    };
    
    window.addEventListener('modelAlternativeChanged', handleModelAlternativeChange);
    return () => window.removeEventListener('modelAlternativeChanged', handleModelAlternativeChange);
  }, [sortConfig]);
  
  // Listen for destination alternative changes to update sorting for servers
  useEffect(() => {
    const handleDestinationAlternativeChange = (event) => {
      // If we're currently sorting by destination, force a re-sort
      if (sortConfig.key === 'destination') {
        // Force re-render to update sorting
        setSortConfig({
          ...sortConfig
        });
      }
    };
    
    window.addEventListener('destinationAlternativeChanged', handleDestinationAlternativeChange);
    return () => window.removeEventListener('destinationAlternativeChanged', handleDestinationAlternativeChange);
  }, [sortConfig]);

  // If we open the search box or show filters, recalc the tab position
  useEffect(() => {
    if (Object.values(showSearch).some(Boolean) || showFilter) {
      updateTabPosition(sortConfig.key);
    }
  }, [showSearch, showFilter, sortConfig.key, projectType]);

  const handleTabChange = (event, newValue) => {
    // "newValue" is the fieldKey from <Tab value={key} />
    
    // Special handling for model field in server projects
    if (projectType === 'servers' && newValue === 'model') {
      // Use the actually selected model alternative key
      const actualKey = selectedModelAlternative?.key || 'model';
      setSortConfig({ key: actualKey, direction: 'asc' });
    } else {
      setSortConfig({ key: newValue, direction: 'asc' });
    }
    
    setShowSearch({});
    setSearchQuery('');
    setShowFilter(false);
    updateTabPosition(newValue);
  };

  const handleTabClick = (fieldKey) => {
    // Special handling for model field in server projects
    if (projectType === 'servers' && fieldKey === 'model') {
      // Use the actual selected alternative key for sorting
      const actualKey = selectedModelAlternative?.key || 'model';
      
      // Model field should toggle asc/desc regardless of which model alternative is selected
      const modelRelatedKeys = ['model', 'hostname', 'serialNumber', 'make', 'deviceName'];
      
      // Check if we need to toggle direction or start fresh
      if (modelRelatedKeys.includes(sortConfig.key)) {
        // Toggle direction if any model alternative is currently sorted
        // Get new direction before setting state
        const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        
        setSortConfig({
          key: actualKey,
          direction: newDirection
        });
        
        console.log(`Toggling direction for ${actualKey} to ${newDirection}`);
        
        // Broadcast sort direction change to help TabLabel update
        window.dispatchEvent(new CustomEvent('sortDirectionChanged', {
          detail: { key: actualKey, direction: newDirection }
        }));
      } else {
        // If coming from a different field, start with ascending
        setSortConfig({
          key: actualKey,
          direction: 'asc'
        });
        
        console.log(`Setting initial sort for ${actualKey} to asc`);
        
        // Broadcast sort direction change to help TabLabel update
        window.dispatchEvent(new CustomEvent('sortDirectionChanged', {
          detail: { key: actualKey, direction: 'asc' }
        }));
      }
      
      updateTabPosition(fieldKey);
    } else {
      // Normal behavior for other fields
      const newDirection = (prev) => prev.key === fieldKey && prev.direction === 'asc' ? 'desc' : 'asc';
      
      setSortConfig((prev) => ({
        key: fieldKey,
        direction: newDirection(prev)
      }));
      
      updateTabPosition(fieldKey);
    }
  };

  // Toggle the search bar for a given key
  const toggleSearch = (event, key) => {
    event.stopPropagation();
    
    // Special handling for model field in server projects
    if (projectType === 'servers' && key === 'model') {
      // Use the actually selected model alternative key
      const actualKey = selectedModelAlternative?.key || 'model';
      
      setShowSearch((prev) => ({
        ...prev,
        [actualKey]: !prev[actualKey]
      }));
      
      // If we just turned it off, clear the query
      if (showSearch[actualKey]) setSearchQuery('');
    } 
    // Special handling for destination field in server projects
    else if (projectType === 'servers' && key === 'destination') {
      // In this case, we always turn on the 'destination' key in showSearch
      // regardless of whether we're showing Cart or Destination
      // This ensures the UI works the same in both modes
      
      setShowSearch((prev) => ({
        ...prev,
        destination: !prev.destination
      }));
      
      // If we just turned it off, clear the query
      if (showSearch.destination) setSearchQuery('');
      
      // Use the destination alternative for sorting/filtering
      const currentAlternative = selectedDestinationAlternative.key || 'destination';
      
      // Update sortConfig to use the current alternative if needed
      // This ensures filtering works correctly
      if (sortConfig.key === 'destination' || sortConfig.key === 'cart') {
        setSortConfig(prev => ({
          ...prev,
          key: 'destination' // Always use 'destination' for consistency
        }));
      }
    } 
    else {
      setShowSearch((prev) => ({
        ...prev,
        [key]: !prev[key]
      }));
      
      // If we just turned it off, clear the query
      if (showSearch[key]) setSearchQuery('');
    }
  };


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Workstation filter toggles
  const handleFilterChange = (filterType) => {
    if (filterType === 'computers') {
      setFilterByComputers((prev) => !prev);
    } else if (filterType === 'dockingStations') {
      setFilterByDockingStations((prev) => !prev);
    }
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter({
      ...statusFilter,
      [event.target.name]: event.target.checked
    });
  };

  // Server type filter logic
  const handleServerTypeFilterIcon = (event) => {
    // Show the filter dropdown for server "Type"
    event.stopPropagation();
    setAnchorElServerTypeFilter(event.currentTarget);
  };

  const handleStatusFilterClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleUsernameFilterClick = (event) => {
    event.stopPropagation();
    setAnchorElUsernameFilter(event.currentTarget);
  };

  const handleCloseServerTypeMenu = () => {
    setAnchorElServerTypeFilter(null);
  };

  // Toggle a specific type in the serverTypeFilter array
  const toggleServerType = (typeOption) => {
    setServerTypeFilter((prev) => {
      if (prev.includes(typeOption)) {
        // Remove it
        return prev.filter((t) => t !== typeOption);
      } else {
        // Add it
        return [...prev, typeOption];
      }
    });
  };

  // Add debug logging for project type
  console.log('Current project type state:', projectType);
  console.log('Initial project type prop:', initialProjectType);
  console.log('Is URL serverProjects?', window.location.pathname.includes('serverProjects'));
  
  // Force project type to 'servers' if URL contains 'serverProjects'
  if (window.location.pathname.includes('serverProjects') && projectType !== 'servers') {
    console.log('Forcing project type to servers based on URL path');
    setProjectType('servers');
  }
  
  // Prepare the tab labels based on projectType
  const currentTabLabels = projectType === 'servers' ? serverTabLabels : workstationTabLabels;

  // Sorting
  const sortedServers = sortServers(servers, sortConfig, selectedDestinationAlternative.key);
  const sortedWorkstations = sortWorkstations(workstations, sortConfig);

  const sortedItems = projectType === 'servers' ? sortedServers : sortedWorkstations;

  // Filtering
  const filteredServers = filterServers(
    sortedItems, 
    searchQuery, 
    sortConfig, 
    serverTypeFilter, 
    selectedDestinationAlternative.key // Pass the selected destination alternative for cart search
  );
  const filteredWorkstations = filterWorkstations(
    sortedItems,
    { filterByComputers, filterByDockingStations, status: statusFilter },
    searchQuery,
    sortConfig
  );

  const filteredItems = projectType === 'servers' ? filteredServers : filteredWorkstations;

  // Upload modal handlers
  const handleOpenUploadModal = () => {
    setUploadModalOpen(true);
  };
  
  const handleCloseUploadModal = () => {
    setUploadModalOpen(false);
  };

  const handleOpenUploadConnectionsModal = () => {
    setUploadConnectionsModalOpen(true);
  };
  
  const handleCloseUploadConnectionsModal = () => {
    setUploadConnectionsModalOpen(false);
  };
  
  // Upload menu handlers
  const handleOpenUploadMenu = (event) => {
    setUploadMenuAnchorEl(event.currentTarget);
  };

  const handleCloseUploadMenu = () => {
    setUploadMenuAnchorEl(null);
  };
  
  const handleUploadOption = (option) => {
    handleCloseUploadMenu();
    if (option === 'devices') {
      handleOpenUploadModal();
    } else if (option === 'connections') {
      handleOpenUploadConnectionsModal();
    }
  };

  // Process workstation file upload
  const processWorkstationUpload = async (rows) => {
    // Create a compatible project object with refLocation if needed
    const project = selectedProject || { refLocation: { path: refLocation } };
    return handleWorkstationFileUpload(rows, firestore, project, handleCloseUploadModal);
  };

  // Keep track of upload promise handlers for resolving/rejecting
  const [uploadPromiseResolve, setUploadPromiseResolve] = useState(null);
  const [uploadPromiseReject, setUploadPromiseReject] = useState(null);
  
  // Handle closing the duplicate info dialog and potentially downloading CSV
  const handleCloseDuplicateDialog = (downloadCsv = false) => {
    if (downloadCsv && duplicateInfo) {
      // Download the CSV file of duplicates
      const link = document.createElement('a');
      link.href = duplicateInfo.duplicateCsvUrl;
      link.setAttribute('download', `duplicate_${uploadType}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    
    setDuplicateDialogOpen(false);
    setConfirmDialogOpen(true);
  };
  
  // Handle the confirmation dialog for proceeding with upload
  const handleCloseConfirmDialog = async (proceed = false) => {
    setConfirmDialogOpen(false);
    
    if (proceed && duplicateInfo) {
      try {
        // Continue with upload (overwrite)
        const uploadResult = await duplicateInfo.proceedWithUpload();
        
        // Include any failed rows (validation errors) from the original result
        const combinedResult = {
          count: uploadResult.count,
          failedRows: uploadResult.failedRows || [],
          headers: duplicateInfo.failedRowsInfo?.headers || []
        };
        
        // If we had failed rows in the original duplicateInfo, add them to the result
        if (duplicateInfo.failedRowsInfo && duplicateInfo.failedRowsInfo.rows) {
          combinedResult.failedRows = [
            ...combinedResult.failedRows,
            ...duplicateInfo.failedRowsInfo.rows
          ];
        }
        
        if (uploadType === 'devices') {
          handleCloseUploadModal();
        } else {
          handleCloseUploadConnectionsModal();
        }
        
        setDuplicateInfo(null);
        
        // Resolve the upload promise if it exists - include all failed rows
        if (uploadPromiseResolve) {
          uploadPromiseResolve(combinedResult);
          setUploadPromiseResolve(null);
          setUploadPromiseReject(null);
        }
        
        return combinedResult;
      } catch (error) {
        console.error('Error proceeding with upload:', error);
        setDuplicateInfo(null);
        
        if (uploadType === 'devices') {
          handleCloseUploadModal();
        } else {
          handleCloseUploadConnectionsModal();
        }
        
        // Reject the upload promise if it exists
        if (uploadPromiseReject) {
          uploadPromiseReject(error);
          setUploadPromiseResolve(null);
          setUploadPromiseReject(null);
        }
        
        throw error;
      }
    } else {
      // User chose to cancel
      setDuplicateInfo(null);
      
      if (uploadType === 'devices') {
        handleCloseUploadModal();
      } else {
        handleCloseUploadConnectionsModal();
      }
      
      const error = new Error(`Upload canceled by user due to duplicates`);
      
      // Reject the upload promise if it exists
      if (uploadPromiseReject) {
        uploadPromiseReject(error);
        setUploadPromiseResolve(null);
        setUploadPromiseReject(null);
        
        // Return instead of throwing the error to prevent the uncaught exception
        return;
      }
      
      // Only log a warning if we couldn't reject the promise (shouldn't normally happen)
      console.warn('Upload canceled by user, but no promise reject function was available');
    }
  };

  // Process server file upload
  const processServerUpload = async (rows) => {
    // Create a compatible project object with refLocation if needed
    const project = selectedProject || { refLocation: { path: refLocation } };
    
    // Set uploadType for dialog handling
    setUploadType('devices');
    
    // Handle server file upload with duplicate detection
    try {
      const result = await handleServerFileUpload(rows, firestore, project);
      
      // Check if there are errors and duplicates together
      const hasFailedRows = result.failedRows && result.failedRows.length > 0;
      
      // Check if duplicates were found
      if (result.duplicatesFound) {
        // Store the duplicate info for use in dialogs
        // Include any failed rows information in the duplicate info
        setDuplicateInfo({
          ...result,
          // Add explicit failedRowsInfo property if there are any
          failedRowsInfo: hasFailedRows ? {
            count: result.failedRows.length,
            rows: result.failedRows,
            headers: rows.length > 0 ? Object.keys(rows[0]) : []
          } : null
        });
        
        // Show the duplicate info dialog
        setDuplicateDialogOpen(true);
        
        // This is needed to keep the upload process from completing yet
        // The actual upload will be handled by the dialog confirm/cancel actions
        return new Promise((resolve, reject) => {
          // Save the promise handlers to resolve/reject later
          setUploadPromiseResolve(() => resolve);
          setUploadPromiseReject(() => reject);
        });
      }
      
      // No duplicates found, return the result
      handleCloseUploadModal();
      return result;
    } catch (error) {
      console.error('Error during server upload with duplicate checking:', error);
      throw error;
    }
  };

  // Process connections file upload
  const processConnectionsUpload = async (rows) => {
    console.log('Project.js: Processing connections upload with', rows.length, 'rows');
    // Create a compatible project object with refLocation if needed
    const project = selectedProject || { refLocation: { path: refLocation } };
    
    // Set uploadType for dialog handling
    setUploadType('connections');
    
    // Validate the project path is available
    if (!project?.refLocation?.path) {
      console.error('Project path is undefined or null');
      throw new Error('Invalid project path. Please reload the page and try again.');
    }
    
    console.log('Project path:', project.refLocation.path);
    
    try {
      console.log('Project.js: Calling handleFileUploadConnections');
      const result = await handleFileUploadConnections(rows, firestore, project);
      console.log('Project.js: handleFileUploadConnections returned successfully:', result);
      
      // Check if there are errors and duplicates together
      const hasFailedRows = result.failedRows && result.failedRows.length > 0;
      
      // Check if duplicates were found
      if (result.duplicatesFound) {
        // Store the duplicate info for use in dialogs
        // Include any failed rows information in the duplicate info
        setDuplicateInfo({
          ...result,
          // Add explicit failedRowsInfo property if there are any
          failedRowsInfo: hasFailedRows ? {
            count: result.failedRows.length,
            rows: result.failedRows,
            headers: rows.length > 0 ? Object.keys(rows[0]) : []
          } : null
        });
        
        // Show the duplicate info dialog
        setDuplicateDialogOpen(true);
        
        // This is needed to keep the upload process from completing yet
        // The actual upload will be handled by the dialog confirm/cancel actions
        return new Promise((resolve, reject) => {
          // Save the promise handlers to resolve/reject later
          setUploadPromiseResolve(() => resolve);
          setUploadPromiseReject(() => reject);
        });
      }
      
      // No duplicates found, close the modal and return the result
      handleCloseUploadConnectionsModal();
      return result;
    } catch (error) {
      console.error('Project.js: Error in processConnectionsUpload:', error);
      throw error; // Re-throw to be caught by the caller
    }
  };

  // Access control & share modal
  const handleAccessChange = (event) => {
    const newAccessType = event.target.value;
    setAccessType(newAccessType);

    // Use refLocation or selectedProject.refLocation.path, whichever is available
    const docPath = refLocation || selectedProject?.refLocation?.path;
    console.log('Updating access with document path:', docPath);
    
    if (!docPath) {
      console.error('No document path available for updating access');
      setSnackbarMessage('Error updating access: No document path available');
      return;
    }

    firestore
      .doc(docPath)
      .update({
        public: newAccessType === 'public'
      })
      .then(() => {
        setSnackbarMessage('Access updated successfully');
      })
      .catch((error) => {
        console.error('Error updating public status:', error);
        setSnackbarMessage('Error updating access: ' + error.message);
      });
  };

  // Download Report
  const handleReportLink = () => {
    generateReport(filteredItems, projectType);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseUsernameFilterMenu = () => {
    setAnchorElUsernameFilter(null);
  };

  // If no title, render nothing
  if (!title) return null;

  return (
    <>
      <Title title={title} />

      {projectType === 'servers' ? (
        <ServerProjectAnalytics servers={servers} />
      ) : (
        <ProjectAnalytics workstations={filteredWorkstations} />
      )}

      <Box sx={{ minHeight: '100vh' }}>
        <AppBar
          position="sticky"
          color="default"
          sx={{
            top: 0,
            zIndex: (theme) => theme.zIndex.appBar + 1
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(1) }}>
            <Tabs
              value={sortConfig.key}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="standard"
              variant="fullWidth"
              ref={tabsRef}
              sx={{ flexGrow: 1, borderBottom: 1, borderColor: 'divider', overflowX: 'auto' }}
            >
              {currentTabLabels.map(({ label, key }) => (
                <Tab
                  key={key}
                  label={
                    <TabLabel
                      displayLabel={label}
                      fieldKey={key}
                      sortConfig={sortConfig}
                      projectType={projectType}
                      toggleSearch={toggleSearch}
                      handleServerTypeFilterIcon={handleServerTypeFilterIcon}
                      handleStatusFilterClick={handleStatusFilterClick}
                      handleUsernameFilterClick={handleUsernameFilterClick}
                    />
                  }
                  value={key}
                  onClick={() => handleTabClick(key)}
                />
              ))}
            </Tabs>

            {/* Show search box if toggled for the current sort key */}
            {(showSearch[sortConfig.key] || 
              (projectType === 'servers' && 
               ['model', 'hostname', 'serialNumber', 'make', 'deviceName'].includes(sortConfig.key) && 
               showSearch[sortConfig.key])) && (
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: theme.shape.borderRadius,
                  padding: theme.spacing(0.5, 2),
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                  marginLeft: `${tabPosition.left}px`,
                  width: `${tabPosition.width}px`,
                  zIndex: 1
                }}
              >
                <InputBase
                  placeholder={`Search ${
                    // Special handling for model alternatives
                    (projectType === 'servers' && 
                     ['model', 'hostname', 'serialNumber', 'make', 'deviceName'].includes(sortConfig.key))
                    ? selectedModelAlternative.label
                    // Special handling for destination/cart
                    : (projectType === 'servers' && 
                       sortConfig.key === 'destination' && 
                       selectedDestinationAlternative.key === 'cart')
                      ? 'Cart'
                      : sortConfig.key
                  }...`}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  autoFocus
                  sx={{ width: '100%' }}
                />
              </Box>
            )}
          </Box>
        </AppBar>

        {/* Render either Servers or Workstations list */}
        {projectType === 'servers' ? (
          <Servers 
            servers={filteredItems} 
            selectedProject={selectedProject || { refLocation: { path: refLocation } }} 
          />
        ) : (
          <Workstations
            users={usersData}
            workstations={filteredItems}
          />
        )}

        <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
          {auth?.uid && (
            <Button onClick={handleOpenModal} variant="contained">
              Share
            </Button>
          )}
          <Button onClick={handleReportLink} variant="contained">
            Download Report
          </Button>

          {auth?.uid && (role === 'admin' || role === 'supervisor' || cachedRole === 'admin' || cachedRole === 'supervisor') && (
            <>
              {(projectType === 'workstations' && !window.location.pathname.includes('serverProjects')) ? (
                <>
                  <Button onClick={handleOpenUploadModal} variant="contained">
                    Upload Workstations
                  </Button>
                  <ProjectEditManager 
                    selectedProject={selectedProject || { refLocation: { path: refLocation } }}
                    setSnackbarMessage={setSnackbarMessage} 
                    refLocation={refLocation}
                  />
                </>
              ): (projectType === 'servers' || window.location.pathname.includes('serverProjects')) ? (
                // Server project buttons - show for both Dashboard and direct URL access
                <>
                  {/* Upload dropdown button */}
                  <Button 
                    onClick={handleOpenUploadMenu} 
                    variant="contained"
                    startIcon={<UploadIcon />}
                  >
                    Upload
                  </Button>
                  
                  {/* Upload Menu */}
                  <Menu
                    anchorEl={uploadMenuAnchorEl}
                    open={Boolean(uploadMenuAnchorEl)}
                    onClose={handleCloseUploadMenu}
                  >
                    <MenuItem onClick={() => handleUploadOption('devices')}>
                      Upload Devices
                    </MenuItem>
                    <MenuItem onClick={() => handleUploadOption('connections')}>
                      Upload Connections
                    </MenuItem>
                  </Menu>
                  
                  <ProjectEditManager 
                    selectedProject={selectedProject || { refLocation: { path: refLocation } }}
                    setSnackbarMessage={setSnackbarMessage} 
                    refLocation={refLocation}
                  />
                </>
              ) : null}

              <UploadModal
                uploadModalOpen={uploadModalOpen}
                handleCloseUploadModal={handleCloseUploadModal}
                handleFileUpload={projectType === 'workstations' ? processWorkstationUpload : processServerUpload}
                uploadType={projectType === 'workstations' ? 'Workstations' : 'Devices'}
                requiredColumns={
                  projectType === 'workstations'
                    ? [
                        { name: 'username', label: 'Username' },
                        { name: 'origin', label: 'Origin' },
                        { name: 'destination', label: 'Destination' }
                      ]
                    : [
                        { name: 'originBuilding', label: 'Origin Lab/Room' },
                        { name: 'size', label: 'Size' },
                        { name: 'originRow', label: 'Origin Row' },
                        { name: 'originRack', label: 'Origin Rack' },
                        { name: 'originRackUnit', label: 'Origin RU' },
                        { name: 'originRearMounted', label: 'Origin Rear Mount' },
                        { name: 'destinationBuilding', label: 'Destination Lab/Room' },
                        { name: 'destinationRow', label: 'Destination Row' },
                        { name: 'destinationRack', label: 'Destination Rack' },
                        { name: 'destinationRackUnit', label: 'Destination RU' },
                        { name: 'destinationRearMounted', label: 'Destination Rear Mount' },
                        { name: 'type', label: 'Type' },
                        { name: 'model', label: 'Model' },
                        { name: 'serialNumber', label: 'S/N' }
                      ]
                }
                optionalColumns={
                  projectType === 'workstations'
                    ? [
                        { name: 'originBuilding', label: 'Origin Building' },
                        { name: 'originFloor', label: 'Origin Floor' },
                        { name: 'destinationBuilding', label: 'Destination Building' },
                        { name: 'destinationFloor', label: 'Destination Floor' },
                        { name: 'notes', label: 'Notes' }
                      ]
                    : [
                        { name: 'destinationConfirmed', label: 'Destination Confirmed' },
                        { name: 'hasRails', label: 'Has Rails' },
                        { name: 'ip', label: 'IP' },
                        { name: 'make', label: 'Make' },
                        { name: 'notes', label: 'Notes' },
                        { name: 'originConfirmed', label: 'Origin Confirmed' },
                        { name: 'uid', label: 'UID' },
                        { name: 'hostname', label: 'Hostname' },
                        { name: 'deviceName', label: 'Device Name' }
                      ]
                }
              />
            
              {/* Upload Modal for Connections (Only for Servers) */}
              {projectType === 'servers' && (
                <UploadModal
                  uploadModalOpen={uploadConnectionsModalOpen}
                  handleCloseUploadModal={handleCloseUploadConnectionsModal}
                  handleFileUpload={processConnectionsUpload}
                  requiredColumns={[
                    { name: 'server1', label: 'Server 1' },
                    { name: 'port1', label: 'Port 1' },
                    { name: 'server2', label: 'Server 2' },
                    { name: 'port2', label: 'Port 2' },
                  ]}
                  optionalColumns={[
                    { name: 'cableType', label: 'Cable Type' },
                    { name: 'notes', label: 'Notes' }
                  ]}
                  uploadType="Connections"
                />
              )}
            </>
          )}
        </Box>
      </Box>

      {/* Logo at bottom */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
        <img src={mmaLogo} style={{ maxWidth: 100 }} alt="Logo" />
      </Box>

      {/* Status Filter Menu */}
      <StatusFilterMenu
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        statusFilter={statusFilter}
        handleStatusFilterChange={handleStatusFilterChange}
      />

      {/* Username Filter Menu */}
      <UsernameFilterMenu
        anchorEl={anchorElUsernameFilter}
        handleClose={handleCloseUsernameFilterMenu}
        filterByComputers={filterByComputers}
        filterByDockingStations={filterByDockingStations}
        handleFilterChange={handleFilterChange}
      />

      {/* Server Type Filter Menu */}
      <ServerTypeFilterMenu
        anchorEl={anchorElServerTypeFilter}
        handleClose={handleCloseServerTypeMenu}
        serverTypeFilter={serverTypeFilter}
        toggleServerType={toggleServerType}
      />

      <ShareModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        accessType={accessType}
        handleAccessChange={handleAccessChange}
        handleCopyLink={handleCopyLink}
      />

      {snackbarMessage && (
        <Snackbar
          open={!!snackbarMessage}
          autoHideDuration={3000}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      )}
      
      {/* Duplicate Detection Dialog */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => handleCloseDuplicateDialog(false)}
        aria-labelledby="duplicate-dialog-title"
        aria-describedby="duplicate-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="duplicate-dialog-title">
          {uploadType === 'devices' ? 'Duplicate Devices Detected' : 'Duplicate Connections Detected'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="duplicate-dialog-description">
            {duplicateInfo && (
              <>
                Found {duplicateInfo.duplicatesCount} duplicate {uploadType === 'devices' ? 
                  'devices (by serial number or hostname) ' : 
                  'connections (by server pairs and ports) '} 
                out of {duplicateInfo.totalCount} total {uploadType}.
                <br /><br />
                You can download a CSV file with the list of duplicates for review.
                
                {duplicateInfo.failedRowsInfo && duplicateInfo.failedRowsInfo.count > 0 && (
                  <>
                    <br /><br />
                    <Typography variant="subtitle1" color="error">
                      <strong>Important:</strong> There were also {duplicateInfo.failedRowsInfo.count} rows with errors.
                    </Typography>
                    <Typography variant="body2">
                      These errors are separate from the duplicates and will need to be fixed before uploading.
                      You will be able to download an error report after proceeding.
                    </Typography>
                  </>
                )}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDuplicateDialog(false)}>
            Continue Without Downloading
          </Button>
          <Button 
            onClick={() => handleCloseDuplicateDialog(true)} 
            variant="contained" 
            color="primary"
          >
            Download CSV
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => handleCloseConfirmDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="confirm-dialog-title">
          Proceed with Upload?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Do you want to proceed with the upload? 
            {uploadType === 'devices' ? 
              ' Duplicate devices will be overwritten with new data.' : 
              ' Duplicate connections will be overwritten with new data.'}
              
            {duplicateInfo?.failedRowsInfo && duplicateInfo.failedRowsInfo.count > 0 && (
              <Typography 
                component="div" 
                style={{ marginTop: '16px', padding: '8px', backgroundColor: '#ffebee', borderRadius: '4px' }}
              >
                <strong>Note:</strong> {duplicateInfo.failedRowsInfo.count} rows with validation errors will be skipped. 
                These rows have issues that prevent them from being processed correctly. 
                You'll receive a detailed error report if you proceed with the upload.
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseConfirmDialog(false)}>
            Cancel Upload
          </Button>
          <Button 
            onClick={() => handleCloseConfirmDialog(true)} 
            variant="contained" 
            color="primary"
          >
            Proceed with Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Project;
