// Status color priority and determination for workstations
export const colorPriority = {
  red: 1,
  orange: 2,
  yellow: 3,
  greenDisconnect: 4,
  greenQA: 5,
  greenWithRedBorder: 6,
  white: 7,
  black: 8
};

export const getColorPriority = (workstation) => {
  if (workstation.qa && workstation.qa.completedBy) {
    if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) {
      return 'greenWithRedBorder';
    }
    return 'greenQA';
  }
  if (workstation.disconnectOnly) return 'greenDisconnect';

  // For import only
  if (!workstation.documentBy || workstation.documentBy === "") return 'black';

  if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) {
    return 'red';
  }

  return workstation.reconnect?.completedBy ? 'yellow' : 'orange';
};

export const getWorkstationStatusText = (workstation) => {
  let status = 'Disconnected';

  if (workstation.qa && workstation.qa.completedBy) {
    if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) {
      status = "QA'd with Issue";
    } else {
      status = "QA'd";
    }
  } else if (workstation.disconnectOnly) {
    status = 'Disconnect Only';
  } else if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) {
    status = 'Issue';
  } else if (workstation.reconnect && workstation.reconnect.completedBy) {
    status = 'Reconnected';
  } else if (!workstation.documentBy || workstation.documentBy === '') {
    status = 'Imported';
  }

  return status;
};