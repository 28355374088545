import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, Typography, Grid, Modal, Box, FormControl, Select, MenuItem, InputLabel, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BasicInfo from './BasicInfo';
import { useCartNames } from './components/hooks';
import { 
  modelAlternativeOptions, 
  selectedModelAlternative,
  destinationAlternativeOptions,
  selectedDestinationAlternative
} from '../../../utils/status/constants';

function Servers({ servers, selectedProject }) {
  const [openModal, setOpenModal] = useState(false);
  const [currentServer, setCurrentServer] = useState(null);
  const [currentServerId, setCurrentServerId] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  // State to trigger re-renders when model alternative changes
  const [, setForceUpdate] = useState({});
  
  // Use the cart names hook to get names for all cart references
  // Only depends on projectRef, not servers
  const { getCartName } = useCartNames(selectedProject?.refLocation?.path);
  
  // Listen for storage events (from the TabLabel component)
  useEffect(() => {
    const handleStorageChange = () => {
      // Force component to re-render
      setForceUpdate({});
    };
    
    // Load initial saved preferences if available
    try {
      // Load model alternative preference
      const savedModelAlternative = localStorage.getItem('serverModelAlternative');
      if (savedModelAlternative) {
        const parsedValue = JSON.parse(savedModelAlternative);
        selectedModelAlternative.key = parsedValue.key;
        selectedModelAlternative.label = parsedValue.label;
      }
      
      // Load destination alternative preference
      const savedDestinationAlternative = localStorage.getItem('serverDestinationAlternative');
      if (savedDestinationAlternative) {
        const parsedValue = JSON.parse(savedDestinationAlternative);
        selectedDestinationAlternative.key = parsedValue.key;
        selectedDestinationAlternative.label = parsedValue.label;
      }
    } catch (e) {
      console.error('Error loading saved preferences:', e);
    }
    
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleOpenModal = (server, id) => {
    // Add the documentId to the server object
    const serverWithId = {
      ...server,
      documentId: id
    };
    setCurrentServer(serverWithId);
    setCurrentServerId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentServer(null);
    setCurrentServerId(null);
    setIsFullscreen(false);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const modalStyle = isFullscreen ? {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    bgcolor: 'background.paper',
    p: { xs: 2, sm: 3, md: 4 },
    overflowY: 'auto'
  } : {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: '1200px',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: { xs: 2, sm: 3, md: 4 },
    overflowY: 'auto'
  };

  // Only log on initial mount or when serverCount changes
  useEffect(() => {
    console.log('Servers component received:', { 
      serverCount: servers?.length || 0, 
      hasSelectedProject: !!selectedProject,
      selectedProjectPath: selectedProject?.refLocation?.path
    });
  }, [servers?.length, selectedProject?.refLocation?.path]);
  
  if (!servers) {
    console.log('No servers data available in Servers component');
    return null;
  }

  return (
    <>
    
      {Object.entries(servers).map(([index, server]) => (
        <Accordion key={index} expanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            onClick={() => handleOpenModal(server, server.id)}
          >
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={4}><Typography variant="body1" sx={{ pl: 4 }}>{server.type}</Typography></Grid>
              <Grid item xs={4}><Typography variant="body1" sx={{ pl: 4 }}>
                {/* Display the selected field if it exists, otherwise show a fallback or nothing */}
                {server[selectedModelAlternative.key] || 
                 (selectedModelAlternative.key !== 'model' ? `No ${selectedModelAlternative.label}` : '')}
              </Typography></Grid>
              <Grid item xs={4}><Typography variant="body1" sx={{ pl: 4 }}>{
                // Choose display based on selected destination alternative
                selectedDestinationAlternative.key === 'destination' ?
                  // Format as Row-Rack-RU for location display
                  [
                    server.destinationRow && `Row ${server.destinationRow}`,
                    server.destinationRack && `Rack ${server.destinationRack}`,
                    server.destinationRackUnit && `RU ${server.destinationRackUnit}`
                  ].filter(Boolean).join(' - ')
                :
                  // Format for cart display - use our hook to get the cart name
                  (server.cartRef ? 
                    // Get cart name from reference and store it in server.cartName for search
                    (() => {
                      // Use the getCartName hook to retrieve cart name from reference
                      const cartName = getCartName(server.cartRef);
                      // Store the cart name for search purposes
                      server.cartName = cartName || 'Cart Assigned';
                      // Return the cart name, or fallback to 'Cart Assigned' if name not found
                      return cartName || 'Cart Assigned';
                    })()
                    : '')
              }</Typography></Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      ))}
      <Modal 
        open={openModal} 
        onClose={isFullscreen ? null : handleCloseModal}
        disableEscapeKeyDown={isFullscreen}
      >
        <Box sx={modalStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <IconButton 
              onClick={toggleFullscreen} 
              aria-label={isFullscreen ? 'minimize' : 'maximize'}
              sx={{ mr: 1 }}
            >
              {isFullscreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
            </IconButton>
            <IconButton 
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {currentServer && selectedProject && (
            <BasicInfo 
              server={currentServer} 
              projectRef={selectedProject?.refLocation?.path} 
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default Servers;
