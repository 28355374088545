import { useState } from 'react';

const useShareModal = (url, setSnackbarMessage) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleCopyLink = () => {
    console.log('Copying URL to clipboard:', url);
    navigator.clipboard.writeText(url)
      .then(() => {
        console.log('URL copied successfully');
        setSnackbarMessage('Link copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy URL:', error);
        setSnackbarMessage('Failed to copy link.');
      });
  };

  return {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    handleCopyLink,
  };
};

export default useShareModal;
