import { useState, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase';
import moment from 'moment';

/**
 * Custom hook to fetch and manage project data
 * 
 * @param {Object} params - URL parameters (year, month, id) if provided
 * @param {Object} selectedProject - Selected project object with refLocation
 * @returns {Object} Project data, workstations, servers, and related state
 */
const useProjectData = (params, selectedProject) => {
  const firestore = useFirestore();
  
  const [workstations, setWorkstations] = useState([]);
  const [servers, setServers] = useState([]);
  const [projectSummary, setProjectSummary] = useState(null);
  const [customTitle, setCustomTitle] = useState('');
  const [url, setURL] = useState('');
  const [refLocation, setRefLocation] = useState('');
  const [projectType, setProjectType] = useState('workstations');
  const [accessType, setAccessType] = useState(null);

  // Case 1: Using URL params (from ProjectParams)
  useEffect(() => {
    if (params?.year && params?.month && params?.id) {
      const { year, month, id } = params;
      
      // Check if we're accessing a server project based on the URL path
      const isServerProject = window.location.pathname.includes('serverProjects');
      console.log('URL Path:', window.location.pathname);
      console.log('Is Server Project:', isServerProject);
      
      // Construct the refLocation from the params
      const collectionName = isServerProject ? 'serverProjects' : 'projects';
      const constructedRefLocation = `${collectionName}/${year}/${month}/${id}`;
      console.log('Constructed Ref Location:', constructedRefLocation);
      setRefLocation(constructedRefLocation);
      
      // Fetch project summary
      const fetchProjectDetails = async () => {
        try {
          // Use the correct collection based on the URL path
          const projectRef = firestore.collection(collectionName).doc(year).collection(month).doc(id);
          console.log('Firestore Path:', `${collectionName}/${year}/${month}/${id}`);
          const projectDoc = await projectRef.get();
          console.log('Project Doc Exists:', projectDoc.exists);
          
          if (projectDoc.exists) {
            const projectData = projectDoc.data();
            projectData.id = id;
            projectData.path = constructedRefLocation;
            setProjectSummary(projectData);
            setAccessType(projectData.public ? 'public' : 'restricted');
            
            const { date, name } = projectData;
            
            setCustomTitle(`${name} - ${moment.unix(date.seconds).format('dddd, MMMM Do, h:mm A')}`);
            
            // Construct URL
            const isDev = window.location.hostname === 'localhost';
            
            // Handle localhost for development without hardcoding domains
            let config;
            
            if (isDev) {
              // In development, always use localhost with current port
              config = {
                scheme: 'http',
                host: `${window.location.hostname}:${window.location.port}`
              };
            } else {
              // In production, use window.location as fallback to avoid hardcoding
              config = {
                scheme: window.location.protocol.replace(':', ''),
                host: window.location.host
              };
            }
            const projectURL = `${config.scheme}://${config.host}/${constructedRefLocation}`;
            setURL(projectURL);
            
            // Determine project type
            const parts = constructedRefLocation.split('/');
            console.log('Path Parts:', parts);
            const inferredType = parts.includes('serverProjects') ? 'servers' : 'workstations';
            console.log('Inferred Project Type:', inferredType);
            setProjectType(inferredType);
          }
        } catch (error) {
          console.error('Error fetching project details:', error);
        }
      };
      
      fetchProjectDetails();
      
      // Set up listeners for workstations and servers
      console.log('Setting up data listeners...');
      
      // For workstations
      const workstationsPath = `${constructedRefLocation}/workstations`;
      console.log('Workstations Path:', workstationsPath);
      const workstationsRef = firestore.collection(workstationsPath);
      const unsubscribeWorkstations = workstationsRef.onSnapshot((snapshot) => {
        console.log('Workstations Snapshot Size:', snapshot.size);
        const workstationsData = snapshot.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });
        setWorkstations(workstationsData);
      });
      
      // For servers
      const serversPath = `${constructedRefLocation}/servers`;
      console.log('Servers Path:', serversPath);
      const serversRef = firestore.collection(serversPath);
      const unsubscribeServers = serversRef.onSnapshot((snapshot) => {
        console.log('Servers Snapshot Size:', snapshot.size);
        const serversData = snapshot.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });
        setServers(serversData);
      });
      
      // Cleanup listeners
      return () => {
        unsubscribeWorkstations();
        unsubscribeServers();
      };
    }
  }, [params, firestore]);

  // Case 2: Using selectedProject (from Dashboard)
  useEffect(() => {
    if (selectedProject && !params) {
      const refLocationPath = selectedProject.refLocation?.path;
      if (refLocationPath) {
        setRefLocation(refLocationPath);
        
        // Determine project type
        const parts = refLocationPath.split('/');
        const inferredType = parts.includes('serverProjects') ? 'servers' : 'workstations';
        setProjectType(inferredType);
        
        // Fetch project access type
        firestore
          .doc(refLocationPath)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const projectData = doc.data();
              setAccessType(projectData.public ? 'public' : 'restricted');
            }
          })
          .catch((error) => console.error('Error fetching project data:', error));
          
        // No need to set up listeners as data comes from parent component
        setURL(window.location.href);
      }
    }
  }, [selectedProject, firestore]);

  return {
    workstations,
    servers,
    projectSummary,
    customTitle,
    url,
    refLocation,
    projectType,
    accessType,
    setWorkstations,
    setServers,
    setCustomTitle,
    setURL,
    setAccessType
  };
};

export default useProjectData;