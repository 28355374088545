import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const SelectProjectNotice = () => {
    return (
        <Container maxWidth="sm" style={{ marginTop: 50 }}>
            <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 2, p: 3 }}>
                <Typography variant="h5" component="div" gutterBottom>
                    Select a project
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    Please select a project from the menu.
                </Typography>
            </Box>
        </Container>
    )
}

export default SelectProjectNotice;
