import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import HamburgerMenu from './HamburgerMenu';
import ProjectAnalyticsThin from '../project/ProjectAnalyticsThin';
import Project from '../project/Project';
import useClientNames from '../../hooks/useClientNames';
import useSelectedProjectData from '../../hooks/useSelectedProjectData';
import useStatsData from '../../hooks/useStatsData';
import { Container, Box, CircularProgress } from '@mui/material';
import SelectClientNotice from './SelectClientNotice';
import SelectProjectNotice from './SelectProjectNotice';
import SelectOrCreateProjectNotice from './SelectOrCreateProjectNotice';
import Title from '../project/Title';

const Dashboard = ({ auth, profile, configuration }) => {
  // Log the configuration to see what we're receiving
  console.log('Dashboard received configuration:', configuration);
  
  // Fetch client names and loading state using the custom hook
  const { clientNames, isLoading } = useClientNames(auth, profile);

  // Define state variables for client, project, stats, and title
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedStats, setSelectedStats] = useState(null);
  const [title, setTitle] = useState('Select a Client');

  // Function to get client name, handling special case for 'all'
  // Memoize this function to prevent unnecessary re-renders
  const getClientName = useCallback(
    (clientID) => (clientID === 'all' ? 'All Companies' : clientNames[clientID] || ''),
    [clientNames]
  );

  // Fetch project data using the custom hook
  const {
    selectedProject,
    workstations,
    servers,
    customTitle: projectCustomTitle,
    url,
    projectType,
    clearProjectData,
  } = useSelectedProjectData(selectedProjectId, selectedClient, getClientName, configuration);

  // Fetch stats data using the custom hook
  const { workstations: statsWorkstations, servers: statsServers, loading: statsLoading, error: statsError } = 
    useStatsData(selectedStats);

  // Dynamically update the title based on the current state
  useEffect(() => {
    if (selectedProjectId) {
      setTitle(projectCustomTitle);
    } else if (selectedStats) {
      const { client, year, month } = selectedStats;
      const clientName = getClientName(client);
      if (month) {
        setTitle(`${clientName}: ${month} ${year}`);
      } else {
        setTitle(`${clientName}: ${year}`);
      }
    } else if (selectedClient) {
      setTitle(getClientName(selectedClient));
    } else {
      setTitle('Select a Client');
    }
  }, [selectedProjectId, selectedStats, selectedClient, projectCustomTitle, getClientName]);

  // Handle client selection
  const handleClientChange = useCallback((clientID) => {
    setSelectedClient(clientID);
    setSelectedProjectId(null);
    setSelectedStats(null);
  }, []);

  // Handle project selection
  const handleProjectChange = useCallback((projectData) => {
     // projectData can be null, a string ID, or an object with id and clientID
    setSelectedProjectId(projectData);
    setSelectedStats(null);
  }, []);

  // Handle stats selection
  const handleStatsChange = useCallback((stats) => {
    setSelectedStats(stats);
    setSelectedProjectId(null);
  }, []);

  // Determine what content to render based on the current state
  let content;

  if (isLoading) {
    content = (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  } else if (!selectedClient) {
    content = <SelectClientNotice />;
  } else if (auth.uid && profile.role === 'client' && selectedClient && !selectedProjectId && !selectedStats) {
    content = <SelectProjectNotice />;
  } else if (auth.uid && (profile.role === 'admin' || profile.role === 'supervisor') && selectedClient !== 'all' && !selectedProjectId && !selectedStats) {
    content = <SelectOrCreateProjectNotice selectedClient={selectedClient} auth={auth} profile={profile} clientNames={clientNames} />;
  } else if (auth.uid && (profile.role === 'admin' || profile.role === 'supervisor') && selectedClient === 'all' && !selectedProjectId && !selectedStats) {
    content = <SelectProjectNotice />;
  } else if (selectedProjectId && !selectedStats) {
    content = (
      <Project
        auth={auth}
        role={profile.role}
        title={title}
        workstations={workstations}
        servers={servers}
        url={url}
        selectedProject={selectedProject}
        projectType={projectType}
      />
    );
  } else if (selectedStats) {
    content = (
      <>
        <Title title={title} />
        {statsLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : statsError ? (
          <p>Error: {statsError}</p>
        ) : (
          <ProjectAnalyticsThin workstations={statsWorkstations} servers={statsServers} />
        )}
      </>
    );
  } else {
    content = <Title title={title} />;
  }

  // Render the layout with HamburgerMenu and content
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        height: '100vh',
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 1102, height: { xs: 'auto', sm: '100%' } }}>
        <HamburgerMenu
          auth={auth}
          profile={profile}
          setSelectedClient={handleClientChange}
          setSelectedProjectId={handleProjectChange}
          setSelectedStats={handleStatsChange}
          configuration={configuration}
          selectedClient={selectedClient}
          selectedProjectId={selectedProjectId}
          clientNames={clientNames}
          isLoading={isLoading}
        />
      </Box>
      <Container maxWidth="md">{content}</Container>
    </Box>
  );
};

// Map Redux state to props
const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  configuration: state.firestore.data.configuration,
});

// Connect the component to Redux and Firestore
export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => [
    { collection: 'projects', doc: 'configuration', storeAs: 'configuration' },
  ])
)(Dashboard);