import React from 'react';
import { Menu, MenuItem, FormControlLabel, Checkbox } from '@mui/material';

const UsernameFilterMenu = ({ 
  anchorEl, 
  handleClose, 
  filterByComputers, 
  filterByDockingStations, 
  handleFilterChange 
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={filterByComputers}
              onChange={() => handleFilterChange('computers')}
            />
          }
          label="Has Computer"
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={filterByDockingStations}
              onChange={() => handleFilterChange('dockingStations')}
            />
          }
          label="Has Docking Station"
        />
      </MenuItem>
    </Menu>
  );
};

export default UsernameFilterMenu;