import { getColorPriority } from './workstationStatusUtils';

// Compare logic for workstations
export const compareValues = (a, b, key) => {
  const valueA = a[key];
  const valueB = b[key];

  // Convert both values to strings if needed
  const valueAStr = typeof valueA === 'string' ? valueA : (valueA ?? '').toString();
  const valueBStr = typeof valueB === 'string' ? valueB : (valueB ?? '').toString();

  switch (key) {
    case 'issues':
      return (
        Object.values(a.issues || {}).filter((issue) => issue.clientIssue).length -
        Object.values(b.issues || {}).filter((issue) => issue.clientIssue).length
      );
    case 'status':
      return getColorPriority(a) - getColorPriority(b);
    default:
      // Safe string compare
      return valueAStr.localeCompare(valueBStr);
  }
};

export const sortWorkstations = (workstations, sortConfig) => {
  return Object.values(workstations || {}).sort((a, b) => {
    const comparison = compareValues(a, b, sortConfig.key);
    return sortConfig.direction === 'asc' ? comparison : -comparison;
  });
};

// Compare logic for servers
export const compareServerValues = (a, b, key, destinationMode = 'destination') => {
  if (key === 'destination') {
    // Determine if we're sorting by destination or cart based on the current view mode
    if (destinationMode === 'destination') {
      // Create concatenated destination strings for sorting (Row-Rack-RU)
      const getDestinationString = (server) => {
        const row = (server.destinationRow || '').toString().padStart(2, '0'); // Pad for numeric sorting
        const rack = (server.destinationRack || '').toString().padStart(2, '0');
        const ru = (server.destinationRackUnit || '').toString().padStart(2, '0');
        return `${row}-${rack}-${ru}`.toLowerCase();
      };
      const valueA = getDestinationString(a);
      const valueB = getDestinationString(b);
      return valueA.localeCompare(valueB);
    } else {
      // We're in cart mode, sort by cart name
      // Get cartName from the server object, or extract from cartRef as fallback
      const getCartString = (server) => {
        // If cartName exists, use it directly
        if (server.cartName) {
          return server.cartName.toLowerCase();
        }
        
        // If no cartRef, this server has no cart
        if (!server.cartRef) {
          return '';
        }
        
        // Extract cart ID from reference
        let cartId = '';
        if (typeof server.cartRef === 'string') {
          cartId = server.cartRef.split('/').pop();
        } else if (server.cartRef.id) {
          cartId = server.cartRef.id;
        } else if (server.cartRef.path) {
          cartId = server.cartRef.path.split('/').pop();
        }
        
        return cartId.toLowerCase();
      };
      
      const valueA = getCartString(a);
      const valueB = getCartString(b);
      
      // Servers with carts should sort before those without
      if (valueA === '' && valueB !== '') return 1;
      if (valueA !== '' && valueB === '') return -1;
      
      return valueA.localeCompare(valueB);
    }
  } else {
    // Handle missing values gracefully - empty values should sort last
    const valueA = (a[key] !== undefined && a[key] !== null) 
      ? a[key].toString().toLowerCase() 
      : '';
    const valueB = (b[key] !== undefined && b[key] !== null) 
      ? b[key].toString().toLowerCase() 
      : '';
      
    // Empty values sort last
    if (valueA === '' && valueB !== '') return 1;
    if (valueA !== '' && valueB === '') return -1;
    
    // Normal string comparison
    return valueA.localeCompare(valueB);
  }
};

export const sortServers = (servers, sortConfig, destinationMode = 'destination') => {
  return Object.values(servers || {}).sort((a, b) => {
    const comparison = compareServerValues(a, b, sortConfig.key, destinationMode);
    return sortConfig.direction === 'asc' ? comparison : -comparison;
  });
};