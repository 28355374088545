import { useParams } from "react-router-dom";
import Project from './Project';
import { Container, Box } from '@mui/material';
import { connect } from 'react-redux';
import useProjectData from '../../hooks/useProjectData';

function ProjectParams({ auth, profile }) {
  const params = useParams();
  
  // Use the custom hook to fetch project data
  const {
    workstations,
    servers,
    customTitle,
    url,
    refLocation,
    projectType,
    accessType
  } = useProjectData(params);

  return (
    <Container maxWidth="md" sx={{ marginTop: 10, position: 'relative' }}>
      <Box>
        <Project
          auth={auth}
          role={profile.role}
          title={customTitle}
          workstations={workstations}
          servers={servers}
          url={url}
          refLocation={refLocation}
          projectType={projectType}
          accessType={accessType}
        />
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

export default connect(mapStateToProps)(ProjectParams);