import React, { useState, useEffect } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { v4 as uuidv4 } from 'uuid';
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import useClientNames from '../../hooks/useClientNames';

const ProjectEditManager = ({ selectedProject, setSnackbarMessage, refLocation }) => {
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [deviceSelectDialogOpen, setDeviceSelectDialogOpen] = useState(false);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [devices, setDevices] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'type', direction: 'asc' });
  const [changeClientDialogOpen, setChangeClientDialogOpen] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [changingClient, setChangingClient] = useState(false);
  const [currentClientId, setCurrentClientId] = useState('');
  
  // New state for add device functionality
  const [addDeviceDialogOpen, setAddDeviceDialogOpen] = useState(false);
  const [newDeviceData, setNewDeviceData] = useState({});
  const [addingDevice, setAddingDevice] = useState(false);
  
  const firestore = useFirestore();
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const { clientNames, isLoading } = useClientNames(auth, profile);

  // Fetch the current client ID when the component mounts
  useEffect(() => {
    if (selectedProject?.refLocation?.path || refLocation) {
      const projectRef = selectedProject?.refLocation?.path || refLocation;
      
      firestore.doc(projectRef)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const projectData = doc.data();
            if (projectData.client) {
              setCurrentClientId(projectData.client);
              setSelectedClientId(projectData.client);
            }
          } else {
            console.error('Project document does not exist');
          }
        })
        .catch((error) => {
          console.error('Error fetching project data:', error);
        });
    }
  }, [selectedProject, refLocation, firestore]);

  const handleOpenEditMenu = (event) => {
    setEditMenuAnchorEl(event.currentTarget);
  };
  
  const handleChangeClientOption = () => {
    setChangeClientDialogOpen(true);
    handleCloseEditMenu();
  };
  
  const handleAddDeviceOption = () => {
    // Set default values based on project type
    const defaultValues = {
      ...(isServerProject ? {
        type: 'Server', // Default type for server projects
        make: '',
        model: '',
        serialNumber: '',
        hostname: '',
        originBuilding: '',
        originRow: '',
        originRack: '',
        originRackUnit: '',
        destinationBuilding: '',
        destinationRow: '',
        destinationRack: '',
        destinationRackUnit: '',
      } : {
        username: '',
        origin: '',
        destination: '',
        notes: ''
      })
    };
    
    setNewDeviceData(defaultValues);
    setAddDeviceDialogOpen(true);
    handleCloseEditMenu();
  };

  const handleCloseEditMenu = () => {
    setEditMenuAnchorEl(null);
  };

  const handleDeleteOption = (type) => {
    setDeleteType(type);
    if (type === 'singleDevice') {
      fetchDevices();
      setDeviceSelectDialogOpen(true);
    } else {
      setDeleteDialogOpen(true);
    }
    handleCloseEditMenu();
  };
  
  const fetchDevices = async () => {
    if (!selectedProject && !refLocation) return;
    
    const projectRef = selectedProject?.refLocation?.path || refLocation;
    const isServerProject = projectRef.includes('serverProjects');
    const collectionName = isServerProject ? 'servers' : 'workstations';
    
    setLoadingDevices(true);
    try {
      const snapshot = await firestore.collection(`${projectRef}/${collectionName}`).get();
      const devicesList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDevices(devicesList);
    } catch (error) {
      console.error('Error fetching devices:', error);
      setSnackbarMessage(`Error fetching devices: ${error.message}`);
    } finally {
      setLoadingDevices(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteType('');
  };
  
  const handleCloseDeviceSelectDialog = () => {
    setDeviceSelectDialogOpen(false);
    setSelectedDeviceIds([]);
  };
  
  const handleDeviceSelect = (deviceId) => {
    setSelectedDeviceIds(prevSelected => {
      if (prevSelected.includes(deviceId)) {
        // If already selected, remove it
        return prevSelected.filter(id => id !== deviceId);
      } else {
        // Otherwise add it
        return [...prevSelected, deviceId];
      }
    });
  };
  
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all devices
      const allIds = devices.map(device => device.id);
      setSelectedDeviceIds(allIds);
    } else {
      // Deselect all
      setSelectedDeviceIds([]);
    }
  };
  
  // Handle sorting of devices list
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };
  
  // Get sorted devices
  const getSortedDevices = () => {
    if (!devices || devices.length === 0) return [];
    
    return [...devices].sort((a, b) => {
      let valA, valB;
      
      // Special handling for hostname/deviceName field
      if (sortConfig.key === 'hostname') {
        valA = a.hostname || a.deviceName || '';
        valB = b.hostname || b.deviceName || '';
      } 
      // Special handling for type field to normalize case
      else if (sortConfig.key === 'type') {
        valA = (a.type || '').toLowerCase();
        valB = (b.type || '').toLowerCase();
      }
      // Special handling for model field to include make
      else if (sortConfig.key === 'model') {
        valA = `${a.make || ''} ${a.model || ''}`.trim();
        valB = `${b.make || ''} ${b.model || ''}`.trim();
      }
      else {
        // Handle null or undefined values for other fields
        valA = a[sortConfig.key] || '';
        valB = b[sortConfig.key] || '';
      }
      
      // Treat empty strings as lowest value
      if (valA === '' && valB !== '') return sortConfig.direction === 'asc' ? -1 : 1;
      if (valA !== '' && valB === '') return sortConfig.direction === 'asc' ? 1 : -1;
      
      // Case-insensitive string comparison
      if (typeof valA === 'string' && typeof valB === 'string') {
        const comparison = valA.toLowerCase().localeCompare(valB.toLowerCase());
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      }
      
      // Numeric comparison
      return sortConfig.direction === 'asc' ? valA - valB : valB - valA;
    });
  };
  
  const handleDeleteSelectedDevices = async () => {
    if (selectedDeviceIds.length === 0) return;
    
    setDeleteInProgress(true);
    try {
      const projectRef = selectedProject?.refLocation?.path || refLocation;
      const isServerProject = projectRef.includes('serverProjects');
      const collectionName = isServerProject ? 'servers' : 'workstations';
      
      // Use batch operation for deleting multiple devices
      let batch = firestore.batch();
      let batchCount = 0;
      const batchLimit = 450; // Firestore batch limit is 500, stay under it
      let totalDevicesDeleted = 0;
      let totalConnectionsDeleted = 0;
      
      // Process each selected device
      for (const deviceId of selectedDeviceIds) {
        // Add device deletion to batch
        const deviceRef = firestore.doc(`${projectRef}/${collectionName}/${deviceId}`);
        batch.delete(deviceRef);
        batchCount++;
        totalDevicesDeleted++;
        
        // If batch is getting full, commit it and start a new one
        if (batchCount >= batchLimit) {
          await batch.commit();
          batch = firestore.batch();
          batchCount = 0;
        }
        
        // If it's a server project, also delete associated connections
        if (isServerProject) {
          // Get all connections where this server is either server1 or server2
          const server1ConnectionsSnapshot = await firestore
            .collection(`${projectRef}/connections`)
            .where('server1Id', '==', deviceId)
            .get();
            
          const server2ConnectionsSnapshot = await firestore
            .collection(`${projectRef}/connections`)
            .where('server2Id', '==', deviceId)
            .get();
          
          // Add connection deletions to batch
          server1ConnectionsSnapshot.docs.forEach(doc => {
            batch.delete(doc.ref);
            batchCount++;
            totalConnectionsDeleted++;
            
            // If batch is getting full, commit it and start a new one
            if (batchCount >= batchLimit) {
              batch.commit();
              batch = firestore.batch();
              batchCount = 0;
            }
          });
          
          server2ConnectionsSnapshot.docs.forEach(doc => {
            batch.delete(doc.ref);
            batchCount++;
            totalConnectionsDeleted++;
            
            // If batch is getting full, commit it and start a new one
            if (batchCount >= batchLimit) {
              batch.commit();
              batch = firestore.batch();
              batchCount = 0;
            }
          });
        }
      }
      
      // Commit any remaining operations in the batch
      if (batchCount > 0) {
        await batch.commit();
      }
      
      // Show success message
      if (isServerProject) {
        const deviceText = totalDevicesDeleted === 1 ? 'device' : 'devices';
        const connectionText = totalConnectionsDeleted === 1 ? 'connection' : 'connections';
        setSnackbarMessage(`Successfully deleted ${totalDevicesDeleted} ${deviceText} and ${totalConnectionsDeleted} associated ${connectionText}.`);
      } else {
        const text = totalDevicesDeleted === 1 ? 'workstation' : 'workstations';
        setSnackbarMessage(`Successfully deleted ${totalDevicesDeleted} ${text}.`);
      }
      
      // Close the dialog and reset state
      setDeviceSelectDialogOpen(false);
      setSelectedDeviceIds([]);
      
      // Remove the deleted devices from the local state
      setDevices(prev => prev.filter(device => !selectedDeviceIds.includes(device.id)));
      
    } catch (error) {
      console.error('Error deleting devices:', error);
      setSnackbarMessage(`Error deleting devices: ${error.message}`);
    } finally {
      setDeleteInProgress(false);
    }
  };

  // Handle Change Client functionality
  const handleCloseChangeClientDialog = () => {
    setChangeClientDialogOpen(false);
    setSelectedClientId(currentClientId); // Reset selection to current client
  };

  const handleClientChange = (event) => {
    setSelectedClientId(event.target.value);
  };

  const handleChangeClient = async () => {
    if (!selectedClientId || selectedClientId === currentClientId || !auth.uid) {
      handleCloseChangeClientDialog();
      return;
    }

    if ((profile.role !== 'admin' && profile.role !== 'supervisor')) {
      setSnackbarMessage('Only administrators or supervisors can change project clients.');
      handleCloseChangeClientDialog();
      return;
    }

    setChangingClient(true);
    
    try {
      const projectRef = selectedProject?.refLocation?.path || refLocation;
      
      if (!projectRef) {
        throw new Error('Project reference path not found');
      }

      // Get the project document
      const projectDoc = await firestore.doc(projectRef).get();
      
      if (!projectDoc.exists) {
        throw new Error('Project document not found');
      }
      
      const projectData = projectDoc.data();
      const oldClientId = projectData.client;
      
      // Update the client field in the project document
      await firestore.doc(projectRef).update({
        client: selectedClientId
      });
      
      // Make sure the project exists in the new client's projects collection
      // Parse the path to extract year/month/projectId
      const pathParts = projectRef.split('/');
      const projectId = pathParts[pathParts.length - 1];
      const month = pathParts[pathParts.length - 2];
      const year = pathParts[pathParts.length - 3];
      const isServerProject = projectRef.includes('serverProjects');
      
      // Create project reference object for client's projects collection
      const projectInfo = {
        date: projectData.date,
        name: projectData.name,
        refLocation: firestore.doc(projectRef),
        type: isServerProject ? 'servers' : 'workstations'
      };
      
      // Add to new client's projects collection
      const newClientProjectRef = firestore.collection('clients').doc(selectedClientId).collection('projects').doc(projectId);
      await newClientProjectRef.set(projectInfo);
      
      // Remove from old client's projects collection
      if (oldClientId) {
        await firestore.collection('clients').doc(oldClientId).collection('projects').doc(projectId).delete();
      }
      
      // Update state and show success message
      setCurrentClientId(selectedClientId);
      setSnackbarMessage(`Project client changed to ${clientNames[selectedClientId]}`);
      
      // Reload the page to reflect the changes
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      
    } catch (error) {
      console.error('Error changing client:', error);
      setSnackbarMessage(`Error changing client: ${error.message}`);
    } finally {
      setChangingClient(false);
      setChangeClientDialogOpen(false);
    }
  };

  const handleConfirmDelete = async () => {
    if ((!selectedProject && !refLocation) || !deleteType) return;
    
    setDeleteInProgress(true);
    try {
      // Use either selectedProject.refLocation.path or the direct refLocation prop
      const projectRef = selectedProject?.refLocation?.path || refLocation;
      
      console.log('Deleting from project path:', projectRef);
      
      // Check if this is a workstation or server project
      const isServerProject = projectRef.includes('serverProjects');
      const collectionName = isServerProject ? 'servers' : 'workstations';
      
      if (deleteType === 'devices') {
        // Get all devices/workstations
        const itemsSnapshot = await firestore.collection(`${projectRef}/${collectionName}`).get();
        
        // Delete in batches (Firestore limits batch operations to 500)
        const batchSize = 450;
        let batch = firestore.batch();
        let operationCount = 0;
        let totalDeletedItems = 0;
        
        itemsSnapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
          operationCount++;
          totalDeletedItems++;
          
          if (operationCount >= batchSize) {
            // Commit the current batch and start a new one
            batch.commit();
            batch = firestore.batch();
            operationCount = 0;
          }
        });
        
        // Commit any remaining operations
        if (operationCount > 0) {
          await batch.commit();
        }
        
        // For server projects, also delete connections
        let totalDeletedConnections = 0;
        if (isServerProject) {
          // Delete all connections
          const connectionsSnapshot = await firestore.collection(`${projectRef}/connections`).get();
          
          // Reset batch operations for connections
          batch = firestore.batch();
          operationCount = 0;
          
          connectionsSnapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
            operationCount++;
            totalDeletedConnections++;
            
            if (operationCount >= batchSize) {
              batch.commit();
              batch = firestore.batch();
              operationCount = 0;
            }
          });
          
          if (operationCount > 0) {
            await batch.commit();
          }
          
          setSnackbarMessage(`Successfully deleted ${totalDeletedItems} devices and ${totalDeletedConnections} connections.`);
        } else {
          setSnackbarMessage(`Successfully deleted ${totalDeletedItems} workstations.`);
        }
      } 
      else if (deleteType === 'connections') {
        // Only applies to server projects
        if (!isServerProject) {
          setSnackbarMessage('Connections can only be deleted from server projects.');
          return;
        }
        
        // Get all connections
        const connectionsSnapshot = await firestore.collection(`${projectRef}/connections`).get();
        
        // Delete in batches
        const batchSize = 450;
        let batch = firestore.batch();
        let operationCount = 0;
        let totalDeleted = 0;
        
        connectionsSnapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
          operationCount++;
          totalDeleted++;
          
          if (operationCount >= batchSize) {
            // Commit the current batch and start a new one
            batch.commit();
            batch = firestore.batch();
            operationCount = 0;
          }
        });
        
        // Commit any remaining operations
        if (operationCount > 0) {
          await batch.commit();
        }
        
        setSnackbarMessage(`Successfully deleted ${totalDeleted} connections.`);
      }
    } catch (error) {
      console.error(`Error deleting ${deleteType}:`, error);
      setSnackbarMessage(`Error deleting ${deleteType}: ${error.message}`);
    } finally {
      setDeleteInProgress(false);
      setDeleteDialogOpen(false);
      setDeleteType('');
    }
  };

  // Function to add a new device
  const handleAddDevice = async () => {
    if (!auth.uid) return;
    
    setAddingDevice(true);
    
    try {
      const projectRef = selectedProject?.refLocation?.path || refLocation;
      
      if (!projectRef) {
        throw new Error('Project reference path not found');
      }
      
      // Determine if this is a server or workstation project
      const isServerProject = projectRef.includes('serverProjects');
      const collectionName = isServerProject ? 'servers' : 'workstations';
      
      // Validation based on project type
      if (!isServerProject) {
        // For workstation projects, only require destination
        if (!newDeviceData.destination) {
          throw new Error('Destination is required');
        }
      }
      // For server projects, all fields are optional
      
      // Generate a unique ID for the new device using uuidv4 (consistent with upload handlers)
      const deviceId = uuidv4();
      
      // Prepare the data - don't add extra fields to be consistent with upload handlers
      const deviceData = {
        ...newDeviceData
      };
      
      // Convert field values to appropriate types based on the field type mapping similar to ServerUploadHandler
      if (isServerProject) {
        // Field types for server projects (match those in ServerUploadHandler.js)
        const fieldTypes = {
          'originBuilding': 'string',
          'size': 'number',
          'originRow': 'string',
          'originRack': 'string',
          'originRackUnit': 'number',
          'originRearMounted': 'boolean',
          'destinationBuilding': 'string',
          'destinationRow': 'string',
          'destinationRack': 'string',
          'destinationRackUnit': 'number',
          'destinationRearMounted': 'boolean',
          'type': 'string',
          'model': 'string',
          'serialNumber': 'string',
          'destinationConfirmed': 'boolean',
          'hasRails': 'boolean',
          'ip': 'string',
          'make': 'string',
          'notes': 'string',
          'originConfirmed': 'boolean',
          'uid': 'string',
          'hostname': 'string',
          'deviceName': 'string'
        };
        
        // Convert each field based on its type
        Object.keys(deviceData).forEach(field => {
          if (deviceData[field] === undefined || deviceData[field] === null || deviceData[field] === '') return;
          
          const fieldType = fieldTypes[field];
          if (!fieldType) return; // Skip if field type is not defined
          
          switch (fieldType) {
            case 'string':
              deviceData[field] = String(deviceData[field]);
              break;
            case 'number':
              deviceData[field] = Number(deviceData[field]);
              break;
            case 'boolean':
              if (deviceData[field] === 'true' || deviceData[field] === true) {
                deviceData[field] = true;
              } else if (deviceData[field] === 'false' || deviceData[field] === false) {
                deviceData[field] = false;
              }
              break;
          }
        });
        
        // Set default size of 1 if not provided (matching ServerUploadHandler.js behavior)
        if (!deviceData.size) {
          deviceData.size = 1;
        }
      } else {
        // For workstation projects, all fields are strings (match WorkstationUploadHandler.js)
        Object.keys(deviceData).forEach(field => {
          if (deviceData[field] !== undefined && deviceData[field] !== null && deviceData[field] !== '') {
            deviceData[field] = String(deviceData[field]).trim();
          }
        });
      }
      
      // Add the device to the appropriate collection
      await firestore.collection(`${projectRef}/${collectionName}`).doc(deviceId).set(deviceData);
      
      // Show success message and close dialog
      setSnackbarMessage(`${isServerProject ? 'Device' : 'Workstation'} added successfully`);
      setAddDeviceDialogOpen(false);
      setNewDeviceData({});
      
    } catch (error) {
      console.error('Error adding device:', error);
      setSnackbarMessage(`Error adding ${isServerProject ? 'device' : 'workstation'}: ${error.message}`);
    } finally {
      setAddingDevice(false);
    }
  };

  // Get the project reference path and determine if this is a server or workstation project
  const projectRef = selectedProject?.refLocation?.path || refLocation;
  const isServerProject = projectRef?.includes('serverProjects');

  return (
    <>
      <Button 
        onClick={handleOpenEditMenu} 
        variant="contained" 
        startIcon={<EditIcon />}
      >
        Edit
      </Button>

      {/* Edit Menu */}
      <Menu
        anchorEl={editMenuAnchorEl}
        open={Boolean(editMenuAnchorEl)}
        onClose={handleCloseEditMenu}
      >
        {/* Only show Change Client option for admins and supervisors */}
        {(profile.role === 'admin' || profile.role === 'supervisor') && (
          <MenuItem onClick={handleChangeClientOption}>
            Change Client
          </MenuItem>
        )}
        <MenuItem onClick={handleAddDeviceOption}>
          Add {isServerProject ? 'Device' : 'Workstation'}
        </MenuItem>
        <MenuItem onClick={() => handleDeleteOption('singleDevice')}>
          Delete A {isServerProject ? 'Device' : 'Workstation'}
        </MenuItem>
        <MenuItem onClick={() => handleDeleteOption('devices')}>
          Delete All {isServerProject ? 'Devices' : 'Workstations'}
        </MenuItem>
        {isServerProject && (
          <MenuItem onClick={() => handleDeleteOption('connections')}>
            Delete All Connections
          </MenuItem>
        )}
      </Menu>

      {/* Change Client Dialog */}
      <Dialog
        open={changeClientDialogOpen}
        onClose={handleCloseChangeClientDialog}
      >
        <DialogTitle>Change Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a new client for this project. This will move the project to the selected client and update all references.
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="client-select-label">Client</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={selectedClientId}
              label="Client"
              onChange={handleClientChange}
              disabled={changingClient || isLoading}
            >
              {Object.keys(clientNames || {}).map((clientID) => (
                <MenuItem 
                  key={clientID} 
                  value={clientID}
                >
                  {clientNames[clientID]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
            Current client: {currentClientId ? clientNames[currentClientId] || 'Unknown' : 'None'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChangeClientDialog} disabled={changingClient}>
            Cancel
          </Button>
          <Button 
            onClick={handleChangeClient} 
            color="primary" 
            variant="contained"
            disabled={changingClient || !selectedClientId || selectedClientId === currentClientId}
          >
            {changingClient ? 'Changing...' : 'Change Client'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>
          {deleteType === 'devices' 
            ? (isServerProject 
              ? 'Delete All Devices and Connections?' 
              : 'Delete All Workstations?')
            : 'Delete All Connections?'
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteType === 'devices' 
              ? (isServerProject
                ? 'This will permanently delete ALL devices AND connections for this project. This action cannot be undone.'
                : 'This will permanently delete ALL workstations for this project. This action cannot be undone.')
              : 'This will permanently delete ALL connections for this project. This action cannot be undone.'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} disabled={deleteInProgress}>
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            color="error" 
            variant="contained"
            disabled={deleteInProgress}
          >
            {deleteInProgress ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Add Device Dialog */}
      <Dialog
        open={addDeviceDialogOpen}
        onClose={() => setAddDeviceDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Add {isServerProject ? 'Device' : 'Workstation'}
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
            {isServerProject ? (
              // Server project fields
              <>
                <TextField
                  name="type"
                  label="Type"
                  value={newDeviceData.type || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, type: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="make"
                  label="Make"
                  value={newDeviceData.make || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, make: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="model"
                  label="Model"
                  value={newDeviceData.model || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, model: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="serialNumber"
                  label="Serial Number"
                  value={newDeviceData.serialNumber || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, serialNumber: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="hostname"
                  label="Hostname"
                  value={newDeviceData.hostname || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, hostname: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="originBuilding"
                  label="Origin Building"
                  value={newDeviceData.originBuilding || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, originBuilding: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="originRow"
                  label="Origin Row"
                  value={newDeviceData.originRow || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, originRow: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="originRack"
                  label="Origin Rack"
                  value={newDeviceData.originRack || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, originRack: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="originRackUnit"
                  label="Origin Rack Unit"
                  value={newDeviceData.originRackUnit || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, originRackUnit: e.target.value})}
                  fullWidth
                />
                <FormControl fullWidth>
                  <InputLabel>Origin Rear Mounted</InputLabel>
                  <Select
                    name="originRearMounted"
                    value={newDeviceData.originRearMounted || ''}
                    label="Origin Rear Mounted"
                    onChange={e => setNewDeviceData({...newDeviceData, originRearMounted: e.target.value})}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  name="destinationBuilding"
                  label="Destination Building"
                  value={newDeviceData.destinationBuilding || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, destinationBuilding: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="destinationRow"
                  label="Destination Row"
                  value={newDeviceData.destinationRow || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, destinationRow: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="destinationRack"
                  label="Destination Rack"
                  value={newDeviceData.destinationRack || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, destinationRack: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="destinationRackUnit"
                  label="Destination Rack Unit"
                  value={newDeviceData.destinationRackUnit || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, destinationRackUnit: e.target.value})}
                  fullWidth
                />
                <FormControl fullWidth>
                  <InputLabel>Destination Rear Mounted</InputLabel>
                  <Select
                    name="destinationRearMounted"
                    value={newDeviceData.destinationRearMounted || ''}
                    label="Destination Rear Mounted"
                    onChange={e => setNewDeviceData({...newDeviceData, destinationRearMounted: e.target.value})}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
              </>
            ) : (
              // Workstation project fields
              <>
                <TextField
                  name="username"
                  label="Username"
                  value={newDeviceData.username || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, username: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="origin"
                  label="Origin"
                  value={newDeviceData.origin || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, origin: e.target.value})}
                  fullWidth
                />
                <TextField
                  name="destination"
                  label="Destination"
                  value={newDeviceData.destination || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, destination: e.target.value})}
                  fullWidth
                  required
                />
                <TextField
                  name="notes"
                  label="Notes"
                  value={newDeviceData.notes || ''}
                  onChange={e => setNewDeviceData({...newDeviceData, notes: e.target.value})}
                  fullWidth
                  multiline
                  rows={2}
                />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDeviceDialogOpen(false)} disabled={addingDevice}>
            Cancel
          </Button>
          <Button 
            onClick={handleAddDevice} 
            color="primary" 
            variant="contained"
            disabled={addingDevice}
          >
            {addingDevice ? 'Adding...' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Device Selection Dialog */}
      <Dialog
        open={deviceSelectDialogOpen}
        onClose={handleCloseDeviceSelectDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Select {isServerProject ? 'Device' : 'Workstation'} to Delete
        </DialogTitle>
        <DialogContent>
          {loadingDevices ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : devices.length === 0 ? (
            <Typography align="center" sx={{ p: 2 }}>
              No {isServerProject ? 'devices' : 'workstations'} found for this project.
            </Typography>
          ) : (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
                      <Checkbox
                        indeterminate={selectedDeviceIds.length > 0 && selectedDeviceIds.length < devices.length}
                        checked={devices.length > 0 && selectedDeviceIds.length === devices.length}
                        onChange={handleSelectAll}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TableCell>
                    
                    {/* Server-specific Type field */}
                    {isServerProject && (
                      <TableCell 
                        onClick={() => handleSort('type')}
                        sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          Type
                          {sortConfig.key === 'type' && (
                            sortConfig.direction === 'asc' 
                              ? <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                              : <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                          )}
                        </Box>
                      </TableCell>
                    )}
                    
                    {/* Server project specific fields */}
                    {isServerProject && (
                      <>
                        <TableCell 
                          onClick={() => handleSort('hostname')}
                          sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Hostname / Name
                            {sortConfig.key === 'hostname' && (
                              sortConfig.direction === 'asc' 
                                ? <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                                : <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell 
                          onClick={() => handleSort('serialNumber')}
                          sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Serial Number
                            {sortConfig.key === 'serialNumber' && (
                              sortConfig.direction === 'asc' 
                                ? <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                                : <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell 
                          onClick={() => handleSort('model')}
                          sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Make/Model
                            {sortConfig.key === 'model' && (
                              sortConfig.direction === 'asc' 
                                ? <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                                : <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                            )}
                          </Box>
                        </TableCell>
                      </>
                    )}
                    
                    {/* Workstation project specific fields */}
                    {!isServerProject && (
                      <>
                        <TableCell 
                          onClick={() => handleSort('username')}
                          sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Username
                            {sortConfig.key === 'username' && (
                              sortConfig.direction === 'asc' 
                                ? <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                                : <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell 
                          onClick={() => handleSort('destination')}
                          sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Destination
                            {sortConfig.key === 'destination' && (
                              sortConfig.direction === 'asc' 
                                ? <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                                : <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                            )}
                          </Box>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getSortedDevices().map((device) => (
                    <TableRow 
                      key={device.id}
                      selected={selectedDeviceIds.includes(device.id)}
                      onClick={() => handleDeviceSelect(device.id)}
                      hover
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          checked={selectedDeviceIds.includes(device.id)}
                          onChange={() => handleDeviceSelect(device.id)}
                          onClick={(e) => e.stopPropagation()}
                          color="primary"
                        />
                      </TableCell>
                      {/* Server project specific columns */}
                      {isServerProject && (
                        <>
                          <TableCell>{device.type}</TableCell>
                          <TableCell>{device.hostname || device.deviceName || 'N/A'}</TableCell>
                          <TableCell>{device.serialNumber || 'N/A'}</TableCell>
                          <TableCell>{(device.make && device.model) ? `${device.make} ${device.model}` : (device.make || device.model || 'N/A')}</TableCell>
                        </>
                      )}
                      
                      {/* Workstation project specific columns */}
                      {!isServerProject && (
                        <>
                          <TableCell>{device.username || 'N/A'}</TableCell>
                          <TableCell>
                            {device.destination || 
                             (device.destinationBuilding || device.destinationFloor ? 
                              `${device.destinationBuilding || ''} ${device.destinationFloor || ''}`.trim() : 
                              'N/A')}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeviceSelectDialog} disabled={deleteInProgress}>
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteSelectedDevices} 
            color="error" 
            variant="contained"
            startIcon={<DeleteIcon />}
            disabled={deleteInProgress || selectedDeviceIds.length === 0}
          >
            {deleteInProgress ? 'Deleting...' : `Delete ${selectedDeviceIds.length > 0 ? `(${selectedDeviceIds.length})` : ''}`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectEditManager;