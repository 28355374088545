import React, { useState } from 'react';
import { Box, Menu, Typography, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { 
  useConnectionsData, 
  useConnectionUpdates, 
  useSortAndFilter, 
  useColumnVisibility, 
  useInlineEditing 
} from './components/hooks';
import ConnectionsTable from './components/ConnectionsTable';
import SearchBar from './components/SearchBar';
import { ColumnFilterMenu, ConnectionStatusFilterMenu } from './components/FilterMenus';
import { getUniqueColumnValues } from './components/utils';

function ServerConnections({ server = {}, projectRef }) {
  // State for zoom level (100% by default)
  const [zoomLevel, setZoomLevel] = useState(100);
  
  // Fetch connections data
  const { 
    connections, 
    loading, 
    fetchConnections 
  } = useConnectionsData(server, projectRef);
  
  // Connection update functionality
  const { 
    toggleConnectionStatus, 
    updatingConnections 
  } = useConnectionUpdates(projectRef);
  
  // Sorting and filtering functionality
  const { 
    sortConfig,
    searchQuery,
    searchColumn,
    showSearch,
    filters,
    anchorEl,
    filterColumn,
    isConnectedFilterAnchorEl,
    handleSortChange,
    handleSearchChange,
    toggleSearch,
    handleFilterClick,
    handleCloseFilter,
    toggleFilter,
    clearColumnFilters,
    handleIsConnectedFilterClick,
    handleIsConnectedFilterClose,
    toggleIsConnectedFilter,
    clearIsConnectedFilters,
    getSortedAndFilteredConnections
  } = useSortAndFilter();
  
  // Column visibility management
  const {
    hideServer1Column,
    hidePort1Column,
    hideCableTypeColumn,
    hideServer2Column,
    hidePort2Column,
    hideDestinationColumn,
    hideIsConnectedColumn,
    hideNotesColumn,
    setHideServer1Column,
    setHidePort1Column,
    setHideCableTypeColumn,
    setHideServer2Column,
    setHidePort2Column,
    setHideDestinationColumn,
    setHideIsConnectedColumn,
    setHideNotesColumn,
    showHeaderOptions,
    toggleHeaderOptions
  } = useColumnVisibility();
  
  // Inline editing functionality
  const {
    editingCell,
    editValue,
    updateLoading,
    connections: localConnections, // Use locally managed connections from the hook
    setLocalConnections, // Get the setter function
    handleEditClick,
    handleCancelEdit,
    handleEditChange,
    handleEditKeyPress,
    handleSaveEdit
  } = useInlineEditing(connections, projectRef);
  
  // Handle toggling the connected status
  const handleToggleConnected = async (connection) => {
    try {
      const newStatus = await toggleConnectionStatus(connection);
      
      // Update localConnections to reflect the change immediately in UI
      if (newStatus !== null) {
        setLocalConnections(prevConnections => 
          prevConnections.map(conn => {
            if (conn.id === connection.id) {
              return { ...conn, isConnected: newStatus };
            }
            return conn;
          })
        );
      }
      
      return newStatus;
    } catch (error) {
      console.error('Error toggling connection status:', error);
      return null;
    }
  };
  
  // Get the sorted and filtered connections
  const sortedAndFilteredConnections = getSortedAndFilteredConnections(localConnections);
  
  // Handler for column search click
  const handleSearchClick = (columnKey) => {
    toggleSearch(columnKey);
  };

  return (
    <>
      {/* Show search bar if any search is active */}
      {Object.keys(showSearch).some(key => showSearch[key]) && (
        <SearchBar 
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
          searchColumn={searchColumn}
        />
      )}
      
      {/* Main connections table */}
      <ConnectionsTable
        server={server}
        connections={localConnections}
        loading={loading}
        sortConfig={sortConfig}
        sortedAndFilteredConnections={sortedAndFilteredConnections}
        filters={filters}
        showHeaderOptions={showHeaderOptions}
        handleSortChange={handleSortChange}
        toggleHeaderOptions={toggleHeaderOptions}
        hideServer1Column={hideServer1Column}
        hidePort1Column={hidePort1Column}
        hideCableTypeColumn={hideCableTypeColumn}
        hideServer2Column={hideServer2Column}
        hidePort2Column={hidePort2Column}
        hideDestinationColumn={hideDestinationColumn}
        hideIsConnectedColumn={hideIsConnectedColumn}
        hideNotesColumn={hideNotesColumn}
        setHideServer1Column={setHideServer1Column}
        setHidePort1Column={setHidePort1Column}
        setHideCableTypeColumn={setHideCableTypeColumn}
        setHideServer2Column={setHideServer2Column}
        setHidePort2Column={setHidePort2Column}
        setHideDestinationColumn={setHideDestinationColumn}
        setHideIsConnectedColumn={setHideIsConnectedColumn}
        setHideNotesColumn={setHideNotesColumn}
        editingCell={editingCell}
        zoomLevel={zoomLevel}
        setZoomLevel={setZoomLevel}
        handleEditClick={handleEditClick}
        handleSaveEdit={handleSaveEdit}
        handleCancelEdit={handleCancelEdit}
        editValue={editValue}
        updateLoading={updateLoading}
        handleEditChange={handleEditChange}
        handleEditKeyPress={handleEditKeyPress}
        handleToggleConnected={handleToggleConnected}
        updatingConnections={updatingConnections}
        fetchConnections={fetchConnections}
        onFilterClick={handleFilterClick}
        onSearchClick={handleSearchClick}
      />
      
      {/* Column Filter Menu */}
      {filterColumn && (
        <ColumnFilterMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseFilter}
          column={filterColumn}
          values={getUniqueColumnValues(localConnections, filterColumn, server)}
          activeFilters={filters[filterColumn] || []}
          toggleFilter={toggleFilter}
          clearFilters={() => clearColumnFilters(filterColumn)}
        />
      )}
      
      {/* IsConnected Filter Menu */}
      <ConnectionStatusFilterMenu
        anchorEl={isConnectedFilterAnchorEl}
        open={Boolean(isConnectedFilterAnchorEl)}
        onClose={handleIsConnectedFilterClose}
        activeFilters={filters.isConnected}
        toggleFilter={toggleIsConnectedFilter}
        clearFilters={clearIsConnectedFilters}
      />
    </>
  );
}

export default ServerConnections;