import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { 
  modelAlternativeOptions, 
  selectedModelAlternative,
  destinationAlternativeOptions,
  selectedDestinationAlternative
} from '../../../utils/status/constants';

const TabLabel = ({ 
  displayLabel, 
  fieldKey, 
  sortConfig, 
  projectType, 
  toggleSearch, 
  handleServerTypeFilterIcon,
  handleStatusFilterClick,
  handleUsernameFilterClick
}) => {
  const isWorkstation = projectType === 'workstations';
  const isServer = projectType === 'servers';
  
  // State for the model field menu
  const [modelMenuAnchorEl, setModelMenuAnchorEl] = useState(null);
  const modelMenuOpen = Boolean(modelMenuAnchorEl);
  
  // State for the destination field menu
  const [destinationMenuAnchorEl, setDestinationMenuAnchorEl] = useState(null);
  const destinationMenuOpen = Boolean(destinationMenuAnchorEl);
  
  // Handle model field menu open
  const handleModelMenuClick = (event) => {
    event.stopPropagation();
    setModelMenuAnchorEl(event.currentTarget);
  };
  
  // Handle model field menu close
  const handleModelMenuClose = () => {
    setModelMenuAnchorEl(null);
  };
  
  // Handle destination field menu open
  const handleDestinationMenuClick = (event) => {
    event.stopPropagation();
    setDestinationMenuAnchorEl(event.currentTarget);
  };
  
  // Handle destination field menu close
  const handleDestinationMenuClose = () => {
    setDestinationMenuAnchorEl(null);
  };
  
  // Handle model alternative selection
  const handleModelAlternativeSelect = (option) => {
    // Only proceed if the selection is actually different
    if (selectedModelAlternative.key !== option.key) {
      // Update the global selected model alternative
      selectedModelAlternative.label = option.label;
      selectedModelAlternative.key = option.key;
      
      // Save preference to localStorage for persistence
      localStorage.setItem('serverModelAlternative', JSON.stringify(option));
      
      // Create and dispatch a custom event with the new sort field and current direction
      const event = new CustomEvent('modelAlternativeChanged', { 
        detail: { 
          key: option.key, 
          label: option.label,
          direction: localSortDirection // Include current sort direction
        } 
      });
      window.dispatchEvent(event);
      
      // Force a re-render of the component
      window.dispatchEvent(new Event('storage'));
    }
    
    handleModelMenuClose();
  };
  
  // Handle destination alternative selection
  const handleDestinationAlternativeSelect = (option) => {
    // Only proceed if the selection is actually different
    if (selectedDestinationAlternative.key !== option.key) {
      // Update the global selected destination alternative
      selectedDestinationAlternative.label = option.label;
      selectedDestinationAlternative.key = option.key;
      
      // Save preference to localStorage for persistence
      localStorage.setItem('serverDestinationAlternative', JSON.stringify(option));
      
      // Create and dispatch a custom event with the new alternative
      const event = new CustomEvent('destinationAlternativeChanged', { 
        detail: { 
          key: option.key, 
          label: option.label
        } 
      });
      window.dispatchEvent(event);
      
      // Force a re-render of the component
      window.dispatchEvent(new Event('storage'));
    }
    
    handleDestinationMenuClose();
  };
  
  // Load saved preferences on mount
  useEffect(() => {
    try {
      // Load model alternative
      const savedModelAlternative = localStorage.getItem('serverModelAlternative');
      if (savedModelAlternative) {
        const parsedValue = JSON.parse(savedModelAlternative);
        selectedModelAlternative.key = parsedValue.key;
        selectedModelAlternative.label = parsedValue.label;
      }
      
      // Load destination alternative
      const savedDestinationAlternative = localStorage.getItem('serverDestinationAlternative');
      if (savedDestinationAlternative) {
        const parsedValue = JSON.parse(savedDestinationAlternative);
        selectedDestinationAlternative.key = parsedValue.key;
        selectedDestinationAlternative.label = parsedValue.label;
      }
    } catch (e) {
      console.error('Error loading saved preferences:', e);
    }
  }, []);
  
  // Decide which fields can show the search icon
  const canSearch =
    (isWorkstation && (fieldKey === 'username' || fieldKey === 'destination')) ||
    (isServer && (fieldKey === 'model' || fieldKey === 'destination')); // fieldKey is 'destination' even when showing Cart label, so it covers both
    
  // Check if we should show sort arrows for model alternatives
  const isModelAlternative = isServer && fieldKey === 'model';
  const isModelAlternativeSorted = isModelAlternative && 
    ['model', 'hostname', 'serialNumber', 'make', 'deviceName'].includes(sortConfig.key);
    
  // Force a refresh when the sort config changes  
  const [, forceUpdate] = useState({});
  
  // Track sort direction for model alternatives
  const [localSortDirection, setLocalSortDirection] = useState(sortConfig.direction);
  
  // Update when sort config changes or menu opens/closes
  useEffect(() => {
    const handleStorage = () => forceUpdate({});
    window.addEventListener('storage', handleStorage);
    
    // Listen for model alternative changes
    const handleModelChange = () => {
      setTimeout(() => forceUpdate({}), 50);
    };
    window.addEventListener('modelAlternativeChanged', handleModelChange);
    
    // Listen for destination alternative changes
    const handleDestinationChange = () => {
      setTimeout(() => forceUpdate({}), 50);
    };
    window.addEventListener('destinationAlternativeChanged', handleDestinationChange);
    
    // Listen for sort direction changes
    const handleSortDirectionChange = (event) => {
      console.log(`TabLabel received sort direction change: ${event.detail.direction}`);
      setLocalSortDirection(event.detail.direction);
    };
    window.addEventListener('sortDirectionChanged', handleSortDirectionChange);
    
    return () => {
      window.removeEventListener('storage', handleStorage);
      window.removeEventListener('modelAlternativeChanged', handleModelChange);
      window.removeEventListener('destinationAlternativeChanged', handleDestinationChange);
      window.removeEventListener('sortDirectionChanged', handleSortDirectionChange);
    };
  }, []);
  
  // Also update localSortDirection when sortConfig changes
  useEffect(() => {
    setLocalSortDirection(sortConfig.direction);
  }, [sortConfig.direction]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', maxWidth: '100%' }}>
      <Typography variant="body1">
        {/* Use the selectedModelAlternative.label if it's the model tab in server project */}
        {isServer && fieldKey === 'model' ? selectedModelAlternative.label : 
         /* Use the selectedDestinationAlternative.label if it's the destination tab in server project */
         (isServer && fieldKey === 'destination' ? selectedDestinationAlternative.label : displayLabel)}
      </Typography>
      
      {/* Model Tab Special Feature - Show swap icon for model field in server projects */}
      {isServer && fieldKey === 'model' && (
        <IconButton
          size="small"
          onClick={handleModelMenuClick}
          title="Change displayed field"
        >
          <SwapHorizIcon fontSize="small" />
        </IconButton>
      )}
      
      {/* Destination Tab Special Feature - Show swap icon for destination field in server projects */}
      {isServer && fieldKey === 'destination' && (
        <IconButton
          size="small"
          onClick={handleDestinationMenuClick}
          title="Change destination display"
        >
          <SwapHorizIcon fontSize="small" />
        </IconButton>
      )}
      
      {/* Model Field Menu */}
      <Menu
        anchorEl={modelMenuAnchorEl}
        open={modelMenuOpen}
        onClose={handleModelMenuClose}
        onClick={(e) => e.stopPropagation()}
      >
        {modelAlternativeOptions.map((option) => (
          <MenuItem 
            key={option.key} 
            onClick={() => handleModelAlternativeSelect(option)}
            selected={option.key === selectedModelAlternative.key}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      
      {/* Destination Field Menu */}
      <Menu
        anchorEl={destinationMenuAnchorEl}
        open={destinationMenuOpen}
        onClose={handleDestinationMenuClose}
        onClick={(e) => e.stopPropagation()}
      >
        {destinationAlternativeOptions.map((option) => (
          <MenuItem 
            key={option.key} 
            onClick={() => handleDestinationAlternativeSelect(option)}
            selected={option.key === selectedDestinationAlternative.key}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>

      {/* Show arrow/filter icons if this is the current sort key or a model alternative */}
      {(sortConfig.key === fieldKey || isModelAlternativeSorted) && (
        <>
          {/* Use localSortDirection for model alternatives */}
          {(isModelAlternativeSorted ? localSortDirection : sortConfig.direction) === 'asc' ? (
            <ArrowUpwardIcon fontSize="small" />
          ) : (
            <ArrowDownwardIcon fontSize="small" />
          )}

          {/* Show search icon for regular fields or destination field */}
          {canSearch && (
            <IconButton 
              size="small" 
              onClick={(event) => {
                // When in destination tab, always use fieldKey 'destination'
                // The toggle function will handle it properly based on selectedDestinationAlternative
                toggleSearch(event, fieldKey);
              }}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          )}

          {/* Additional workstation-only filters */}
          {isWorkstation && fieldKey === 'username' && (
            <IconButton
              size="small"
              onClick={handleUsernameFilterClick}
            >
              <FilterListIcon fontSize="small" />
            </IconButton>
          )}

          {isWorkstation && fieldKey === 'status' && (
            <IconButton
              size="small"
              onClick={handleStatusFilterClick}
            >
              <FilterListIcon fontSize="small" />
            </IconButton>
          )}

          {/* For SERVERS: show a filter icon if fieldKey === "type" */}
          {isServer && fieldKey === 'type' && (
            <IconButton
              size="small"
              onClick={handleServerTypeFilterIcon}
            >
              <FilterListIcon fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

export default TabLabel;