import { useState, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase';

const useClientNames = (auth, profile) => {
  const firestore = useFirestore();
  const [clientNames, setClientNames] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    if (!auth.uid || !profile) {
      setIsLoading(false); // No auth/profile, no loading
      return;
    }

    const loadClients = async () => {
      try {
        setIsLoading(true); // Start loading
        if (profile.role === 'admin' || profile.role === 'supervisor') {
          const clientSnapshot = await firestore.collection('clients').get();
          const allClients = clientSnapshot.docs.map(clientDoc => ({
            id: clientDoc.id,
            name: clientDoc.data().name,
          }));
          const sortedClients = allClients.sort((a, b) => a.name.localeCompare(b.name));
          setClientNames(sortedClients.reduce((acc, client) => ({ ...acc, [client.id]: client.name }), {}));
        } else {
          const userDoc = await firestore.collection('users').doc(auth.uid).get();
          const data = userDoc.data();
          const clientScope = data.clientScope || [];

          if (!Array.isArray(clientScope) || clientScope.length === 0) {
            console.error('No clients available in clientScope');
            setClientNames({});
            return;
          }

          const clientPromises = clientScope.map(async (clientID) => {
            const clientDoc = await firestore.collection('clients').doc(clientID).get();
            if (!clientDoc.exists) {
              console.error(`Client with ID ${clientID} does not exist`);
              return null;
            }
            const clientData = clientDoc.data();
            return { id: clientID, name: clientData.name };
          });

          const clients = (await Promise.all(clientPromises)).filter(Boolean);
          setClientNames(clients.reduce((acc, client) => ({ ...acc, [client.id]: client.name }), {}));
        }
      } catch (error) {
        console.error('Error fetching client names:', error);
        setClientNames({});
      } finally {
        setIsLoading(false); // Done loading, success or failure
      }
    };

    loadClients();
  }, [firestore, auth.uid, profile]);

  return { clientNames, isLoading }; // Return both
};

export default useClientNames;