import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import emptyPic from '../../../assets/no-pic-loaded.PNG';
import './BasicInfo.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import ServerConnections from './ServerConnections';
import CartSelector from './components/CartSelector';

function BasicInfo({ server = {}, projectRef }) {
  const Field = ({ label, value }) => {
    return value ? (
      <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
        {`${label}: ${value}`}
      </Typography>
    ) : null;
  };

  return (
    <Grid container spacing={2}>

      <Grid item xs={12} sm={4} md={3} display="flex" flexDirection="column" alignItems="center">
        <img
          src={server.pictures?.[0] || emptyPic}
          style={{ maxHeight: 250, maxWidth: '100%' }}
          alt="server preview"
        />
        {/* <Typography sx={{ mt: 2, fontWeight: 'bold' }}>
          {server.hostname || server.deviceName || ""}
        </Typography> */}
      </Grid>

      <Grid item xs={12} sm={8} md={9}>
        <Typography variant="h5" textAlign="center" sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' }, fontWeight: 'bold' }}>
          {server.hostname || server.serialNumber || server.deviceName ||  server.model || ''}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" sx={{ padding: { xs: 1, sm: 2 }, height: '100%' }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold', mb: 1 }}>Origin</Typography>
              <Box>
                <Field label="Building" value={server.originBuilding} />
                <Field label="Row" value={server.row} />
                <Field label="Rack" value={server.originRack} />
                <Field label="Rack Unit" value={server.originRackUnit} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" sx={{ padding: { xs: 1, sm: 2 }, height: '100%' }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold', mb: 1 }}>Destination</Typography>
              <Box>
                <Field label="Building" value={server.destinationBuilding} />
                <Field label="Row" value={server.destinationRow} />
                <Field label="Rack" value={server.destinationRack} />
                <Field label="Rack Unit" value={server.destinationRackUnit} />
                {/* Cart Selector Component */}
                {server.documentId && <CartSelector server={server} projectRef={projectRef} />}
              </Box>
            </Paper>
          </Grid>
          
          {/* Server Details */}
          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ padding: { xs: 1, sm: 2 } }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold', mb: 1 }}>
                Device Details
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ width: { xs: '100%', sm: '50%' }, pr: { xs: 0, sm: 1 } }}>
                  <Field label="Type" value={server.type} />
                  <Field label="Make" value={server.make} />
                  <Field label="Serial Number" value={server.serialNumber} />
                </Box>
                <Box sx={{ width: { xs: '100%', sm: '50%' }, pl: { xs: 0, sm: 1 } }}>
                  <Field label="IP" value={server.ip} />
                  <Field label="Hostname" value={server.hostname} />
                  <Field label="Device Name" value={server.deviceName} />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* Connections Section */}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Paper variant="outlined" sx={{ padding: { xs: 1, sm: 2 } }}>
          <ServerConnections server={server} projectRef={projectRef} />
        </Paper>
      </Grid>

    </Grid>
  );
}

export default BasicInfo;