import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse';

// Field type mapping for proper data conversion
const fieldTypes = {
  'originBuilding': 'string',
  'size': 'number',
  'originRow': 'string',
  'originRack': 'string',
  'originRackUnit': 'number',
  'originRearMounted': 'boolean',
  'destinationBuilding': 'string',
  'destinationRow': 'string',
  'destinationRack': 'string',
  'destinationRackUnit': 'number',
  'destinationRearMounted': 'boolean',
  'type': 'string',
  'model': 'string',
  'serialNumber': 'string',
  'destinationConfirmed': 'boolean',
  'hasRails': 'boolean',
  'ip': 'string',
  'make': 'string',
  'notes': 'string',
  'originConfirmed': 'boolean',
  'uid': 'string',
  'hostname': 'string',
  'deviceName': 'string'
};

// Column name normalization mapping (for case-insensitive matching)
const columnMapping = {
  'originbuilding': 'originBuilding',
  'origin building': 'originBuilding',
  'origin lab/room': 'originBuilding',
  'size': 'size',
  'originrow': 'originRow',
  'origin row': 'originRow',
  'originrack': 'originRack',
  'origin rack': 'originRack',
  'originrackunit': 'originRackUnit',
  'origin ru': 'originRackUnit',
  'originrearmounted': 'originRearMounted',
  'origin rear mount': 'originRearMounted',
  'origin rear mounted': 'originRearMounted',
  'destinationbuilding': 'destinationBuilding',
  'destination building': 'destinationBuilding',
  'destination lab/room': 'destinationBuilding',
  'destinationrow': 'destinationRow',
  'destination row': 'destinationRow',
  'destinationrack': 'destinationRack',
  'destination rack': 'destinationRack',
  'destinationrackunit': 'destinationRackUnit',
  'destination ru': 'destinationRackUnit',
  'destinationrearmounted': 'destinationRearMounted',
  'destination rear mount': 'destinationRearMounted',
  'destination rear mounted': 'destinationRearMounted',
  'type': 'type',
  'model': 'model',
  'serialnumber': 'serialNumber',
  's/n': 'serialNumber',
  'serial': 'serialNumber',
  'serial number': 'serialNumber',
  'destinationconfirmed': 'destinationConfirmed',
  'destination confirmed': 'destinationConfirmed',
  'hasrails': 'hasRails',
  'has rails': 'hasRails',
  'ip': 'ip',
  'make': 'make',
  'notes': 'notes',
  'originconfirmed': 'originConfirmed',
  'origin confirmed': 'originConfirmed',
  'uid': 'uid',
  'hostname': 'hostname',
  'devicename': 'deviceName',
  'device name': 'deviceName'
};

const handleServerFileUpload = async (rows, firestore, selectedProject, onComplete) => {
  try {
    // Process the rows to get the data to submit
    const processedRows = rows.map(row => {
      const dataToSubmit = {
        size: 1  // Set default size of 1 if not provided
      };
      
      // Process each field in the row
      Object.entries(row).forEach(([header, value]) => {
        // Skip if value is empty
        if (value === undefined || value === null || value === '') return;
        
        // Normalize the header name (lowercase and remove spaces)
        const normalizedHeader = header.toLowerCase().trim();
        
        // Get the standardized field name from our mapping
        const fieldName = columnMapping[normalizedHeader];
        
        // Skip if we don't recognize this field
        if (!fieldName) {
          console.log(`Ignoring unknown column: ${header}`);
          return;
        }
        
        // Get the field type
        const fieldType = fieldTypes[fieldName];
        
        // Convert value based on type
        switch (fieldType) {
          case 'string':
            dataToSubmit[fieldName] = `${value}`;
            break;
          case 'number':
            dataToSubmit[fieldName] = Number(value);
            break;
          case 'boolean':
            if (value === 'TRUE' || value === 'true' || value === true) {
              dataToSubmit[fieldName] = true;
            } else if (value === 'FALSE' || value === 'false' || value === false) {
              dataToSubmit[fieldName] = false;
            }
            break;
          default:
            dataToSubmit[fieldName] = value;
        }
      });
      
      return { originalRow: row, dataToSubmit };
    }).filter(item => Object.keys(item.dataToSubmit).length > 0);
    
    // Check for duplicates by querying the database
    const collectionPath = `${selectedProject?.refLocation?.path}/servers`;
    const serversCollection = firestore.collection(collectionPath);
    
    // Get all existing servers
    const existingServersSnapshot = await serversCollection.get();
    const existingServers = existingServersSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    
    // Find duplicates based on serial number or hostname (if they exist)
    const duplicates = [];
    
    for (const item of processedRows) {
      const { serialNumber, hostname } = item.dataToSubmit;
      
      // Check for duplicates by serial number (if it exists)
      if (serialNumber) {
        const duplicateBySerial = existingServers.find(server => 
          server.serialNumber && server.serialNumber.toLowerCase() === serialNumber.toLowerCase()
        );
        
        if (duplicateBySerial) {
          duplicates.push({
            ...item.originalRow,
            duplicateFound: `Duplicate serial number: ${serialNumber}`,
            duplicateId: duplicateBySerial.id
          });
          continue; // Skip checking hostname if already found as duplicate
        }
      }
      
      // Check for duplicates by hostname (if it exists)
      if (hostname) {
        const duplicateByHostname = existingServers.find(server => 
          server.hostname && server.hostname.toLowerCase() === hostname.toLowerCase()
        );
        
        if (duplicateByHostname) {
          duplicates.push({
            ...item.originalRow,
            duplicateFound: `Duplicate hostname: ${hostname}`,
            duplicateId: duplicateByHostname.id
          });
        }
      }
    }
    
    // If duplicates were found, create a CSV and return it for download
    if (duplicates.length > 0) {
      // Generate CSV data from duplicates
      const fields = Object.keys(duplicates[0]).filter(key => key !== 'duplicateId');
      const csv = Papa.unparse({
        fields,
        data: duplicates.map(duplicate => {
          const row = {};
          fields.forEach(field => {
            row[field] = duplicate[field];
          });
          return row;
        })
      });
      
      // Create a blob for download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      
      // Return an object with duplicate information
      return {
        duplicatesFound: true,
        duplicatesCount: duplicates.length,
        totalCount: processedRows.length,
        duplicateCsvUrl: url,
        processedRows,
        existingServers,
        // Function to proceed with upload (overwriting duplicates)
        proceedWithUpload: async () => {
          return await uploadServers(processedRows, serversCollection, firestore, existingServers);
        }
      };
    }
    
    // If no duplicates, proceed with upload
    const result = await uploadServers(processedRows, serversCollection, firestore);
    
    return {
      count: result.count,
      duplicatesFound: false,
      failedRows: result.failedRows || []
    };
  } catch (error) {
    console.error("Error processing server upload: ", error);
    throw error;
  }
};

// Helper function to upload servers
const uploadServers = async (processedRows, serversCollection, firestore, existingServers = []) => {
  const batch = firestore.batch();
  const failedRows = [];
  let count = 0;
  
  try {
    // Process all rows
    for (const item of processedRows) {
      try {
        const { dataToSubmit } = item;
        
        // Check if we need to update an existing server or create a new one
        let serverToUpdate = null;
        
        if (existingServers.length > 0) {
          // Find by serial number first
          if (dataToSubmit.serialNumber) {
            serverToUpdate = existingServers.find(server => 
              server.serialNumber && server.serialNumber.toLowerCase() === dataToSubmit.serialNumber.toLowerCase()
            );
          }
          
          // If not found by serial, try hostname
          if (!serverToUpdate && dataToSubmit.hostname) {
            serverToUpdate = existingServers.find(server => 
              server.hostname && server.hostname.toLowerCase() === dataToSubmit.hostname.toLowerCase()
            );
          }
        }
        
        if (serverToUpdate) {
          // Update existing server
          const docRef = serversCollection.doc(serverToUpdate.id);
          batch.update(docRef, dataToSubmit);
        } else {
          // Create new server
          const docId = uuidv4();
          const docRef = serversCollection.doc(docId);
          batch.set(docRef, dataToSubmit);
        }
        
        count++;
      } catch (rowError) {
        console.error("Error processing row:", rowError);
        failedRows.push({
          ...item.originalRow,
          error: rowError.message
        });
      }
    }
    
    // Commit the batch operation
    await batch.commit();
    console.log(`${count} servers processed successfully!`);
    
    return { count, failedRows };
  } catch (batchError) {
    console.error("Error committing batch:", batchError);
    throw batchError;
  }
};

export default handleServerFileUpload;