import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Sling as Hamburger } from 'hamburger-react';
import { useFirestore } from 'react-redux-firebase';
import { 
  Typography, 
  MenuItem, 
  Select, 
  FormControl, 
  InputLabel, 
  Paper, 
  IconButton, 
  CircularProgress, 
  Box 
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import SignOut from '../auth/SignOut';
import moment from 'moment';

const HamburgerMenu = ({
  auth,
  profile,
  setSelectedClient,
  setSelectedProjectId,
  setSelectedStats,
  configuration,
  selectedClient,
  selectedProjectId,
  clientNames,
  isLoading,
}) => {
  const firestore = useFirestore();
  const [isOpen, setOpen] = useState(true);
  const [groupedProjects, setGroupedProjects] = useState({});
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [activeStatsIcon, setActiveStatsIcon] = useState(null);
  const [menuLoading, setMenuLoading] = useState(false);

  const monthOrder = useMemo(() => [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ], []);

  const styles = {
    container: {
      position: 'absolute',
      left: '50px',
      width: '300px',
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      padding: '5px'
    }
  };

  // Group projects by year and month - memoized for better performance
  const groupProjectsByYearAndMonth = useCallback((clientsProjects) => {
    return (clientsProjects || []).reduce((acc, { clientID, projects }) => {
      if (!projects) return acc;

      if (clientID) {
        acc[clientID] = projects.reduce((yearAcc, projectData) => {
          const year = projectData.date.getFullYear();
          const month = projectData.date.toLocaleString('default', { month: 'long' });

          if (!yearAcc[year]) {
            yearAcc[year] = {};
          }
          if (!yearAcc[year][month]) {
            yearAcc[year][month] = [];
          }

          yearAcc[year][month].push(projectData);
          yearAcc[year][month].sort((a, b) => a.date - b.date);

          return yearAcc;
        }, {});
      }

      acc['all'] = acc['all'] || {};
      projects.forEach((projectData) => {
        const year = projectData.date.getFullYear();
        const month = projectData.date.toLocaleString('default', { month: 'long' });

        if (!acc['all'][year]) {
          acc['all'][year] = {};
        }
        if (!acc['all'][year][month]) {
          acc['all'][year][month] = [];
        }

        acc['all'][year][month].push(projectData);
        acc['all'][year][month].sort((a, b) => a.date - b.date);
      });

      return acc;
    }, {});
  }, []);

  // Fetch all projects when the menu opens
  useEffect(() => {
    if (auth.uid && profile && !isLoading) {
      const loadProjects = async () => {
        setMenuLoading(true);
        try {
          if (profile.role === 'admin' || profile.role === 'supervisor') {
            const clientSnapshot = await firestore.collection('clients').get();
            const allClients = clientSnapshot.docs.map(clientDoc => clientDoc.id);
            const allProjects = await Promise.all(
              allClients.map(async (clientID) => {
                const projectsSnapshot = await firestore.collection('clients').doc(clientID).collection('projects').get();
                const projects = projectsSnapshot.docs.map(projectDoc => ({
                  ...projectDoc.data(),
                  clientID,
                  date: projectDoc.data().date.toDate(),
                  id: projectDoc.id
                }));
                return { clientID, projects };
              })
            );
            setGroupedProjects(groupProjectsByYearAndMonth(allProjects));
          } else {
            const userDoc = await firestore.collection('users').doc(auth.uid).get();
            const data = userDoc.data();
            const clientScope = data.clientScope || [];

            if (clientScope.length === 0) {
              console.error('No clients available in clientScope');
              return;
            }

            const clientPromises = clientScope.map(async (clientID) => {
              const projectsSnapshot = await firestore.collection('clients').doc(clientID).collection('projects').get();
              const projects = projectsSnapshot.docs.map(projectDoc => ({
                ...projectDoc.data(),
                clientID,
                date: projectDoc.data().date.toDate(),
                id: projectDoc.id
              }));
              return { clientID, projects };
            });

            const clientsProjects = await Promise.all(clientPromises);
            setGroupedProjects(groupProjectsByYearAndMonth(clientsProjects));
          }
        } catch (error) {
          console.error('Error fetching projects:', error);
        } finally {
          setMenuLoading(false);
        }
      };

      loadProjects();
    }
  }, [isOpen, firestore, auth.uid, profile, isLoading, groupProjectsByYearAndMonth]);

  // Real-time updates for selected client
  useEffect(() => {
    if (!selectedClient || isLoading) return;

    const fetchClientProjects = async (clientIDs) => {
      setMenuLoading(true);
      try {
        const projectPromises = clientIDs.map(async (clientID) => {
          const clientRef = firestore.collection('clients').doc(clientID);
          const clientDoc = await clientRef.get();

          if (!clientDoc.exists) {
            console.error(`Client with ID ${clientID} does not exist`);
            return null;
          }

          const projectsSnapshot = await clientRef.collection('projects').get();
          return {
            clientID,
            projects: projectsSnapshot.docs.map((projectDoc) => ({
              ...projectDoc.data(),
              id: projectDoc.id,
              clientID,
              date: projectDoc.data().date.toDate(),
            })),
          };
        });

        const allProjects = (await Promise.all(projectPromises)).filter(Boolean);
        setGroupedProjects(groupProjectsByYearAndMonth(allProjects));
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setMenuLoading(false);
      }
    };

    let unsubscribe;

    if (selectedClient === 'all') {
      const isAdminOrSupervisor = profile?.role === 'admin' || profile?.role === 'supervisor';

      if (isAdminOrSupervisor) {
        unsubscribe = firestore.collection('clients').onSnapshot(async (snapshot) => {
          const clientIDs = snapshot.docs.map((doc) => doc.id);
          await fetchClientProjects(clientIDs);
        });
      } else {
        unsubscribe = firestore.collection('users').doc(auth.uid).onSnapshot(async (userDoc) => {
          const clientScope = userDoc.data()?.clientScope || [];
          if (clientScope.length === 0) {
            console.error('No clients available in clientScope');
            return;
          }
          await fetchClientProjects(clientScope);
        });
      }
    } else {
      unsubscribe = firestore
        .collection('clients')
        .doc(selectedClient)
        .collection('projects')
        .onSnapshot(
          (snapshot) => {
            setMenuLoading(true);
            const projects = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
              clientID: selectedClient,
              date: doc.data().date.toDate(),
            }));
            setGroupedProjects(groupProjectsByYearAndMonth([{ clientID: selectedClient, projects }]));
            setMenuLoading(false);
          },
          (error) => {
            console.error('Error fetching projects in real-time:', error);
            setMenuLoading(false);
          }
        );
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [firestore, selectedClient, auth.uid, profile, isLoading, groupProjectsByYearAndMonth]);
  
  const handleClientClick = useCallback((clientID) => {
    setSelectedClient(clientID);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedProjectId(null);
    setActiveStatsIcon(null);
  }, [setSelectedClient, setSelectedProjectId, setSelectedStats]);

  const handleYearClick = useCallback((year) => {
    setSelectedYear(selectedYear === year ? null : year);
    setSelectedMonth(null);
  }, [selectedYear]);

  const handleMonthClick = useCallback((year, month) => {
    setSelectedYear(year);
    setSelectedMonth(selectedMonth === month ? null : month);
  }, [selectedMonth]);

  const handleProjectClick = useCallback((projectId, clientID) => {
    setActiveStatsIcon(null);
    if (selectedProjectId?.id === projectId) {
      setSelectedProjectId(null);
    } else {
      // Pass an object containing both projectId and clientID to handle "All Companies" view properly
      setSelectedProjectId({ id: projectId, clientID });
    }
  }, [selectedProjectId, setSelectedProjectId]);

  const handleYearStatsClick = useCallback((year, e) => {
    e.stopPropagation();
    const isActive = activeStatsIcon === `year-${year}`;
    setActiveStatsIcon(isActive ? null : `year-${year}`);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedProjectId(null);
    setSelectedStats(isActive ? null : { client: selectedClient, year, month: null });
  }, [activeStatsIcon, selectedClient, setSelectedProjectId, setSelectedStats]);

  const handleMonthStatsClick = useCallback((year, month, e) => {
    e.stopPropagation();
    const isActive = activeStatsIcon === `month-${year}-${month}`;
    setActiveStatsIcon(isActive ? null : `month-${year}-${month}`);
    setSelectedMonth(null);
    setSelectedProjectId(null);
    setSelectedStats(isActive ? null : { client: selectedClient, year, month });
  }, [activeStatsIcon, selectedClient, setSelectedProjectId, setSelectedStats]);

  const formatDayWithSuffix = useCallback((day) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <Hamburger toggled={isOpen} toggle={setOpen} />
      {isOpen && (
        <div style={styles.container}>
          {Object.keys(clientNames).length > 0 && (
            <FormControl fullWidth>
              <InputLabel>Select Company</InputLabel>
              <Select
                value={selectedClient || ''}
                onChange={(e) => handleClientClick(e.target.value)}
                label="Select Company"
              >
                {Object.keys(clientNames).length > 1 && (profile.role === 'admin' || profile.role === 'supervisor') && (
                  <MenuItem 
                    value="all"
                    onClick={() => handleClientClick('all')}
                  >
                    All Companies
                  </MenuItem>
                )}
                {Object.keys(clientNames || {}).map((clientID) => (
                  <MenuItem 
                    key={clientID} 
                    value={clientID}
                    onClick={() => handleClientClick(clientID)}
                  >
                    {clientNames[clientID]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {menuLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress size={24} />
            </Box>
          )}

          {selectedClient && groupedProjects[selectedClient] && !menuLoading && (
            <ul style={{ padding: '0', marginBottom: '20px', listStyleType: 'none', textAlign: 'center' }}>
              {Object.entries(groupedProjects[selectedClient])
                .sort(([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA))
                .map(([year, months]) => (
                  <li key={year} style={{ marginBottom: '10px', position: 'relative', marginLeft: '0', marginRight: '0' }}>
                    <Paper variant="outlined" sx={{ padding: 1, border: '1px solid #ccc', borderRadius: '8px', width: '90%', margin: '0 auto' }}>
                      <Paper
                        elevation={3}
                        sx={{
                          cursor: 'pointer',
                          textAlign: 'center',
                          margin: '0 auto',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '8px 16px',
                          position: 'relative',
                        }}
                        onClick={() => handleYearClick(year)}
                      >
                        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                          {year}
                        </Typography>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: activeStatsIcon === `year-${year}` ? '#1976d2' : 'black',
                          }}
                          onClick={(e) => handleYearStatsClick(year, e)}
                        >
                          <BarChartIcon />
                        </IconButton>
                      </Paper>
                      {selectedYear === year && (
                        <ul style={{ padding: '0', marginTop: '10px', listStyleType: 'none', textAlign: 'center' }}>
                          {Object.entries(months)
                            .sort((a, b) => monthOrder.indexOf(a[0]) - monthOrder.indexOf(b[0]))
                            .map(([month, projects]) => (
                              <li key={month} style={{ marginBottom: '10px', marginLeft: '0', marginRight: '0' }}>
                                <Paper
                                  elevation={3}
                                  sx={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    margin: '0 auto',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '8px 16px',
                                    position: 'relative',
                                  }}
                                  onClick={() => handleMonthClick(year, month)}
                                >
                                  <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                                    {month}
                                  </Typography>
                                  <IconButton
                                    sx={{
                                      position: 'absolute',
                                      right: 8,
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                      color: activeStatsIcon === `month-${year}-${month}` ? '#1976d2' : 'black',
                                    }}
                                    onClick={(e) => handleMonthStatsClick(year, month, e)}
                                  >
                                    <BarChartIcon />
                                  </IconButton>
                                </Paper>
                                {selectedMonth === month && (
                                  <ul style={{ padding: '0', marginTop: '10px', listStyleType: 'none', textAlign: 'center' }}>
                                    {projects
                                      .sort((a, b) => {
                                        const dateA = a.date instanceof Date ? a.date : a.date.toDate();
                                        const dateB = b.date instanceof Date ? b.date : b.date.toDate();
                                        return dateA - dateB;
                                      })
                                      .map((projectData, index) => {
                                        const date = projectData.date instanceof Date ? projectData.date : projectData.date.toDate();
                                        const dayWithSuffix = formatDayWithSuffix(date.getDate());
                                        return (
                                          <li key={projectData.id} style={{ marginBottom: '10px', marginLeft: '0', marginRight: '0' }}>
                                            <Paper
                                              elevation={3}
                                              sx={{
                                                color: selectedProjectId?.id === projectData.id ? 'white' : 'black',
                                                backgroundColor: selectedProjectId?.id === projectData.id ? '#1976d2' : '#FFF',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                margin: '0 auto',
                                                width: '100%',
                                              }}
                                              onClick={() => handleProjectClick(projectData.id, projectData.clientID)}
                                            >
                                              <Typography variant="body1">
                                                {`${dayWithSuffix}`}
                                              </Typography>
                                            </Paper>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                )}
                              </li>
                            ))}
                        </ul>
                      )}
                    </Paper>
                  </li>
                ))}
            </ul>
          )}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <SignOut auth={auth} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
