import React, { useState, useMemo } from 'react';
import { Typography, Container, Box, Button, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createProject } from '../../store/actions/projectActions';
import ProjectDialog from '../project/Modals/ProjectDialog';

/**
 * Component to show a notice for selecting or creating a project
 */
const SelectOrCreateProjectNotice = ({ selectedClient, auth, profile, clientNames = {} }) => {
  const [open, setOpen] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [projectDate, setProjectDate] = useState('');
  const [projectStartTime, setProjectStartTime] = useState('');
  const [projectType, setProjectType] = useState('workstations');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  // Get client name from clientNames prop
  const clientName = useMemo(() => 
    clientNames[selectedClient] || 'Unknown Client', 
    [selectedClient, clientNames]
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const projectDetails = {
      client: selectedClient,
      name: projectName,
      date: projectDate,
      startTime: projectStartTime,
      projectType: projectType,
    };
    
    try {
      await dispatch(createProject(projectDetails));
    } catch (error) {
      console.error('Error creating project:', error);
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: 50 }}>
      <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 2, p: 3 }}>
        <Typography variant="h5" component="div" gutterBottom>
          Select or Create a Project
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Pick a project for {clientName} from the menu or create a new one below.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleOpen}
          sx={{ mt: 2 }}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Create Project'}
        </Button>
      </Box>

      <ProjectDialog
        open={open}
        onClose={handleClose}
        projectName={projectName}
        setProjectName={setProjectName}
        projectDate={projectDate}
        setProjectDate={setProjectDate}
        projectStartTime={projectStartTime}
        setProjectStartTime={setProjectStartTime}
        projectType={projectType}
        setProjectType={setProjectType}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </Container>
  );
};

export default SelectOrCreateProjectNotice;