import React from 'react';
import { Menu, MenuItem, FormControlLabel, Checkbox } from '@mui/material';

const StatusFilterMenu = ({ 
  anchorEl, 
  handleClose, 
  statusFilter, 
  handleStatusFilterChange 
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={statusFilter.disconnectOnly}
              onChange={handleStatusFilterChange}
              name="disconnectOnly"
            />
          }
          label="Disconnect Only"
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={statusFilter.qa}
              onChange={handleStatusFilterChange}
              name="qa"
            />
          }
          label="QA'd"
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={statusFilter.issue}
              onChange={handleStatusFilterChange}
              name="issue"
            />
          }
          label="Issue"
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={statusFilter.reconnected}
              onChange={handleStatusFilterChange}
              name="reconnected"
            />
          }
          label="Reconnected"
        />
      </MenuItem>
    </Menu>
  );
};

export default StatusFilterMenu;