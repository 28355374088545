import React from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Box, 
  CircularProgress,
  Typography
} from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ConnectionRow from './ConnectionRow';
import TableHeader from './TableHeader';

const ConnectionsTable = ({ 
  server,
  connections,
  loading,
  sortConfig, 
  sortedAndFilteredConnections,
  filters,
  showHeaderOptions,
  handleSortChange,
  toggleHeaderOptions,
  hideServer1Column,
  hidePort1Column,
  hideCableTypeColumn,
  hideServer2Column,
  hidePort2Column,
  hideDestinationColumn,
  hideIsConnectedColumn,
  hideNotesColumn,
  setHideServer1Column,
  setHidePort1Column,
  setHideCableTypeColumn,
  setHideServer2Column,
  setHidePort2Column,
  setHideDestinationColumn,
  setHideIsConnectedColumn,
  setHideNotesColumn,
  editingCell,
  zoomLevel,
  setZoomLevel,
  handleEditClick,
  handleSaveEdit,
  handleCancelEdit,
  editValue,
  updateLoading,
  handleEditChange,
  handleEditKeyPress,
  handleToggleConnected,
  updatingConnections,
  fetchConnections,
  onFilterClick,
  onSearchClick
}) => {
  
  // Handle zoom in action (increase by 10% each time, max 200%)
  const handleZoomIn = () => {
    setZoomLevel(prev => Math.min(prev + 10, 200));
  };
  
  // Handle zoom out action (decrease by 10% each time, min 30%)
  const handleZoomOut = () => {
    setZoomLevel(prev => Math.max(prev - 10, 30));
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' }, fontWeight: 'bold' }}>
          Connections
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ mr: 1, fontSize: '0.8rem' }}>
            {zoomLevel}%
          </Typography>
          <Box 
            onClick={handleZoomOut}
            disabled={zoomLevel <= 30}
            title="Zoom out"
            sx={{ 
              mr: 0.5, 
              cursor: zoomLevel <= 30 ? 'default' : 'pointer',
              color: zoomLevel <= 30 ? 'text.disabled' : 'primary.main'
            }}
          >
            <ZoomOutIcon fontSize="small" />
          </Box>
          <Box 
            onClick={handleZoomIn}
            disabled={zoomLevel >= 200}
            title="Zoom in"
            sx={{ 
              cursor: zoomLevel >= 200 ? 'default' : 'pointer',
              color: zoomLevel >= 200 ? 'text.disabled' : 'primary.main'
            }}
          >
            <ZoomInIcon fontSize="small" />
          </Box>
        </Box>
      </Box>
      
      {loading ? (
        <Box display="flex" justifyContent="center" padding={3}>
          <CircularProgress />
        </Box>
      ) : connections.length > 0 ? (
        <Box sx={{ overflowX: 'auto', width: '100%' }}>
          <Box sx={{ 
            transform: `scale(${zoomLevel / 100})`, 
            transformOrigin: 'top left',
            width: `${10000 / zoomLevel}%` // This ensures the table adjusts its width based on zoom level
          }}>
            <Table size="small" sx={{ 
              // Apply consistent font styling to the entire table
              '& .MuiTableCell-root': {
                fontFamily: 'inherit',
                fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                padding: { xs: '4px 6px', sm: '6px 8px', md: '8px 16px' },
                whiteSpace: 'nowrap'
              }
            }}>
              <TableHead>
                <TableRow>
                  <TableHeader 
                    columnKey="server1"
                    label="Server 1"
                    hidden={hideServer1Column}
                    setHidden={setHideServer1Column}
                    showOptions={showHeaderOptions.server1}
                    toggleOptions={toggleHeaderOptions}
                    sortConfig={sortConfig}
                    handleSortChange={handleSortChange}
                    filters={filters}
                    onFilterClick={onFilterClick}
                    onSearchClick={onSearchClick}
                  />
                  <TableHeader 
                    columnKey="port1"
                    label="Port 1"
                    hidden={hidePort1Column}
                    setHidden={setHidePort1Column}
                    showOptions={showHeaderOptions.port1}
                    toggleOptions={toggleHeaderOptions}
                    sortConfig={sortConfig}
                    handleSortChange={handleSortChange}
                    filters={filters}
                    onFilterClick={onFilterClick}
                    onSearchClick={onSearchClick}
                  />
                  <TableHeader 
                    columnKey="cableType"
                    label="Cable Type"
                    hidden={hideCableTypeColumn}
                    setHidden={setHideCableTypeColumn}
                    showOptions={showHeaderOptions.cableType}
                    toggleOptions={toggleHeaderOptions}
                    sortConfig={sortConfig}
                    handleSortChange={handleSortChange}
                    filters={filters}
                    onFilterClick={onFilterClick}
                    onSearchClick={onSearchClick}
                  />
                  <TableHeader 
                    columnKey="server2"
                    label="Server 2"
                    hidden={hideServer2Column}
                    setHidden={setHideServer2Column}
                    showOptions={showHeaderOptions.server2}
                    toggleOptions={toggleHeaderOptions}
                    sortConfig={sortConfig}
                    handleSortChange={handleSortChange}
                    filters={filters}
                    onFilterClick={onFilterClick}
                    onSearchClick={onSearchClick}
                  />
                  <TableHeader 
                    columnKey="port2"
                    label="Port 2"
                    hidden={hidePort2Column}
                    setHidden={setHidePort2Column}
                    showOptions={showHeaderOptions.port2}
                    toggleOptions={toggleHeaderOptions}
                    sortConfig={sortConfig}
                    handleSortChange={handleSortChange}
                    filters={filters}
                    onFilterClick={onFilterClick}
                    onSearchClick={onSearchClick}
                  />
                  <TableHeader 
                    columnKey="destination"
                    label="Destination"
                    hidden={hideDestinationColumn}
                    setHidden={setHideDestinationColumn}
                    showOptions={showHeaderOptions.destination}
                    toggleOptions={toggleHeaderOptions}
                    sortConfig={sortConfig}
                    handleSortChange={handleSortChange}
                    filters={filters}
                    onFilterClick={onFilterClick}
                    onSearchClick={onSearchClick}
                  />
                  <TableHeader 
                    columnKey="isConnected"
                    label="Is Connected"
                    hidden={hideIsConnectedColumn}
                    setHidden={setHideIsConnectedColumn}
                    showOptions={showHeaderOptions.isConnected}
                    toggleOptions={toggleHeaderOptions}
                    sortConfig={sortConfig}
                    filters={filters}
                    onFilterClick={onFilterClick}
                    onSearchClick={onSearchClick}
                    handleSortChange={handleSortChange}
                  />
                  <TableHeader 
                    columnKey="notes"
                    label="Notes"
                    hidden={hideNotesColumn}
                    setHidden={setHideNotesColumn}
                    showOptions={showHeaderOptions.notes}
                    toggleOptions={toggleHeaderOptions}
                    sortConfig={sortConfig}
                    handleSortChange={handleSortChange}
                    filters={filters}
                    onFilterClick={onFilterClick}
                    onSearchClick={onSearchClick}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAndFilteredConnections.map((connection) => (
                  <ConnectionRow 
                    key={connection.id}
                    connection={connection}
                    server={server}
                    hideServer1Column={hideServer1Column}
                    hidePort1Column={hidePort1Column}
                    hideCableTypeColumn={hideCableTypeColumn}
                    hideServer2Column={hideServer2Column}
                    hidePort2Column={hidePort2Column}
                    hideDestinationColumn={hideDestinationColumn}
                    hideIsConnectedColumn={hideIsConnectedColumn}
                    hideNotesColumn={hideNotesColumn}
                    editingCell={editingCell}
                    editValue={editValue}
                    updateLoading={updateLoading}
                    handleEditClick={handleEditClick}
                    handleSaveEdit={handleSaveEdit}
                    handleCancelEdit={handleCancelEdit}
                    handleEditChange={handleEditChange}
                    handleEditKeyPress={handleEditKeyPress}
                    handleToggleConnected={handleToggleConnected}
                    updatingConnections={updatingConnections}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center', padding: 2 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
            No connections found for this device
          </Typography>
          <Box 
            onClick={fetchConnections}
            sx={{
              display: 'inline-block',
              border: '1px solid',
              borderColor: 'primary.main',
              borderRadius: 1,
              color: 'primary.main',
              px: 2,
              py: 0.5,
              fontSize: '0.875rem',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'action.hover'
              }
            }}
          >
            Refresh Connections
          </Box>
        </Box>
      )}
    </>
  );
};

export default ConnectionsTable;