/**
 * Utility functions for connection sorting and filtering
 */

/**
 * Creates and dispatches a custom event to notify components about cart updates
 * @param {string} action - The action performed ('create', 'update', 'delete')
 * @param {string} cartId - The ID of the cart that was affected
 * @param {string} cartName - The name of the cart
 */
export const createCartUpdateEvent = (action, cartId, cartName) => {
  const event = new CustomEvent('cartUpdate', {
    detail: {
      action,
      cartId,
      cartName,
      timestamp: Date.now()
    }
  });
  
  console.log(`Dispatching cartUpdate event: ${action} cart ${cartId} (${cartName})`);
  window.dispatchEvent(event);
};

/**
 * Compares two connection values for sorting
 */
export const compareValues = (a, b, key, server) => {
  let valueA, valueB;
  
  // Map the display keys to actual connection properties
  switch(key) {
    case 'server1':
      valueA = server.hostname || server.deviceName || "";
      valueB = server.hostname || server.deviceName || "";
      break;
    case 'port1':
      valueA = a.localPort || "";
      valueB = b.localPort || "";
      break;
    case 'server2':
      valueA = a.remoteName || "";
      valueB = b.remoteName || "";
      break;
    case 'port2':
      valueA = a.remotePort || "";
      valueB = b.remotePort || "";
      break;
    case 'destination':
      // Create concatenated destination strings for sorting (Row-Rack-RU)
      const getDestinationString = (connection) => {
        // Convert to strings and ensure values exist before using padStart
        const row = connection.destinationRow ? connection.destinationRow.toString().padStart(2, '0') : '00';
        const rack = connection.destinationRack ? connection.destinationRack.toString().padStart(2, '0') : '00';
        const ru = connection.destinationRackUnit ? connection.destinationRackUnit.toString().padStart(2, '0') : '00';
        return `${row}-${rack}-${ru}`.toLowerCase();
      };
      valueA = getDestinationString(a);
      valueB = getDestinationString(b);
      return valueA.localeCompare(valueB);
    case 'isConnected':
      // Special handling for boolean sorting - true values first
      if (a.isConnected === true && b.isConnected !== true) return -1;
      if (a.isConnected !== true && b.isConnected === true) return 1;
      if (a.isConnected === false && b.isConnected === null) return -1;
      if (a.isConnected === null && b.isConnected === false) return 1;
      return 0;
    case 'notes':
      valueA = a.notes || "";
      valueB = b.notes || "";
      break;
    default:
      valueA = a[key] || "";
      valueB = b[key] || "";
  }

  // Convert to strings for comparison
  const valueAStr = typeof valueA === 'string' ? valueA : (valueA?.toString() || "");
  const valueBStr = typeof valueB === 'string' ? valueB : (valueB?.toString() || "");

  return valueAStr.localeCompare(valueBStr);
};

/**
 * Sort connections by the specified key and direction
 */
export const sortConnections = (connections, sortConfig, server) => {
  return [...connections].sort((a, b) => {
    const comparison = compareValues(a, b, sortConfig.key, server);
    return sortConfig.direction === 'asc' ? comparison : -comparison;
  });
};

/**
 * Get the display value for a given connection and column
 */
export const getConnectionValue = (connection, column, server) => {
  switch(column) {
    case 'server1':
      return server.hostname || server.deviceName || "";
    case 'port1':
      return connection.localPort || "";
    case 'cableType':
      return connection.cableType || "";
    case 'server2':
      return connection.remoteName || "";
    case 'port2':
      return connection.remotePort || "";
    case 'destination':
      // Format destination similar to Servers.js display
      return [
        connection.destinationRow && `Row ${connection.destinationRow}`,
        connection.destinationRack && `Rack ${connection.destinationRack}`,
        connection.destinationRackUnit && `RU ${connection.destinationRackUnit}`
      ].filter(Boolean).join(' - ') || "";
    case 'isConnected':
      return connection.isConnected === true ? "Yes" : 
             connection.isConnected === false ? "No" : 
             "Unknown";
    case 'notes':
      return connection.notes || "";
    default:
      return "";
  }
};

/**
 * Get all unique values for a column to use in filters
 */
export const getUniqueColumnValues = (connections, column, server) => {
  const values = connections.map(connection => getConnectionValue(connection, column, server));
  // Filter out empty values, create a unique set, convert back to array, and sort alphabetically
  return [...new Set(values)]
    .filter(value => value !== "")
    .sort((a, b) => a.localeCompare(b));
};

/**
 * Filter connections based on active filters and search query
 */
export const filterConnections = (connections, filters, searchQuery, searchColumn, server) => {
  return connections.filter((connection) => {
    // Check all active column filters
    for (const column of ['server1', 'port1', 'cableType', 'server2', 'port2', 'destination', 'isConnected']) {
      const columnFilters = filters[column];
      if (columnFilters.length > 0) {
        const columnValue = getConnectionValue(connection, column, server);
        
        // Case-insensitive comparison for text fields
        const matchFound = columnFilters.some(filter => {
          if (typeof columnValue === 'string' && typeof filter === 'string') {
            return columnValue.toLowerCase() === filter.toLowerCase();
          }
          return columnValue === filter;
        });
        
        if (!matchFound) {
          return false;
        }
      }
    }
    
    // Search filter
    let searchMatch = true;
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      switch(searchColumn) {  // Use searchColumn instead of sortConfig.key
        case 'server1':
          searchMatch = (server.hostname || server.deviceName || "").toLowerCase().includes(lowerQuery);
          break;
        case 'port1':
          searchMatch = (connection.localPort || "").toLowerCase().includes(lowerQuery);
          break;
        case 'cableType':
          searchMatch = (connection.cableType || "").toLowerCase().includes(lowerQuery);
          break;
        case 'server2':
          searchMatch = (connection.remoteName || "").toLowerCase().includes(lowerQuery);
          break;
        case 'port2':
          searchMatch = (connection.remotePort || "").toLowerCase().includes(lowerQuery);
          break;
        case 'destination':
          // Search in the formatted destination string
          const destinationText = getConnectionValue(connection, 'destination', server).toLowerCase();
          searchMatch = destinationText.includes(lowerQuery);
          break;
        case 'cart':
          // Return true for now - we're implementing cart search at server level in filterUtils.js
          // This is just to avoid errors if cart search somehow gets triggered at connection level
          searchMatch = true;
          break;
        case 'isConnected':
          const statusText = connection.isConnected === true ? "yes" : 
                             connection.isConnected === false ? "no" : "unknown";
          searchMatch = statusText.includes(lowerQuery);
          break;
        case 'notes':
          searchMatch = (connection.notes || "").toLowerCase().includes(lowerQuery);
          break;
        default:
          searchMatch = true;
      }
    }
    
    return searchMatch;
  });
};