import React from 'react';
import { Box, Typography, IconButton, TableCell } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const TableHeader = ({
  columnKey,
  label,
  hidden,
  setHidden,
  showOptions,
  toggleOptions,
  sortConfig,
  handleSortChange,
  filters,
  onFilterClick,
  onSearchClick
}) => {
  if (hidden) {
    return (
      <TableCell sx={{ 
        width: '40px',
        padding: '4px',
        display: 'table-cell'
      }}>
        <Box 
          onClick={() => setHidden(false)}
          title={`Show ${label} column`}
          sx={{ 
            cursor: 'pointer',
            color: theme => theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            fontWeight: 'bold',
            padding: '4px',
            userSelect: 'none'
          }}
        >
          <Typography component="span" sx={{ fontSize: '1.2rem', lineHeight: 1 }}>_</Typography>
        </Box>
      </TableCell>
    );
  }

  const isActiveSort = sortConfig.key === columnKey;
  const canSearch = ['server1', 'port1', 'cableType', 'server2', 'port2', 'destination', 'notes'].includes(columnKey);
  const canFilter = ['server1', 'port1', 'cableType', 'server2', 'port2', 'destination', 'isConnected'].includes(columnKey);
  const isFiltered = filters[columnKey]?.length > 0;

  const renderColumnHeader = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box 
          component="span" 
          onClick={() => toggleOptions(columnKey)}
          sx={{ 
            cursor: 'pointer', 
            display: 'flex', 
            alignItems: 'center',
            color: isFiltered ? 'primary.main' : 'inherit',
            fontWeight: isFiltered ? 'bold' : 'normal',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            typography: 'body1',
            position: 'relative',
            pr: showOptions ? 1 : 0,
            '&:hover': {
              bgcolor: 'rgba(0,0,0,0.04)',
            }
          }}
        >
          {label}
          {filters[columnKey]?.length > 0 && (
            <Box component="span" sx={{ 
              ml: 0.5, 
              fontSize: '0.75rem', 
              bgcolor: 'primary.main', 
              color: 'white', 
              borderRadius: '50%', 
              width: 20, 
              height: 20, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center' 
            }}>
              {filters[columnKey].length}
            </Box>
          )}
        </Box>
        
        {showOptions && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {canSearch && (
              <IconButton 
                size="small" 
                onClick={(e) => { e.stopPropagation(); onSearchClick?.(columnKey); }}
                sx={{ padding: '4px' }}
              >
                <SearchIcon fontSize="small" />
              </IconButton>
            )}
            
            {canFilter && (
              <IconButton 
                size="small" 
                onClick={(e) => { e.stopPropagation(); onFilterClick?.(e, columnKey); }}
                color={isFiltered ? "primary" : "default"}
                sx={{ padding: '4px' }}
              >
                <FilterListIcon fontSize="small" />
              </IconButton>
            )}
            
            {/* Sort button explicit with original styling */}
            <Box
              onClick={(e) => { e.stopPropagation(); handleSortChange(columnKey); }}
              sx={{ 
                cursor: 'pointer',
                padding: '4px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {/* Always show the arrow direction, whether active or not */}
              {sortConfig.key === columnKey 
                ? (sortConfig.direction === 'asc' 
                    ? <ArrowUpwardIcon fontSize="small" sx={{ color: 'inherit' }} />
                    : <ArrowDownwardIcon fontSize="small" sx={{ color: 'inherit' }} />)
                : <ArrowDownwardIcon fontSize="small" sx={{ color: 'rgba(0,0,0,0.3)' }} />
              }
            </Box>
            
            <IconButton 
              size="small" 
              onClick={(e) => { 
                e.stopPropagation(); 
                setHidden(true);
              }}
              title={`Hide ${label} column`}
              sx={{ padding: '4px' }}
            >
              <VisibilityOffIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <TableCell sx={{ 
      width: 'auto',
      display: 'table-cell'
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {renderColumnHeader()}
      </Box>
    </TableCell>
  );
};

export default TableHeader;