import React from 'react';
import { Box, InputBase } from '@mui/material';

const SearchBar = ({ 
  searchQuery, 
  handleSearchChange, 
  searchColumn 
}) => {
  // Function to format column name for display
  const formatColumnName = (column) => {
    switch(column) {
      case 'server1': return 'Server 1';
      case 'port1': return 'Port 1';
      case 'cableType': return 'Cable Type';
      case 'server2': return 'Server 2';
      case 'port2': return 'Port 2';
      case 'destination': return 'Destination';
      case 'isConnected': return 'Connection Status';
      case 'notes': return 'Notes';
      default: return column;
    }
  };
  
  return (
    <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
      <InputBase
        placeholder={`Search ${formatColumnName(searchColumn)}...`}
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        autoFocus
        sx={{ 
          border: '1px solid #ccc', 
          borderRadius: 1, 
          padding: '2px 8px' 
        }}
      />
    </Box>
  );
};

export default SearchBar;