import React, { useState, useRef } from 'react';
import { Button, Box, Typography, Modal, FormGroup, FormControlLabel, Checkbox, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

function UploadModal({ 
  uploadModalOpen, 
  handleCloseUploadModal, 
  handleFileUpload, 
  requiredColumns = [], 
  optionalColumns = [],
  uploadType = 'data' // Default value for upload type label 
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  
  // Use effect to reset file input when modal opens/closes
  React.useEffect(() => {
    // Reset file input when modal is closed
    if (!uploadModalOpen && fileInputRef.current) {
      console.log('Reset file input on modal close');
      fileInputRef.current.value = '';
      setSelectedFile(null);
      setIsUploading(false); // Make sure loading state is also reset
    }
  }, [uploadModalOpen]);
  
  // Add a timeout to reset isUploading if a promise never resolves
  React.useEffect(() => {
    let resetTimer;
    if (isUploading) {
      // Add a timeout to reset isUploading after 30 seconds if it's never reset
      resetTimer = setTimeout(() => {
        console.log('Upload timeout reached, resetting isUploading state');
        setIsUploading(false);
      }, 30000); // 30 second timeout
    }
    
    return () => {
      if (resetTimer) {
        clearTimeout(resetTimer);
      }
    };
  }, [isUploading]);
  
  const [optionalColumnsState, setOptionalColumnsState] = useState(
    optionalColumns.reduce((acc, column) => ({ ...acc, [column.name]: false }), {})
  );

  const fileInputRef = useRef(null);
  const containerRef = useRef(null);

  // When isUploading is true, scroll the modal container to the top
  React.useEffect(() => {
    if (isUploading && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [isUploading]);

  // Custom alert dialog state
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success', 'error', or 'download'
  const [downloadUrl, setDownloadUrl] = useState('');
  const [downloadFilename, setDownloadFilename] = useState('');

  const handleCheckboxChange = (event) => {
    setOptionalColumnsState({
      ...optionalColumnsState,
      [event.target.name]: event.target.checked,
    });
  };

  const createAndDownloadTemplate = () => {
    const worksheetData = [requiredColumns.map(column => column.name)];
    const selectedOptionalColumns = Object.keys(optionalColumnsState).filter(column => optionalColumnsState[column]);
    worksheetData[0].push(...selectedOptionalColumns);

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(fileBlob, 'template.xlsx');
  };

  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      console.log('File selected:', file?.name);
      setSelectedFile(file);
    } catch (error) {
      console.error('Error in file selection:', error);
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // Custom alert function that returns a Promise
  const showCustomAlert = (message, type = 'success', downloadOptions = null) => {
    return new Promise((resolve) => {
      setAlertMessage(message);
      setAlertType(type);
      
      if (downloadOptions) {
        setDownloadUrl(downloadOptions.url);
        setDownloadFilename(downloadOptions.filename);
      } else {
        setDownloadUrl('');
        setDownloadFilename('');
      }
      
      setAlertDialogOpen(true);
      
      // The promise will be resolved when the user closes the dialog
      // in the handleAlertClose function
    });
  };
  
  // Handle alert dialog close and resolve promise
  const handleAlertClose = (downloadRequested = false) => {
    console.log('Closing alert dialog, download requested:', downloadRequested);
    setAlertDialogOpen(false);
    
    // If this was a download alert and download was requested, trigger the download
    if (alertType === 'download' && downloadRequested && downloadUrl) {
      console.log('Triggering download for:', downloadFilename);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', downloadFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
        console.log('Download cleanup complete');
      }, 100);
    }
    
    // Reset file input and selected file state
    if (fileInputRef.current) {
      console.log('Resetting file input');
      fileInputRef.current.value = '';
    }
    setSelectedFile(null);
    
    // Close the upload modal after alert is dismissed
    console.log('Closing upload modal');
    handleCloseUploadModal();
  };

  const handleFileUploadClick = () => {
    if (selectedFile) {
      setIsUploading(true);
      
      // Force re-read the file from disk by cloning the file input
      const file = selectedFile;
      console.log('Uploading file:', file.name, 'Size:', file.size, 'Last modified:', new Date(file.lastModified).toISOString());
      
      // Create a new FileReader to read the latest file content
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target.result;
        console.log('File data loaded, size:', fileData.length);
        
        try {
          const workbook = XLSX.read(fileData, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          console.log('Sheet name:', sheetName);
          const worksheet = workbook.Sheets[sheetName];
          
          // Convert sheet to JSON with headers
          // This will create objects with named properties based on the first row
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          console.log('Parsed JSON data rows:', jsonData.length, 'First row keys:', jsonData.length > 0 ? Object.keys(jsonData[0]) : 'none');
          
          if (jsonData.length > 0) {
            // Pass the data with named properties to the upload handler
            // Keep the modal open and loading indicator visible until upload completes
            console.log('Starting file upload with', jsonData.length, 'rows');
            try {
              const uploadPromise = handleFileUpload(jsonData);
              console.log('Upload promise created:', uploadPromise);
              
              uploadPromise
                .then(result => {
                  console.log('Upload complete, got result:', result);
                  // The result is expected to be passed from the upload handler
                  // with information about success, errors, etc.
                  setIsUploading(false);
                  
                  // Check if there are errors that need a download link
                  if (result && result.failedRows && result.failedRows.length > 0) {
                    console.log('Failed rows found:', result.failedRows.length);
                    
                    // Deduplicate failed rows by their content to prevent duplicate errors
                    const deduplicatedRows = [];
                    const seenErrors = new Set();
                    
                    result.failedRows.forEach(row => {
                      // Create a unique key based on row content and error
                      const rowKey = JSON.stringify({
                        ...row,
                        // Only use the error message for deduplication, not the whole object
                        errorMsg: row.error
                      });
                      
                      if (!seenErrors.has(rowKey)) {
                        seenErrors.add(rowKey);
                        deduplicatedRows.push(row);
                      }
                    });
                    
                    console.log(`Deduplicated errors from ${result.failedRows.length} to ${deduplicatedRows.length}`);
                    
                    // Create a downloadable error report
                    const headersWithError = [...result.headers, 'error'];
                    const csv = Papa.unparse({
                      fields: headersWithError,
                      data: deduplicatedRows
                    });
                    
                    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    const url = URL.createObjectURL(blob);
                    
                    // Show alert with download option
                    showCustomAlert(
                      `Processed ${result.count} ${uploadType} (created new or updated existing) with ${deduplicatedRows.length} failed rows.`,
                      'download',
                      { url, filename: `${uploadType.toLowerCase()}_errors.csv` }
                    );
                  } else {
                    console.log('No failed rows, showing success message');
                    // Show success alert
                    showCustomAlert(`Successfully processed ${result?.count || 'all'} ${uploadType} (created new or updated existing)`);
                  }
                })
                .catch(error => {
                  console.error('Upload promise rejected with error:', error);
                  setIsUploading(false);
                  
                  // Special handling for user cancellation due to duplicates
                  if (error.message && error.message.includes('Upload canceled by user due to duplicates')) {
                    console.log('Upload was canceled by user due to duplicates - silent handling');
                    // Just close the modal without showing an error alert
                    // Reset file input
                    if (fileInputRef.current) {
                      fileInputRef.current.value = '';
                    }
                    setSelectedFile(null);
                    handleCloseUploadModal();
                  } else {
                    // Show error alert for other errors
                    showCustomAlert(`Error processing upload: ${error.message}`, 'error');
                  }
                });
            } catch (error) {
              console.error('Exception thrown during upload initialization:', error);
              setIsUploading(false);
              showCustomAlert(`Error starting upload: ${error.message}`, 'error');
            }
          } else {
            setIsUploading(false);
            showCustomAlert('No data found in the file.', 'error');
          }
        } catch (error) {
          console.error('Error parsing Excel file:', error);
          setIsUploading(false);
          showCustomAlert(`Error parsing file: ${error.message}. Make sure it's a valid Excel file.`, 'error');
        }
      };
      
      reader.onerror = (error) => {
        console.error('FileReader error:', error);
        setIsUploading(false);
        showCustomAlert('Error reading file. Please try selecting it again.', 'error');
      };
      
      // Read the file as binary string
      console.log('Starting to read file as binary string');
      reader.readAsBinaryString(file);
      
    } else {
      showCustomAlert('Please select a file to upload.', 'error');
    }
  };

  return (
    <>
      <Modal 
        open={uploadModalOpen}
        onClose={() => {
          if (!isUploading) {
            // Reset file input before closing
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
            setSelectedFile(null);
            handleCloseUploadModal();
          }
        }}
        aria-labelledby="upload-modal-title"
        aria-describedby="upload-modal-description"
        keepMounted
      >
        <Box 
          ref={containerRef}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 400 },
            maxHeight: '80%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            position: 'relative'
          }}
        >
          <Typography id="upload-modal-title" variant="h6" component="h2">
            Select fields to customize your template. Download, fill it out, and upload.
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Required Fields:</Typography>
            <FormGroup>
              {requiredColumns.map((column) => (
                <FormControlLabel
                  key={column.name}
                  control={<Checkbox checked disabled />}
                  label={column.label}
                />
              ))}
            </FormGroup>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1">Optional Fields:</Typography>
            <FormGroup>
              {optionalColumns.map((column) => (
                <FormControlLabel
                  key={column.name}
                  control={
                    <Checkbox
                      name={column.name}
                      checked={optionalColumnsState[column.name]}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={column.label}
                />
              ))}
            </FormGroup>
          </Box>

          <Button onClick={createAndDownloadTemplate} variant="contained" fullWidth sx={{ mt: 3 }}>
            Download Template
          </Button>

          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ marginTop: '20px', marginBottom: '20px', display: 'block' }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button 
              onClick={() => {
                // Reset file input before closing
                if (fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
                setSelectedFile(null);
                handleCloseUploadModal();
              }} 
              variant="outlined" 
              disabled={isUploading}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleFileUploadClick} 
              variant="contained" 
              disabled={isUploading || !selectedFile}
              startIcon={isUploading && <CircularProgress size={20} />}
            >
              {isUploading ? 'Processing...' : 'Upload'}
            </Button>
          </Box>
          
          {/* Processing Overlay */}
          {isUploading && (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              backdropFilter: 'blur(5px)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
              borderRadius: '4px'
            }}>
              <CircularProgress size={60} sx={{ color: 'white' }} />
              <Typography variant="h6" sx={{ mt: 2, color: 'white', fontWeight: 'bold' }}>
                Processing {uploadType}...
              </Typography>
              <Typography variant="body1" sx={{ mt: 1, textAlign: 'center', maxWidth: '80%', color: 'white' }}>
                This may take a few moments while we check for duplicates and update existing records.
              </Typography>
            </Box>
          )}
        </Box>
      </Modal>
      
      {/* Custom Alert Dialog */}
      <Dialog
        open={alertDialogOpen}
        onClose={() => handleAlertClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alertType === 'error' ? 'Error' : 'Success'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {alertMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          {alertType === 'download' && (
            <Button onClick={() => handleAlertClose(true)} color="primary">
              Download Error Report
            </Button>
          )}
          <Button onClick={() => handleAlertClose()} color="primary" autoFocus>
            {alertType === 'download' ? 'Skip Download' : 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadModal;
