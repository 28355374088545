// hooks/useStatsData.js
import { useState, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase';

const useStatsData = (selectedStats) => {
  const firestore = useFirestore();
  const [workstations, setWorkstations] = useState([]);
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!selectedStats) {
      setWorkstations([]);
      setServers([]);
      setLoading(false);
      setError(null);
      return;
    }

    const { client, year, month } = selectedStats;
    let isMounted = true;

    const fetchStats = async () => {
      setLoading(true);
      setError(null);

      try {
        // Handle "all" clients case
        let projects = [];
        if (client === 'all') {
          const clientsSnapshot = await firestore.collection('clients').get();
          const clientPromises = clientsSnapshot.docs.map(async (clientDoc) => {
            const projectsSnapshot = await firestore
              .collection('clients')
              .doc(clientDoc.id)
              .collection('projects')
              .get();
            return projectsSnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
              clientID: clientDoc.id,
              date: doc.data().date.toDate(),
              refLocation: doc.data().refLocation?.path
            }));
          });
          const allProjects = await Promise.all(clientPromises);
          projects = allProjects.flat();
        } else {
          const projectsSnapshot = await firestore
            .collection('clients')
            .doc(client)
            .collection('projects')
            .get();
          projects = projectsSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            clientID: client,
            date: doc.data().date.toDate(),
            refLocation: doc.data().refLocation?.path
          }));
        }

        // Filter by year and optionally month
        const filteredProjects = projects.filter(project => {
          const projectYear = project.date.getFullYear();
          const projectMonth = project.date.toLocaleString('default', { month: 'long' });
          return projectYear === parseInt(year) && (!month || projectMonth === month);
        });

        // Fetch workstations and servers
        const itemsFetches = filteredProjects.map(project => {
          const refLocation = project.refLocation;
          if (!refLocation) return null;
          const inferredType = refLocation.includes('serverProjects') ? 'servers' : 'workstations';
          const itemsRef = firestore.doc(refLocation).collection(inferredType);
          return { type: inferredType, promise: itemsRef.get() };
        }).filter(Boolean);

        const snapshots = await Promise.all(itemsFetches.map(({ promise }) => promise));
        const allWorkstations = [];
        const allServers = [];

        snapshots.forEach((snapshot, index) => {
          const { type } = itemsFetches[index];
          const docsData = snapshot.docs.map(doc => doc.data());
          if (type === 'workstations') allWorkstations.push(...docsData);
          else allServers.push(...docsData);
        });

        if (isMounted) {
          setWorkstations(allWorkstations);
          setServers(allServers);
        }
      } catch (err) {
        if (isMounted) setError(err.message || 'Failed to fetch stats');
        console.error('Error fetching stats:', err);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchStats();

    return () => {
      isMounted = false;
    };
  }, [selectedStats, firestore]);

  return { workstations, servers, loading, error };
};

export default useStatsData;