import { useState, useEffect, useRef } from 'react';
import { useFirestore } from 'react-redux-firebase';
import moment from 'moment';

/**
 * Custom hook to fetch and manage selected project data
 * 
 * @param {string|Object} selectedProject - The selected project ID or object with id and clientID
 * @param {string} defaultClientID - The selected client ID (used when projectId is a string)
 * @param {function} getClientName - Function to get client name by ID
 * @param {Object} configuration - Optional configuration object for URL construction
 * @returns {Object} Project data, workstations, servers, and related state
 */
const useSelectedProjectData = (selectedProject, defaultClientID, getClientName, configuration) => {
  const firestore = useFirestore();
  const [projectData, setProjectData] = useState(null);
  const [workstations, setWorkstations] = useState([]);
  const [servers, setServers] = useState([]);
  const [customTitle, setCustomTitle] = useState('');
  const [url, setURL] = useState('');
  const [projectType, setProjectType] = useState('workstations');
  const projectUnsubscribeRef = useRef(null);

  useEffect(() => {
    // Extract projectId and clientID based on selectedProject type
    let projectId, clientID;
    
    if (!selectedProject) {
      // No project selected
      setProjectData(null);
      setWorkstations([]);
      setServers([]);
      setCustomTitle(defaultClientID ? getClientName(defaultClientID) : '');
      return;
    } else if (typeof selectedProject === 'object' && selectedProject.id) {
      // Object format from "All Companies" view
      projectId = selectedProject.id;
      clientID = selectedProject.clientID;
    } else {
      // String format (legacy/direct project ID)
      projectId = selectedProject;
      clientID = defaultClientID;
    }

    if (!projectId || !clientID) {
      // Invalid data
      console.error('Invalid project or client ID');
      return;
    }

    const fetchProject = async () => {
      try {
        const projectRef = firestore
          .collection('clients')
          .doc(clientID)
          .collection('projects')
          .doc(projectId);
        const projectDoc = await projectRef.get();

        if (projectDoc.exists) {
          const fetchedData = projectDoc.data();
          setProjectData({ ...fetchedData, id: projectDoc.id, clientID });

          // Format the title with client name and date
          const date = moment.unix(fetchedData.date.seconds).format('MMMM D, YYYY');
          setCustomTitle(`${getClientName(clientID)} : ${date}`);

          // Extract refLocation and build URL
          const refLocation = fetchedData?.refLocation?.path;
          if (!refLocation) {
            console.error("No 'refLocation' in this project document");
            return;
          }

          // Check if we're in development or production
          const isDev = window.location.hostname === 'localhost';
          console.log('Is development environment:', isDev);
          console.log('Current hostname:', window.location.hostname);
          
          // Handle localhost for development while respecting configuration for production
          let config;
          
          if (isDev) {
            // In development, always use localhost with current port
            config = {
              scheme: 'http',
              host: `${window.location.hostname}:${window.location.port}`
            };
          } else if (configuration && configuration.host && configuration.scheme) {
            // Use the Firestore configuration in production if available
            config = configuration;
          } else {
            // Only use window.location as fallback if no configuration is found
            // This avoids hardcoding any domain
            config = {
              scheme: window.location.protocol.replace(':', ''),
              host: window.location.host
            };
          }
          
          console.log('Using config:', config);
          
          // Extract path components for proper URL construction
          const pathParts = refLocation.split('/');
          const isServerProject = pathParts.includes('serverProjects');
          
          // Construct the URL with the right path format
          let projectURL;
          if (pathParts.length >= 4) {
            // Typically in format: [projects|serverProjects]/year/month/id
            const collection = isServerProject ? 'serverProjects' : 'projects';
            const year = pathParts[pathParts.indexOf(isServerProject ? 'serverProjects' : 'projects') + 1];
            const month = pathParts[pathParts.indexOf(isServerProject ? 'serverProjects' : 'projects') + 2];
            const id = pathParts[pathParts.indexOf(isServerProject ? 'serverProjects' : 'projects') + 3];
            
            projectURL = `${config.scheme}://${config.host}/${collection}/${year}/${month}/${id}`;
          } else {
            // Fallback to the standard format if path structure is different
            projectURL = `${config.scheme}://${config.host}/${refLocation}`;
          }
          
          console.log('Generated Share URL:', projectURL);
          setURL(projectURL);

          // Determine if it's servers or workstations
          const inferredType = refLocation.split('/').includes('serverProjects')
            ? 'servers'
            : 'workstations';
          
          setProjectType(inferredType);

          // Set up real-time listener
          const itemsRef = firestore.collection(`${refLocation}/${inferredType}`);
          console.log(`Fetching ${inferredType} from ${refLocation}/${inferredType}`);

          const unsubscribe = itemsRef.onSnapshot((snapshot) => {
            const itemsData = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id
            }));
            
            if (inferredType === 'servers') {
              setServers(itemsData);
              setWorkstations([]);
            } else {
              setWorkstations(itemsData);
              setServers([]);
            }
          });

          // Store the unsubscribe function
          projectUnsubscribeRef.current = unsubscribe;
        } else {
          console.error(`Project not found: ${projectId} in client ${clientID}`);
        }
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };

    fetchProject();

    // Cleanup on unmount or when project selection changes
    return () => {
      if (projectUnsubscribeRef.current) {
        projectUnsubscribeRef.current();
        projectUnsubscribeRef.current = null;
      }
    };
  }, [selectedProject, defaultClientID, getClientName, configuration, firestore]);

  // Function to manually clear project data
  const clearProjectData = () => {
    if (projectUnsubscribeRef.current) {
      projectUnsubscribeRef.current();
      projectUnsubscribeRef.current = null;
    }
    setProjectData(null);
    setWorkstations([]);
    setServers([]);
    setCustomTitle(defaultClientID ? getClientName(defaultClientID) : '');
    setURL('');
  };

  return {
    selectedProject: projectData,
    workstations,
    servers,
    customTitle,
    url,
    projectType,
    clearProjectData
  };
};

export default useSelectedProjectData;