// Filtering utilities for workstations and servers

export const filterWorkstations = (workstations, filters, searchQuery, sortConfig) => {
  return workstations.filter((workstation) => {
    // 1. Check if the workstation matches the search query
    const value = workstation[sortConfig.key] ?? '';
    const matchesSearch = searchQuery
      ? value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    // 2. Break down filter conditions
    const hasComputers = Object.keys(workstation.computers ?? {}).length > 0;
    const hasDockingStations = Object.keys(workstation.dockingStations ?? {}).length > 0;
    const isDisconnectOnly = !!workstation.disconnectOnly;
    const isQaCompleted = workstation.qa && workstation.qa.completedBy;
    const hasUnresolvedIssue =
      workstation.issues &&
      Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy);
    const isReconnected = !!workstation.reconnect?.completedBy;

    // 3. Combine filters
    const matchesFilters =
      (!filters.filterByComputers || hasComputers) &&
      (!filters.filterByDockingStations || hasDockingStations) &&
      (!filters.status.disconnectOnly || isDisconnectOnly) &&
      (!filters.status.qa || isQaCompleted) &&
      (!filters.status.issue || hasUnresolvedIssue) &&
      (!filters.status.reconnected || isReconnected);

    return matchesSearch && matchesFilters;
  });
};

export const filterServers = (servers, searchQuery, sortConfig, serverTypeFilter, destinationAlternative) => {
  return servers.filter((server) => {
    // 1. Basic search
    let matchesSearch = true;
    if (searchQuery) {
      // Check if we're in cart display mode with search
      if ((sortConfig.key === 'destination' && destinationAlternative === 'cart') || sortConfig.key === 'cart') {
        // Search for cart name in the cartRef
        if (server.cartRef) {
          // If cartRef has a specific structure (it might be a reference or just an ID)
          // Try to extract any relevant info for searching
          let cartInfo = '';
          if (typeof server.cartRef === 'string') {
            // If it's a path string, get the last part as ID
            cartInfo = server.cartRef.split('/').pop() || '';
          } else if (server.cartRef.id) {
            // If it's a reference object with ID
            cartInfo = server.cartRef.id;
          } else if (server.cartRef.cartName) {
            // If cartRef has name directly (unlikely but possible)
            cartInfo = server.cartRef.cartName;
          }
          
          // Custom cart name would be in another property
          if (server.cartName) {
            cartInfo = server.cartName;
          }
          
          matchesSearch = cartInfo.toString().toLowerCase().includes(searchQuery.toLowerCase());
        } else {
          // If no cart assigned, this server doesn't match the search
          matchesSearch = false;
        }
      } else if (sortConfig.key === 'destination') {
        // Regular destination search for row-rack-RU
        const destinationString = [
          server.destinationRow && `Row ${server.destinationRow}`,
          server.destinationRack && `Rack ${server.destinationRack}`,
          server.destinationRackUnit && `RU ${server.destinationRackUnit}`
        ].filter(Boolean).join(' - ').toLowerCase();
        
        matchesSearch = destinationString.includes(searchQuery.toLowerCase());
      } else {
        const value = server[sortConfig.key] ?? '';
        matchesSearch = value.toString().toLowerCase().includes(searchQuery.toLowerCase());
      }
    }

    // 2. Type filter:
    // If serverTypeFilter is empty, we match all.
    // Otherwise, perform case-insensitive comparison between server.type and filters
    const matchesType =
      serverTypeFilter.length === 0 ||
      (server.type && serverTypeFilter.some(type => 
        type.toLowerCase() === server.type.toLowerCase()));

    return matchesSearch && matchesType;
  });
};