import { v4 as uuidv4 } from 'uuid';

// Field type mapping for workstations (all are strings)
const fieldTypes = {
  'username': 'string',
  'origin': 'string',
  'destination': 'string',
  'originBuilding': 'string',
  'originFloor': 'string',
  'destinationBuilding': 'string',
  'destinationFloor': 'string',
  'notes': 'string'
};

// Column name normalization mapping (for case-insensitive matching)
const columnMapping = {
  'username': 'username',
  'user': 'username',
  'user name': 'username',
  'name': 'username',
  'origin': 'origin',
  'from': 'origin',
  'source': 'origin',
  'destination': 'destination',
  'dest': 'destination',
  'to': 'destination',
  'target': 'destination',
  'originbuilding': 'originBuilding',
  'origin building': 'originBuilding',
  'from building': 'originBuilding',
  'source building': 'originBuilding',
  'originfloor': 'originFloor',
  'origin floor': 'originFloor',
  'from floor': 'originFloor',
  'source floor': 'originFloor',
  'destinationbuilding': 'destinationBuilding',
  'destination building': 'destinationBuilding',
  'to building': 'destinationBuilding',
  'target building': 'destinationBuilding',
  'destinationfloor': 'destinationFloor',
  'destination floor': 'destinationFloor',
  'to floor': 'destinationFloor',
  'target floor': 'destinationFloor',
  'notes': 'notes',
  'note': 'notes',
  'comment': 'notes',
  'comments': 'notes'
};

const handleWorkstationFileUpload = async (rows, firestore, selectedProject, onComplete) => {
  try {
    console.log('WorkstationUploadHandler started with', rows.length, 'rows');
    
    // Make sure we have a valid project path
    if (!selectedProject?.refLocation?.path) {
      throw new Error('Invalid project reference. Project path is undefined.');
    }
    
    // Fetch existing workstations to check for duplicates
    console.log('Fetching existing workstations from path:', `${selectedProject.refLocation.path}/workstations`);
    const existingWorkstationsSnapshot = await firestore
      .collection(`${selectedProject.refLocation.path}/workstations`)
      .get();
      
    // Create lookup maps for faster checking of duplicates
    const workstationLookupMap = {};
    
    existingWorkstationsSnapshot.forEach(doc => {
      const data = doc.data();
      // Create key using username since that's our primary identifier
      if (data.username) {
        const key = data.username.toLowerCase().trim();
        workstationLookupMap[key] = { ref: doc.ref, id: doc.id, data };
      }
    });
    
    console.log(`Found ${existingWorkstationsSnapshot.size} existing workstations`);
    
    const BATCH_SIZE = 50; // Process 50 workstations at a time
    let processedCount = 0;
    let workstationsCount = 0;
    let failedRows = [];
    const originalHeaders = rows.length > 0 ? Object.keys(rows[0]) : [];
    
    // Process data in batches
    for (let i = 0; i < rows.length; i += BATCH_SIZE) {
      const batch = firestore.batch();
      const currentBatch = rows.slice(i, i + BATCH_SIZE);
      let batchProcessed = 0;
      
      console.log(`Processing batch ${Math.floor(i/BATCH_SIZE) + 1} of ${Math.ceil(rows.length/BATCH_SIZE)}`);
      
      // Process each row in the current batch
      for (const row of currentBatch) {
        const dataToSubmit = {};
        let failureReason = null;
        
        // Process each field in the row
        Object.entries(row).forEach(([header, value]) => {
          // Skip if value is empty
          if (value === undefined || value === null || value === '') return;
          
          // Normalize the header name (lowercase and trim)
          const normalizedHeader = header.toLowerCase().trim();
          
          // Get the standardized field name from our mapping
          const fieldName = columnMapping[normalizedHeader];
          
          // Skip if we don't recognize this field
          if (!fieldName) {
            console.log(`Ignoring unknown column: ${header}`);
            return;
          }
          
          // Get the field type and convert accordingly
          const fieldType = fieldTypes[fieldName];
          
          switch (fieldType) {
            case 'string':
              dataToSubmit[fieldName] = `${value}`.trim();
              break;
            case 'number':
              dataToSubmit[fieldName] = Number(value);
              break;
            case 'boolean':
              if (value === 'TRUE' || value === 'true' || value === true) {
                dataToSubmit[fieldName] = true;
              } else if (value === 'FALSE' || value === 'false' || value === false) {
                dataToSubmit[fieldName] = false;
              }
              break;
            default:
              dataToSubmit[fieldName] = value;
          }
        });
        
        // Debug logging to check processed data
        console.log('Processed workstation data:', dataToSubmit);
        
        // Check for required field - username
        if (!dataToSubmit.username) {
          failureReason = 'Missing required field: username';
          failedRows.push({ ...row, error: failureReason });
          continue;
        }
        
        // Check if this workstation already exists (by username)
        const workstationKey = dataToSubmit.username.toLowerCase().trim();
        const existingWorkstation = workstationLookupMap[workstationKey];
        
        if (existingWorkstation) {
          // Update existing workstation instead of creating a new one
          console.log(`Updating existing workstation for user: ${dataToSubmit.username}`);
          batch.update(existingWorkstation.ref, dataToSubmit);
          batchProcessed++;
        } else {
          // Create new workstation
          console.log(`Creating new workstation for user: ${dataToSubmit.username}`);
          const docId = uuidv4();
          const collectionPath = `${selectedProject?.refLocation?.path}/workstations`;
          const docRef = firestore.collection(collectionPath).doc(docId);
          batch.set(docRef, dataToSubmit);
          batchProcessed++;
          
          // Add to lookup map for future duplicate checks within this batch
          workstationLookupMap[workstationKey] = { ref: docRef, id: docId, data: dataToSubmit };
        }
      }
      
      // Commit the current batch and update counters
      if (batchProcessed > 0) {
        console.log(`Committing batch of ${batchProcessed} workstations...`);
        await batch.commit();
        workstationsCount += batchProcessed;
        processedCount += currentBatch.length;
        console.log(`Progress: ${processedCount}/${rows.length} rows processed (${Math.round(processedCount/rows.length*100)}%)`);
      }
    }
    
    // Final report after all batches are done
    console.log(`Successfully processed ${workstationsCount} workstations`);
    
    // Return result object with processing information
    const result = {
      count: workstationsCount,
      headers: originalHeaders,
      failedRows: failedRows.length > 0 ? failedRows : null
    };
    
    console.log('WorkstationUploadHandler completed successfully. Returning result:', result);
    
    // Call onComplete callback if provided
    if (onComplete) {
      console.log('Calling onComplete callback');
      onComplete();
    }
    
    // Return the result for proper handling in the UI
    return result;
  } catch (error) {
    console.error("Error updating Firestore for workstations: ", error);
    
    if (onComplete) {
      console.log('Calling onComplete callback after error');
      onComplete();
    }
    
    // Rethrow the error to be caught by the caller
    throw error;
  }
};

export default handleWorkstationFileUpload;