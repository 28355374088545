import Papa from 'papaparse';
import { getWorkstationStatusText } from './workstationStatusUtils';

// Generate and download a CSV report for workstations or servers
export const generateReport = (items, projectType) => {
  let transformedData = [];

  if (projectType === 'workstations') {
    transformedData = items.map((item) => {
      const status = getWorkstationStatusText(item);

      return {
        username: item.username,
        origin: item.origin,
        destination: item.destination,
        issues: item.issues ? Object.keys(item.issues).length : 0,
        status: status
      };
    });
  } else {
    // For servers
    transformedData = items.map((server) => ({
      type: server.type || '',
      model: server.model || '',
      destinationRack: server.destinationRack || '',
      originRack: server.originRack || '',
      serialNumber: server.serialNumber || '',
    }));
  }

  const csv = Papa.unparse(transformedData);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute(
    'download',
    projectType === 'servers' ? 'servers.csv' : 'workstations.csv'
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};