import React from 'react';
import { 
  TableRow, 
  TableCell, 
  Box, 
  Typography, 
  IconButton, 
  CircularProgress,
  TextField,
  Button,
  MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const ConnectionRow = ({
  connection,
  server,
  hideServer1Column,
  hidePort1Column,
  hideCableTypeColumn,
  hideServer2Column,
  hidePort2Column,
  hideDestinationColumn,
  hideIsConnectedColumn,
  hideNotesColumn,
  editingCell,
  editValue,
  updateLoading,
  handleEditClick,
  handleSaveEdit,
  handleCancelEdit,
  handleEditChange,
  handleEditKeyPress,
  handleToggleConnected,
  updatingConnections
}) => {
  return (
    <TableRow>
      {/* Server 1 */}
      <TableCell sx={{ 
        width: hideServer1Column ? '40px' : 'auto',
        padding: hideServer1Column ? '6px 4px' : undefined,
        display: hideServer1Column ? 'table-cell' : 'table-cell'
      }}>
        {!hideServer1Column && <Typography variant="body1">{server.hostname || server.deviceName || "Server 1"}</Typography>}
      </TableCell>
      
      {/* Port 1 */}
      <TableCell sx={{ 
        width: hidePort1Column ? '40px' : 'auto',
        padding: hidePort1Column ? '6px 4px' : undefined,
        display: hidePort1Column ? 'table-cell' : 'table-cell'
      }}>
        {!hidePort1Column && (
          editingCell?.connectionId === connection.id && editingCell?.field === 'port1' ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <TextField
                variant="standard"
                value={editValue}
                onChange={handleEditChange}
                onKeyDown={(e) => handleEditKeyPress(e, connection)}
                autoFocus
                size="small"
                inputProps={{ style: { minWidth: '30px', maxWidth: '150px' } }}
                sx={{ 
                  mb: 1,
                  '& .MuiInputBase-input': {
                    fontFamily: 'inherit',
                    fontSize: 'inherit'
                  }
                }}
              />
              <Box sx={{ display: 'flex' }}>
                <IconButton 
                  size="small" 
                  onClick={() => handleSaveEdit(connection)}
                  color="primary"
                  disabled={updateLoading}
                  sx={{ padding: '2px', mr: 0.5 }}
                >
                  {updateLoading ? 
                    <CircularProgress size={14} /> : 
                    <CheckIcon fontSize="small" />
                  }
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={handleCancelEdit}
                  color="error"
                  sx={{ padding: '2px' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                '&:hover .edit-button': { 
                  visibility: 'visible' 
                }
              }}
            >
              <Typography variant="body1">{connection.localPort}</Typography>
              <IconButton 
                className="edit-button"
                size="small" 
                onClick={() => handleEditClick(connection, 'port1')} 
                color="primary"
                title="Edit Port 1"
                sx={{ 
                  ml: 1, 
                  p: 0.5,
                  visibility: 'hidden'
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        )}
      </TableCell>

      {/* Cable Type */}
      <TableCell sx={{ 
        width: hideCableTypeColumn ? '40px' : 'auto',
        padding: hideCableTypeColumn ? '6px 4px' : undefined,
        display: hideCableTypeColumn ? 'table-cell' : 'table-cell'
      }}>
        {!hideCableTypeColumn && (
          editingCell?.connectionId === connection.id && editingCell?.field === 'cableType' ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <TextField
                variant="standard"
                value={editValue}
                onChange={handleEditChange}
                onKeyDown={(e) => handleEditKeyPress(e, connection)}
                autoFocus
                size="small"
                inputProps={{ style: { minWidth: '30px', maxWidth: '150px' } }}
                sx={{ 
                  mb: 1,
                  '& .MuiInputBase-input': {
                    fontFamily: 'inherit',
                    fontSize: 'inherit'
                  }
                }}
              />
              <Box sx={{ display: 'flex' }}>
                <IconButton 
                  size="small" 
                  onClick={() => handleSaveEdit(connection)}
                  color="primary"
                  disabled={updateLoading}
                  sx={{ padding: '2px', mr: 0.5 }}
                >
                  {updateLoading ? 
                    <CircularProgress size={14} /> : 
                    <CheckIcon fontSize="small" />
                  }
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={handleCancelEdit}
                  color="error"
                  sx={{ padding: '2px' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                '&:hover .edit-button': { 
                  visibility: 'visible' 
                }
              }}
            >
              <Typography variant="body1">{connection.cableType}</Typography>
              <IconButton 
                className="edit-button"
                size="small" 
                onClick={() => handleEditClick(connection, 'cableType')} 
                color="primary"
                title="Edit Cable Type"
                sx={{ 
                  ml: 1, 
                  p: 0.5,
                  visibility: 'hidden'
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        )}
      </TableCell>
      
      {/* Server 2 */}
      <TableCell sx={{ 
        width: hideServer2Column ? '40px' : 'auto',
        padding: hideServer2Column ? '6px 4px' : undefined,
        display: hideServer2Column ? 'table-cell' : 'table-cell'
      }}>
        {!hideServer2Column && <Typography variant="body1">{connection.remoteName}</Typography>}
      </TableCell>
      
      {/* Port 2 */}
      <TableCell sx={{ 
        width: hidePort2Column ? '40px' : 'auto',
        padding: hidePort2Column ? '6px 4px' : undefined,
        display: hidePort2Column ? 'table-cell' : 'table-cell'
      }}>
        {!hidePort2Column && (
          editingCell?.connectionId === connection.id && editingCell?.field === 'port2' ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <TextField
                variant="standard"
                value={editValue}
                onChange={handleEditChange}
                onKeyDown={(e) => handleEditKeyPress(e, connection)}
                autoFocus
                size="small"
                inputProps={{ style: { minWidth: '30px', maxWidth: '150px' } }}
                sx={{ 
                  mb: 1,
                  '& .MuiInputBase-input': {
                    fontFamily: 'inherit',
                    fontSize: 'inherit'
                  }
                }}
              />
              <Box sx={{ display: 'flex' }}>
                <IconButton 
                  size="small" 
                  onClick={() => handleSaveEdit(connection)}
                  color="primary"
                  disabled={updateLoading}
                  sx={{ padding: '2px', mr: 0.5 }}
                >
                  {updateLoading ? 
                    <CircularProgress size={14} /> : 
                    <CheckIcon fontSize="small" />
                  }
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={handleCancelEdit}
                  color="error"
                  sx={{ padding: '2px' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                '&:hover .edit-button': { 
                  visibility: 'visible' 
                }
              }}
            >
              <Typography variant="body1">{connection.remotePort}</Typography>
              <IconButton 
                className="edit-button"
                size="small" 
                onClick={() => handleEditClick(connection, 'port2')} 
                color="primary"
                title="Edit Port 2"
                sx={{ 
                  ml: 1, 
                  p: 0.5,
                  visibility: 'hidden'
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        )}
      </TableCell>
      
      {/* Destination */}
      <TableCell sx={{ 
        width: hideDestinationColumn ? '40px' : 'auto',
        padding: hideDestinationColumn ? '6px 4px' : undefined,
        display: hideDestinationColumn ? 'table-cell' : 'table-cell'
      }}>
        {!hideDestinationColumn && (
          /* Only show remote server destination info, never the current server */
          connection.destinationRack || connection.destinationRackUnit || connection.destinationRow ? (
            <Typography variant="body1">
              {/* Format as Row-Rack-RU for the destination column, similar to Servers.js */}
              {[
                connection.destinationRow && `Row ${connection.destinationRow}`,
                connection.destinationRack && `Rack ${connection.destinationRack}`,
                connection.destinationRackUnit && `RU ${connection.destinationRackUnit}`
              ].filter(Boolean).join(' - ')}
            </Typography>
          ) : (
            <Typography variant="body2" color="text.secondary">-</Typography>
          )
        )}
      </TableCell>
      
      {/* Is Connected */}
      <TableCell sx={{ 
        width: hideIsConnectedColumn ? '40px' : 'auto',
        padding: hideIsConnectedColumn ? '6px 4px' : undefined,
        display: hideIsConnectedColumn ? 'table-cell' : 'table-cell'
      }}>
        {!hideIsConnectedColumn && (
          editingCell?.connectionId === connection.id && editingCell?.field === 'isConnected' ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
              <TextField
                select
                variant="standard"
                value={editValue}
                onChange={handleEditChange}
                autoFocus
                size="small"
                sx={{ 
                  width: '100%', 
                  mb: 1,
                  '& .MuiInputBase-input': {
                    fontFamily: 'inherit',
                    fontSize: 'inherit'
                  }
                }}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
              </TextField>
              <Box sx={{ display: 'flex' }}>
                <IconButton 
                  size="small" 
                  onClick={() => handleSaveEdit(connection)}
                  color="primary"
                  disabled={updateLoading}
                  sx={{ padding: '2px', mr: 0.5 }}
                >
                  {updateLoading ? 
                    <CircularProgress size={14} /> : 
                    <CheckIcon fontSize="small" />
                  }
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={handleCancelEdit}
                  color="error"
                  sx={{ padding: '2px' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center'
              }}
            >
              <Button
                size="small"
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleConnected(connection);
                }}
                disabled={updatingConnections[connection.id]}
                color={
                  connection.isConnected === true ? "success" :
                  connection.isConnected === false ? "error" :
                  "inherit"
                }
                sx={{ 
                  minWidth: 'auto', 
                  textTransform: 'none',
                  padding: '2px 8px',
                  cursor: 'pointer',
                  fontFamily: 'inherit',
                  fontSize: 'inherit',
                  typography: 'body1'
                }}
                startIcon={updatingConnections[connection.id] ? 
                  <CircularProgress size={14} color="inherit" /> : 
                  null
                }
              >
                {connection.isConnected === true ? "Yes" : 
                  connection.isConnected === false ? "No" : 
                  "Unknown"}
              </Button>
              <IconButton 
                className="edit-button"
                size="small" 
                onClick={() => handleEditClick(connection, 'isConnected')} 
                color="primary"
                title="Edit Connection Status"
                sx={{ 
                  ml: 0.5, 
                  p: 0.5,
                  '&:hover': { visibility: 'visible' },
                  visibility: 'hidden'
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        )}
      </TableCell>
      
      {/* Notes */}
      <TableCell sx={{ 
        width: hideNotesColumn ? '40px' : 'auto',
        padding: hideNotesColumn ? '6px 4px' : undefined,
        display: hideNotesColumn ? 'table-cell' : 'table-cell'
      }}>
        {!hideNotesColumn && (
          editingCell?.connectionId === connection.id && editingCell?.field === 'notes' ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-start', 
              width: '100%',
              minWidth: '250px' 
            }}>
              <TextField
                variant="standard"
                value={editValue}
                onChange={handleEditChange}
                onKeyDown={(e) => handleEditKeyPress(e, connection)}
                autoFocus
                size="small"
                sx={{ 
                  width: '100%', 
                  mb: 1,
                  minWidth: '200px',
                  '& .MuiInputBase-root': {
                    lineHeight: 1.2
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: 'inherit',
                    fontSize: 'inherit'
                  }
                }}
                multiline
                rows={3}
                inputProps={{ style: { padding: '4px 0' } }}
              />
              <Box sx={{ display: 'flex' }}>
                <IconButton 
                  size="small" 
                  onClick={() => handleSaveEdit(connection)}
                  color="primary"
                  disabled={updateLoading}
                  sx={{ padding: '2px', mr: 0.5 }}
                >
                  {updateLoading ? 
                    <CircularProgress size={14} /> : 
                    <CheckIcon fontSize="small" />
                  }
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={handleCancelEdit}
                  color="error"
                  sx={{ padding: '2px' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                '&:hover .edit-button': { 
                  visibility: 'visible' 
                }
              }}
            >
              <Typography variant="body1">{connection.notes}</Typography>
              <IconButton 
                className="edit-button"
                size="small" 
                onClick={() => handleEditClick(connection, 'notes')} 
                color="primary"
                title="Edit Notes"
                sx={{ 
                  ml: 1, 
                  p: 0.5,
                  visibility: 'hidden'
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        )}
      </TableCell>
    </TableRow>
  );
};

export default ConnectionRow;