import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { filterConnections, sortConnections } from './utils';

/**
 * Custom hook to handle fetching and managing connection data
 */
export const useConnectionsData = (server, projectRef) => {
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);
  const firestore = useFirestore();
  
  const fetchConnections = async () => {
    setLoading(true);
    try {
      // Get server identifiers from all possible sources
      const serverId = server.documentId || server.id;
      const hostname = server.hostname;
      const deviceName = server.deviceName;
      
      console.log("Server details:", {
        id: serverId,
        hostname: hostname,
        deviceName: deviceName,
        allProps: Object.keys(server)
      });
      
      if (!serverId && !hostname && !deviceName) {
        console.log("No server identifier available");
        setLoading(false);
        return;
      }

      console.log(`Fetching connections for server with ID: ${serverId}, hostname: ${hostname}, deviceName: ${deviceName}`);
      console.log(`Searching in path: ${projectRef}/connections`);
      
      let server1ConnectionsIdQuery = [];
      let server2ConnectionsIdQuery = [];
      let server1ConnectionsNameQuery = [];
      let server2ConnectionsNameQuery = [];
      
      // Run all queries in parallel for better performance
      const runParallelQueries = async () => {
        const queries = [];
        
        // 1. Try to match by ID
        if (serverId) {
          queries.push(
            firestore
              .collection(`${projectRef}/connections`)
              .where('server1Id', '==', serverId)
              .get()
              .then(result => {
                server1ConnectionsIdQuery = result.docs;
                return result.size;
              })
          );
          
          queries.push(
            firestore
              .collection(`${projectRef}/connections`)
              .where('server2Id', '==', serverId)
              .get()
              .then(result => {
                server2ConnectionsIdQuery = result.docs;
                return result.size;
              })
          );
        }
        
        // 2. Try to match by hostname
        if (hostname) {
          queries.push(
            firestore
              .collection(`${projectRef}/connections`)
              .where('server1Name', '==', hostname)
              .get()
              .then(result => {
                server1ConnectionsNameQuery = result.docs;
                return result.size;
              })
          );
          
          queries.push(
            firestore
              .collection(`${projectRef}/connections`)
              .where('server2Name', '==', hostname)
              .get()
              .then(result => {
                server2ConnectionsNameQuery = result.docs;
                return result.size;
              })
          );
        }
        
        // 3. Try to match by deviceName if different from hostname
        if (deviceName && deviceName !== hostname) {
          queries.push(
            firestore
              .collection(`${projectRef}/connections`)
              .where('server1', '==', deviceName)
              .get()
              .then(result => {
                server1ConnectionsNameQuery = [...server1ConnectionsNameQuery, ...result.docs];
                return result.size;
              })
          );
          
          queries.push(
            firestore
              .collection(`${projectRef}/connections`)
              .where('server2', '==', deviceName)
              .get()
              .then(result => {
                server2ConnectionsNameQuery = [...server2ConnectionsNameQuery, ...result.docs];
                return result.size;
              })
          );
        }
        
        // Execute all queries in parallel
        const results = await Promise.all(queries);
        console.log(`Parallel query results: ${results.join(', ')}`);
        
        // Skip the case-insensitive search if we found results already to speed things up
        if ((server1ConnectionsIdQuery.length + server2ConnectionsIdQuery.length + 
             server1ConnectionsNameQuery.length + server2ConnectionsNameQuery.length) === 0 && hostname) {
          // Only if we haven't found any results, do an expensive case-insensitive search
          const allConnectionsQuery = await firestore
            .collection(`${projectRef}/connections`)
            .get();
          
          console.log(`Examining ${allConnectionsQuery.size} total connections for case-insensitive matches`);
          
          allConnectionsQuery.forEach(doc => {
            const data = doc.data();
            if (data.server1Name && data.server1Name.toLowerCase() === hostname.toLowerCase() &&
                !server1ConnectionsNameQuery.some(d => d.id === doc.id)) {
              server1ConnectionsNameQuery.push(doc);
            }
            
            if (data.server2Name && data.server2Name.toLowerCase() === hostname.toLowerCase() &&
                !server2ConnectionsNameQuery.some(d => d.id === doc.id)) {
              server2ConnectionsNameQuery.push(doc);
            }
          });
        }
      };
      
      // Run all queries in parallel
      await runParallelQueries();
      
      // Process connections efficiently
      // Process connections by ID where this server is the source
      const server1Connections = server1ConnectionsIdQuery.map(doc => {
        const data = doc.data();
        
        // Extract destination info without additional objects
        let destinationRack = null, destinationRackUnit = null, destinationRow = null;
        
        if (data.server2Id) {
          destinationRack = data.server2DestinationRack || data.server2Rack || data.targetRack;
          destinationRackUnit = data.server2DestinationRackUnit || data.server2RackUnit || data.targetRackUnit;
          destinationRow = data.server2DestinationRow || data.server2Row || data.targetRow;
        }
        
        return {
          id: doc.id,
          ...data,
          destinationRack,
          destinationRackUnit,
          destinationRow,
          direction: 'from',
          localPort: data.connectionDetails?.port1,
          remotePort: data.connectionDetails?.port2,
          remoteName: data.server2Name
        };
      });

      // Process connections by ID where this server is the destination
      const server2Connections = server2ConnectionsIdQuery.map(doc => {
        const data = doc.data();
        
        // Extract destination info without additional objects
        let destinationRack = null, destinationRackUnit = null, destinationRow = null;
        
        if (data.server1Id) {
          destinationRack = data.server1DestinationRack || data.server1Rack || data.sourceRack;
          destinationRackUnit = data.server1DestinationRackUnit || data.server1RackUnit || data.sourceRackUnit;
          destinationRow = data.server1DestinationRow || data.server1Row || data.sourceRow;
        }
        
        return {
          id: doc.id,
          ...data,
          destinationRack,
          destinationRackUnit,
          destinationRow,
          direction: 'to',
          localPort: data.connectionDetails?.port2,
          remotePort: data.connectionDetails?.port1,
          remoteName: data.server1Name
        };
      });
      
      // Get unique IDs to check for duplicates
      const existingIds = new Set([...server1Connections, ...server2Connections].map(c => c.id));
      
      // Process connections by name where this server is the source and add only non-duplicates
      const additionalServer1Connections = server1ConnectionsNameQuery
        .filter(doc => !existingIds.has(doc.id))
        .map(doc => {
          const data = doc.data();
          
          // Extract destination info directly
          let destinationRack = null, destinationRackUnit = null, destinationRow = null;
          
          if (data.server2Id) {
            destinationRack = data.server2DestinationRack || data.server2Rack || data.targetRack;
            destinationRackUnit = data.server2DestinationRackUnit || data.server2RackUnit || data.targetRackUnit;
            destinationRow = data.server2DestinationRow || data.server2Row || data.targetRow;
          }
          
          return {
            id: doc.id,
            ...data,
            destinationRack,
            destinationRackUnit,
            destinationRow,
            direction: 'from',
            localPort: data.connectionDetails?.port1,
            remotePort: data.connectionDetails?.port2,
            remoteName: data.server2Name
          };
        });

      // Process connections by name where this server is the destination and add only non-duplicates
      const additionalServer2Connections = server2ConnectionsNameQuery
        .filter(doc => !existingIds.has(doc.id))
        .map(doc => {
          const data = doc.data();
          
          // Extract destination info directly
          let destinationRack = null, destinationRackUnit = null, destinationRow = null;
          
          if (data.server1Id) {
            destinationRack = data.server1DestinationRack || data.server1Rack || data.sourceRack;
            destinationRackUnit = data.server1DestinationRackUnit || data.server1RackUnit || data.sourceRackUnit;
            destinationRow = data.server1DestinationRow || data.server1Row || data.sourceRow;
          }
          
          return {
            id: doc.id,
            ...data,
            destinationRack,
            destinationRackUnit,
            destinationRow,
            direction: 'to',
            localPort: data.connectionDetails?.port2,
            remotePort: data.connectionDetails?.port1,
            remoteName: data.server1Name
          };
        });

      // Combine all initial connections
      const initialConnections = [
        ...server1Connections, 
        ...server2Connections,
        ...additionalServer1Connections,
        ...additionalServer2Connections
      ];
      
      // Get all server IDs that we need destination info for
      const allServerIds = initialConnections.map(conn => 
        conn.direction === 'from' ? conn.server2Id : conn.server1Id
      ).filter(id => id); // Remove undefined/null
      
      // Only fetch unique server IDs
      const uniqueServerIds = [...new Set(allServerIds)];
      
      // Skip server fetches if there are no connections or IDs to fetch
      if (initialConnections.length === 0 || uniqueServerIds.length === 0) {
        console.log(`Found ${initialConnections.length} total connections`);
        setConnections(initialConnections);
        setLoading(false);
        return;
      }
      
      try {
        // Create a map to store server destination data
        const serverDataMap = {};
        
        // Fetch all server documents in parallel
        console.time('serverFetch');
        const serverDocs = await Promise.all(
          uniqueServerIds.map(serverId => 
            firestore.doc(`${projectRef}/servers/${serverId}`).get()
          )
        );
        console.timeEnd('serverFetch');
        
        // Process all server documents
        serverDocs.forEach((doc, index) => {
          if (doc.exists) {
            const serverId = uniqueServerIds[index];
            const data = doc.data();
            
            serverDataMap[serverId] = {
              destinationRack: data.destinationRack,
              destinationRackUnit: data.destinationRackUnit,
              destinationRow: data.destinationRow
            };
          }
        });
        
        // Apply destination data to all connections in one go
        const completedConnections = initialConnections.map(conn => {
          const remoteId = conn.direction === 'from' ? conn.server2Id : conn.server1Id;
          
          if (remoteId && serverDataMap[remoteId]) {
            return {
              ...conn,
              destinationRack: serverDataMap[remoteId].destinationRack,
              destinationRackUnit: serverDataMap[remoteId].destinationRackUnit,
              destinationRow: serverDataMap[remoteId].destinationRow
            };
          }
          
          return conn;
        });
        
        // Log and set the final connections with all data
        console.log(`Found ${completedConnections.length} total connections with destination data`);
        setConnections(completedConnections);
      } catch (error) {
        console.error("Error fetching server destination data:", error);
        // Fall back to initial connections if there's an error
        setConnections(initialConnections);
      } finally {
        // Always turn off loading state
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching connections:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch connections when component mounts or server changes
  useEffect(() => {
    if (server.documentId || server.id || server.hostname || server.deviceName) {
      fetchConnections();
    }
  }, [server]);

  return { connections, loading, fetchConnections };
};

/**
 * Custom hook to handle updating connections
 */
export const useConnectionUpdates = (projectRef) => {
  const firestore = useFirestore();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updatingConnections, setUpdatingConnections] = useState({});

  const updateConnection = async (connectionId, updateData) => {
    setUpdateLoading(true);
    try {
      await firestore.update(
        `${projectRef}/connections/${connectionId}`,
        updateData
      );
      return true;
    } catch (error) {
      console.error("Error updating connection:", error);
      return false;
    } finally {
      setUpdateLoading(false);
    }
  };

  const toggleConnectionStatus = async (connection) => {
    // Set loading state for this specific connection
    setUpdatingConnections(prev => ({
      ...prev,
      [connection.id]: true
    }));
    
    try {
      // Toggle between true and false (Yes and No)
      // If Unknown, set to Yes
      let newStatus;
      if (connection.isConnected === true) {
        newStatus = false;
      } else {
        // Both false and null (unknown) go to true
        newStatus = true;
      }
      
      // Update in Firestore
      await firestore.update(
        `${projectRef}/connections/${connection.id}`,
        { isConnected: newStatus }
      );
      
      return newStatus;
    } catch (error) {
      console.error("Error updating connection status:", error);
      return null;
    } finally {
      // Clear loading state for this specific connection
      setUpdatingConnections(prev => ({
        ...prev,
        [connection.id]: false
      }));
    }
  };

  return { 
    updateConnection,
    toggleConnectionStatus,
    updateLoading,
    updatingConnections
  };
};

/**
 * Custom hook to manage sorting and filtering state
 */
export const useSortAndFilter = () => {
  // Sorting and filtering state
  const [sortConfig, setSortConfig] = useState({ key: 'port1', direction: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearch, setShowSearch] = useState({});
  const [searchColumn, setSearchColumn] = useState('port1'); // Track active search column
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterColumn, setFilterColumn] = useState(null);
  const [isConnectedFilterAnchorEl, setIsConnectedFilterAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    server1: [],
    port1: [],
    cableType: [],
    server2: [],
    port2: [],
    destination: [],
    isConnected: [],
    notes: []
  });

  // Handle sorting, searching, and filtering
  const handleSortChange = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const toggleSearch = (key) => {
    setShowSearch(prev => {
      const newState = { ...prev };
      // Close all other search fields
      Object.keys(newState).forEach(k => newState[k] = false);
      // Toggle this one
      newState[key] = !prev[key];
      
      // Set search column to the one being toggled on
      if (!prev[key]) {
        setSearchColumn(key);
      }
      
      // Clear search if closing
      if (!newState[key]) setSearchQuery('');
      
      return newState;
    });
  };

  const handleFilterClick = (event, column) => {
    setFilterColumn(column);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
    setFilterColumn(null);
  };

  const toggleFilter = (column, value) => {
    setFilters(prev => {
      const updatedFilters = { ...prev };
      if (updatedFilters[column].includes(value)) {
        // Remove value from filter
        updatedFilters[column] = updatedFilters[column].filter(v => v !== value);
      } else {
        // Add value to filter
        updatedFilters[column] = [...updatedFilters[column], value];
      }
      return updatedFilters;
    });
  };
  
  // Clear filters for a specific column
  const clearColumnFilters = (column) => {
    setFilters(prev => ({
      ...prev,
      [column]: []
    }));
    handleCloseFilter();
  };
  
  // Handle opening the Is Connected filter dropdown
  const handleIsConnectedFilterClick = (event) => {
    setIsConnectedFilterAnchorEl(event.currentTarget);
  };
  
  // Handle closing the Is Connected filter dropdown
  const handleIsConnectedFilterClose = () => {
    setIsConnectedFilterAnchorEl(null);
  };
  
  // Handle toggling an Is Connected filter value
  const toggleIsConnectedFilter = (value) => {
    setFilters(prev => {
      const updatedFilters = { ...prev };
      if (updatedFilters.isConnected.includes(value)) {
        // Remove value from filter
        updatedFilters.isConnected = updatedFilters.isConnected.filter(v => v !== value);
      } else {
        // Add value to filter
        updatedFilters.isConnected = [...updatedFilters.isConnected, value];
      }
      return updatedFilters;
    });
  };
  
  // Clear Is Connected filters
  const clearIsConnectedFilters = () => {
    setFilters(prev => ({
      ...prev,
      isConnected: []
    }));
    handleIsConnectedFilterClose();
  };

  // Apply sorting and filtering to connections
  const getSortedAndFilteredConnections = (connections) => {
    if (!connections || !Array.isArray(connections) || connections.length === 0) {
      return [];
    }
    
    // First get a server object from the connections if available
    const firstConnection = connections[0];
    const server = firstConnection?.server || { 
      hostname: firstConnection?.server1Name || firstConnection?.remoteName,
      deviceName: firstConnection?.server1 || firstConnection?.server2
    };
    
    // Apply sorting
    let sortedData = [...connections];
    try {
      sortedData = sortConnections(connections, sortConfig, server);
    } catch (error) {
      console.error("Error sorting connections:", error);
    }
    
    // Apply filtering
    try {
      return filterConnections(
        sortedData,
        filters,
        searchQuery,
        searchColumn, // Use searchColumn instead of sortConfig.key
        server
      );
    } catch (error) {
      console.error("Error filtering connections:", error);
      return sortedData;
    }
  };

  return {
    sortConfig,
    searchQuery,
    searchColumn,
    showSearch,
    filters,
    anchorEl,
    filterColumn,
    isConnectedFilterAnchorEl,
    handleSortChange,
    handleSearchChange,
    toggleSearch,
    handleFilterClick,
    handleCloseFilter,
    toggleFilter,
    clearColumnFilters,
    handleIsConnectedFilterClick,
    handleIsConnectedFilterClose,
    toggleIsConnectedFilter,
    clearIsConnectedFilters,
    getSortedAndFilteredConnections
  };
};

/**
 * Custom hook to manage column visibility
 */
export const useColumnVisibility = () => {
  const [hideServer1Column, setHideServer1Column] = useState(false);
  const [hidePort1Column, setHidePort1Column] = useState(false);
  const [hideCableTypeColumn, setHideCableTypeColumn] = useState(false);
  const [hideServer2Column, setHideServer2Column] = useState(false);
  const [hidePort2Column, setHidePort2Column] = useState(false);
  const [hideDestinationColumn, setHideDestinationColumn] = useState(false);
  const [hideIsConnectedColumn, setHideIsConnectedColumn] = useState(false);
  const [hideNotesColumn, setHideNotesColumn] = useState(false);
  
  // Column header options visibility state
  const [showHeaderOptions, setShowHeaderOptions] = useState({
    server1: false,
    port1: false,
    cableType: false,
    server2: false,
    port2: false,
    destination: false,
    isConnected: false,
    notes: false
  });
  
  // Toggle the column header options visibility
  const toggleHeaderOptions = (column) => {
    setShowHeaderOptions(prev => ({
      ...prev,
      [column]: !prev[column]
    }));
  };

  return {
    hideServer1Column,
    hidePort1Column,
    hideCableTypeColumn,
    hideServer2Column,
    hidePort2Column,
    hideDestinationColumn,
    hideIsConnectedColumn,
    hideNotesColumn,
    setHideServer1Column,
    setHidePort1Column,
    setHideCableTypeColumn,
    setHideServer2Column,
    setHidePort2Column,
    setHideDestinationColumn,
    setHideIsConnectedColumn,
    setHideNotesColumn,
    showHeaderOptions,
    toggleHeaderOptions
  };
};

/**
 * Custom hook to manage cart functionality
 */
export const useCartManagement = (server, projectRef) => {
  const [carts, setCarts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCart, setSelectedCart] = useState('');
  const firestore = useFirestore();

  // Fetch carts from the project
  useEffect(() => {
    const fetchCarts = async () => {
      if (!projectRef) return;
      
      setLoading(true);
      try {
        const cartsSnapshot = await firestore.collection(`${projectRef}/carts`).get();
        const cartsData = cartsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCarts(cartsData);
        
        // If server has a cartRef, set it as selected
        if (server?.cartRef) {
          // Handle both reference objects and string paths
          const cartId = typeof server.cartRef === 'string' 
            ? server.cartRef.split('/').pop() 
            : (server.cartRef.id || '');
          setSelectedCart(cartId);
        }
      } catch (error) {
        console.error("Error fetching carts:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchCarts();
  }, [projectRef, server, firestore]);

  // Handle cart selection change
  const handleCartChange = async (cartId) => {
    setSelectedCart(cartId);
    
    if (!cartId || !server?.documentId) return;
    
    try {
      // Create a reference to the cart document
      const cartRef = firestore.doc(`${projectRef}/carts/${cartId}`);
      
      // Update the server document with the cart reference
      await firestore.update(`${projectRef}/servers/${server.documentId}`, {
        cartRef: cartRef
      });
      
      console.log(`Server ${server.documentId} assigned to cart ${cartId}`);
      return true;
    } catch (error) {
      console.error("Error updating server with cart reference:", error);
      return false;
    }
  };

  // Handle creating a new cart
  const createCart = async (cartName) => {
    if (!cartName.trim() || !projectRef) return null;
    
    try {
      // Add new cart to Firestore
      const cartRef = await firestore.add(`${projectRef}/carts`, {
        cartName: cartName.trim(),
        createdAt: firestore.FieldValue.serverTimestamp()
      });
      
      // Update the server with the new cart reference if there's a server
      if (server?.documentId) {
        await firestore.update(`${projectRef}/servers/${server.documentId}`, {
          cartRef: cartRef
        });
      }
      
      // Refresh carts list
      const cartsSnapshot = await firestore.collection(`${projectRef}/carts`).get();
      const cartsData = cartsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCarts(cartsData);
      
      // Set the new cart as selected
      setSelectedCart(cartRef.id);
      
      console.log(`Created new cart: ${cartName} with ID: ${cartRef.id}`);
      return cartRef.id;
    } catch (error) {
      console.error("Error creating cart:", error);
      return null;
    }
  };

  return {
    carts,
    loading,
    selectedCart,
    handleCartChange,
    createCart
  };
};

/**
 * Custom hook to fetch cart names for all cart references
 * This hook fetches all cart documents in a collection and creates
 * a mapping of cart IDs to their names for efficient lookup
 */
export const useCartNames = (projectRef) => {
  const [cartNames, setCartNames] = useState({});
  const [loading, setLoading] = useState(false);
  const firestore = useFirestore();
  // Use a ref to track if this is already mounted to avoid duplicate fetches
  const fetchedRef = useRef(false);

  // Fetch cart names when component mounts or projectRef changes
  useEffect(() => {
    if (!projectRef) return;
    
    // Always fetch, since this is needed after cart creation
    fetchedRef.current = true;
    
    const fetchCartNames = async () => {
      setLoading(true);
      try {
        const cartsPath = `${projectRef}/carts`;
        console.log(`Fetching cart names from: ${cartsPath}`);
        
        const cartsSnapshot = await firestore.collection(cartsPath).get();
        
        // Create mapping of cart IDs to cart names
        const namesMap = {};
        cartsSnapshot.docs.forEach(doc => {
          const cartData = doc.data();
          namesMap[doc.id] = cartData.cartName || 'Unnamed Cart';
          console.log(`Cart ${doc.id}: ${cartData.cartName || 'Unnamed Cart'}`);
        });
        
        console.log(`Retrieved ${Object.keys(namesMap).length} cart names`);
        setCartNames(namesMap);
      } catch (error) {
        console.error('Error fetching cart names:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCartNames();
    
    // Listen for cart update events
    const handleCartUpdate = (event) => {
      const { action, cartId, cartName } = event.detail;
      console.log(`Received cartUpdate event: ${action} for ${cartId} (${cartName})`);
      
      if (action === 'create' || action === 'update') {
        // Update the cartNames map with the new cart
        setCartNames(prev => ({
          ...prev,
          [cartId]: cartName
        }));
      } else if (action === 'delete') {
        // Remove the cart from cartNames map
        setCartNames(prev => {
          const updated = { ...prev };
          delete updated[cartId];
          return updated;
        });
      }
    };
    
    window.addEventListener('cartUpdate', handleCartUpdate);
    
    return () => {
      window.removeEventListener('cartUpdate', handleCartUpdate);
    };
  }, [projectRef, firestore]);

  // Helper function to get a cart name from an ID or reference
  const getCartName = (cartRef) => {
    if (!cartRef) return null;
    
    // Extract the cart ID from the reference
    let cartId;
    if (typeof cartRef === 'string') {
      cartId = cartRef.split('/').pop();
    } else if (cartRef.id) {
      cartId = cartRef.id;
    } else if (cartRef.path) {
      cartId = cartRef.path.split('/').pop();
    }
    
    // Log the cart ID and all available cart names for debugging
    console.log('Getting cart name for ID:', cartId);
    console.log('Available cart names:', cartNames);
    
    // Return the cart name if found, otherwise return null (don't fallback to ID)
    return cartId ? cartNames[cartId] : null;
  };

  return { cartNames, loading, getCartName };
};

/**
 * Custom hook to manage inline editing
 */
export const useInlineEditing = (connections, projectRef) => {
  const [editingCell, setEditingCell] = useState(null); // Format: {connectionId, field}
  const [editValue, setEditValue] = useState('');
  const [localConnections, setLocalConnections] = useState([]);
  
  const { updateConnection, updateLoading } = useConnectionUpdates(projectRef);
  
  // Update localConnections when connections prop changes
  useEffect(() => {
    setLocalConnections(connections);
  }, [connections]);
  
  // Make setLocalConnections available to consuming components
  const updateLocalConnections = (updater) => {
    setLocalConnections(updater);
  };

  // Handle starting inline editing for a specific cell
  const handleEditClick = (connection, field) => {
    // Set which cell is being edited
    setEditingCell({ connectionId: connection.id, field });
    
    // Initialize the edit value based on the field
    if (field === 'port1') {
      setEditValue(connection.localPort || '');
    } else if (field === 'port2') {
      setEditValue(connection.remotePort || '');
    } else if (field === 'cableType') {
      setEditValue(connection.cableType || '');
    } else if (field === 'isConnected') {
      // Convert boolean to string for the dropdown
      setEditValue(
        connection.isConnected === true ? 'Yes' : 
        connection.isConnected === false ? 'No' : 
        'Unknown'
      );
    } else if (field === 'notes') {
      setEditValue(connection.notes || '');
    }
  };

  // Handle canceling the edit
  const handleCancelEdit = () => {
    setEditingCell(null);
    setEditValue('');
  };

  // Handle edit value change
  const handleEditChange = (e) => {
    setEditValue(e.target.value);
  };

  // Handle key press in edit field
  const handleEditKeyPress = (e, connection) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSaveEdit(connection);
    } else if (e.key === 'Escape') {
      handleCancelEdit();
    }
  };

  // Handle saving the edit
  const handleSaveEdit = async (connection) => {
    if (!editingCell) return;
    
    const { field } = editingCell;
    const isSourceServer = connection.direction === 'from';
    
    // Prepare update data based on which field is being edited
    const updateData = {};
    
    if (field === 'port1') {
      // Update the appropriate port based on whether this is server1 or server2
      updateData.connectionDetails = { ...connection.connectionDetails };
      if (isSourceServer) {
        updateData.connectionDetails.port1 = editValue;
      } else {
        updateData.connectionDetails.port2 = editValue;
      }
    } 
    else if (field === 'port2') {
      // Update the appropriate port based on whether this is server1 or server2
      updateData.connectionDetails = { ...connection.connectionDetails };
      if (isSourceServer) {
        updateData.connectionDetails.port2 = editValue;
      } else {
        updateData.connectionDetails.port1 = editValue;
      }
    }
    else if (field === 'cableType') {
      // Update the cable type field
      updateData.cableType = editValue;
    }
    else if (field === 'isConnected') {
      // Handle boolean conversion from string
      if (editValue === 'Yes') {
        updateData.isConnected = true;
      } else if (editValue === 'No') {
        updateData.isConnected = false;
      } else {
        updateData.isConnected = null;
      }
    }
    else if (field === 'notes') {
      // Update just the notes field
      updateData.notes = editValue;
    }
    
    // Update the document in Firestore
    const success = await updateConnection(connection.id, updateData);
    
    // Update local state to immediately reflect changes in UI
    if (success) {
      setLocalConnections(prevConnections => 
        prevConnections.map(conn => {
          if (conn.id !== connection.id) return conn;
          
          const updatedConn = { ...conn };
          
          if (field === 'port1') {
            if (isSourceServer) {
              updatedConn.localPort = editValue;
              if (updatedConn.connectionDetails) {
                updatedConn.connectionDetails = {
                  ...updatedConn.connectionDetails,
                  port1: editValue
                };
              }
            } else {
              updatedConn.localPort = editValue;
              if (updatedConn.connectionDetails) {
                updatedConn.connectionDetails = {
                  ...updatedConn.connectionDetails,
                  port2: editValue
                };
              }
            }
          } 
          else if (field === 'port2') {
            if (isSourceServer) {
              updatedConn.remotePort = editValue;
              if (updatedConn.connectionDetails) {
                updatedConn.connectionDetails = {
                  ...updatedConn.connectionDetails,
                  port2: editValue
                };
              }
            } else {
              updatedConn.remotePort = editValue;
              if (updatedConn.connectionDetails) {
                updatedConn.connectionDetails = {
                  ...updatedConn.connectionDetails,
                  port1: editValue
                };
              }
            }
          }
          else if (field === 'cableType') {
            updatedConn.cableType = editValue;
          }
          else if (field === 'isConnected') {
            if (editValue === 'Yes') {
              updatedConn.isConnected = true;
            } else if (editValue === 'No') {
              updatedConn.isConnected = false;
            } else {
              updatedConn.isConnected = null;
            }
          }
          else if (field === 'notes') {
            updatedConn.notes = editValue;
          }
          
          return updatedConn;
        })
      );
    }
    
    // Clear editing state regardless of success
    handleCancelEdit();
    
    return success;
  };

  return {
    editingCell,
    editValue,
    updateLoading,
    connections: localConnections, // Return the locally updated connections
    setLocalConnections: updateLocalConnections, // Expose setter function
    handleEditClick,
    handleCancelEdit,
    handleEditChange,
    handleEditKeyPress,
    handleSaveEdit
  };
};