import React from 'react';
import { Menu, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { serverTypeOptions } from '../../../utils/status/constants';

const ServerTypeFilterMenu = ({ 
  anchorEl, 
  handleClose, 
  serverTypeFilter, 
  toggleServerType 
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {serverTypeOptions.map((option) => (
        <MenuItem key={option.key}>
          <FormControlLabel
            control={
              <Checkbox
                checked={serverTypeFilter.includes(option.label)}
                onChange={() => toggleServerType(option.label)}
              />
            }
            label={option.label}
          />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ServerTypeFilterMenu;